import React, {useEffect, useMemo, useState} from 'react';
import {ImageLayout, MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './style.module.scss';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {extractValueFromString, getObjectByIdForm} from '../../helpers';
import SimpleButton from '../../components/simpleButton';
import {useGetTaskCountMutation} from './store/taskType.api';
import {setQuantity} from './store/taskTypes.slice';
import {Quantity} from './store/types';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {ListMenu} from '../home/store/types';
import Footer from '../../components/footer';
import {TaskFilter} from '../../components/taskFilter';
import {dateKeys, setClearFilter} from '../../components/taskFilter/store/filter.slice';
import {FilterItemReq} from '../../components/taskFilter/store/type';
import {typeFlow} from '../../store/types';
import {Modal} from '../../components/modal';
import {Button} from '../../fields/button';
import {TaskLocationState} from '../taskCreate/store/types';
import {Icon} from '../../assets/customSvg';
import {themColors} from '../../hooks/useChangeTheme';

export function getCountColumn(length: number, index: number): 3 | 2 {
    if (length % 3 === 0) {
        return 3;
    } else {
        if (length < 6) {
            return index < 3 ? 3 : 2;
        } else {
            return index <= 5 ? 3 : 2;
        }
    }
}

export const TaskMenu = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id_con} = useAppSelector(state => state.auth.user);
    const {data, isFetching} = useGetAllQuestionsQuery();
    const [getCount] = useGetTaskCountMutation();
    const {quantity} = useAppSelector(state => state.taskMenu);
    const {keys} = useAppSelector(state => state.filter);
    const {theme} = useAppSelector(state => state.app);
    const viewButtons = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [numTask, setNumTask] = useState<string>('');

    useEffect(() => {
        if (data) {
            const params = keys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');
            getCounts(params);
        }
    }, [isFetching]);

    const getCounts = (params: string) => {

        const current = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));
        const sources = current?.children?.filter((i: ListMenu) => i.icon.type === 'fetch').map((i: ListMenu) => i.icon.source) ?? [];

        for (const source of sources) {
            getCount({id_con, path: `${source}&${params}`})
                .unwrap()
                .then((quantity) => {
                    const mode = extractValueFromString(source, 'mode');
                    dispatch(setQuantity({
                        mode,
                        quan: quantity
                    }));
                })
                .catch((error) => {
                });
        }
    };

    const applyFilter = (isInit: boolean) => {
        isInit && dispatch(setClearFilter());

        const params = isInit
            ? dateKeys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&')
            : keys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');

        getCounts(params);
    };

    const handleFilterButton = (item: ListMenu) => {
        navigate(`/task-list/${item.idForm}`);
    };

    const handleOtherButton = (item: ListMenu) => {
        if (item.typeMob?.toUpperCase() === 'SOS') {
            return navigate(`/select-location/${item.idForm}?from=${item.name}`, {
                state: {
                    idParent: item.idParent,
                    path: '',
                }
            });
        }

        if (item.typeMob === typeFlow.taskByNum) {
            return setIsOpen(true);
        }

        navigate('/task-create', {state: {isNew: true, idForm}});
    };

    const isFilterButtons = useMemo(() => viewButtons?.children.filter((i: ListMenu) => i.icon.type === 'fetch'), [quantity]);
    const otherButtons = viewButtons?.children.filter((i: ListMenu) => i.icon.type !== 'fetch');

    const handleSearchByNum = () => {
        const state: TaskLocationState = {
            idForm
        };
        navigate(`/task-create?ID_App=${numTask}`, {state});
    };

    const goBackToHome = () => {
        navigate('/');
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Задачи'}/>
            <BackButton text={'Назад'} onClick={goBackToHome}/>
            <TaskFilter
                keys={keys}
                useInputSearch={false}
                applyFilter={(isInit) => applyFilter(isInit)}
            />
            <div className={styles.list}>
                {isFilterButtons?.map((item: ListMenu, index: number) => {
                    const currentMode = extractValueFromString(item.icon.source, 'mode');
                    const currentQuantity = quantity.find((i: Quantity) => i.mode === currentMode) as Quantity;

                    return (
                        <SimpleButton
                            key={item.idForm}
                            column={getCountColumn(isFilterButtons.length, index)}
                            handler={() => handleFilterButton(item)}
                        >
                            <div className={styles.listItem}>
                                <span className={styles.listTitle}>{currentQuantity?.quan}</span>
                                <span className={styles.listText}>{item.name}</span>
                            </div>
                        </SimpleButton>
                    );
                })}
                {otherButtons?.map((item: ListMenu) => {
                    return (
                        <SimpleButton
                            key={item.idForm}
                            column={2}
                            handler={() => handleOtherButton(item)}
                        >
                            <div className={styles.listItem}>
                                {
                                    item.icon.type === 'iconsvg'
                                        ? <Icon color={themColors[theme].color} width={34} height={34}
                                                fileName={item.icon.source as string}/>
                                        : (
                                            <ImageLayout
                                                src={item.icon.source}
                                                imageWidth={32}
                                                imageHeight={32}
                                                containerWidth={32}
                                                containerHeight={32}
                                            />
                                        )
                                }
                                <span className={styles.listText}>{item.name}</span>
                            </div>
                        </SimpleButton>
                    );
                })}
            </div>
            <Modal isOpen={isOpen}>
                <div className={styles.modal}>
                    <span className={styles.modalTitle}>Поиск по номеру задачи</span>
                    <input
                        className={styles.modalInput}
                        placeholder={'введите номер'}
                        value={numTask}
                        type={'number'}
                        inputMode={'numeric'}
                        onChange={event => {
                            setNumTask(event.target.value);
                        }}
                    />
                    <div className={styles.modalButton}>
                        <Button
                            text={'отменить'}
                            textStyleType={'semiBold'}
                            style={{
                                color: themColors[theme].color,
                                fontSize: '16px',
                                borderRadius: '12px',
                                border: `1px solid ${themColors[theme].color}`,
                                marginRight: '2px'
                            }}
                            onClick={() => setIsOpen(false)}
                        />
                        <Button
                            text={'найти'}
                            textStyleType={'semiBold'}
                            style={{
                                color: '#ffffff',
                                backgroundColor: themColors[theme].color,
                                fontSize: '16px',
                                borderRadius: '12px',
                                border: `1px solid ${themColors[theme].color}`,
                                marginLeft: '2px'
                            }}
                            onClick={handleSearchByNum}
                        />
                    </div>

                </div>
            </Modal>
            <Footer/>
        </MainLayout>
    );
};
