import React, {useEffect, useLayoutEffect, useState} from 'react';
import styles from '../styles.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import {DatePicker, Select, TimeRangePickerProps} from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isToday from 'dayjs/plugin/isToday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {UnmountClosed} from 'react-collapse';
import {useGetFilterObjMutation} from '../../../components/financialFilter/store/financial.filter.api';
import {IFilter} from '../../../components/taskFilter/store/type';
import {IMonitorFilter, setFilter} from '../store/monitoring.slice';
import {fromJsonToUrlencoded} from '../../../helpers';
import {userObjParams} from '../../taskCreate/tabs/FormTask';
import {MonitoringFilterItemType} from '../store/types';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {SwitcherTab} from '../../../components/switcherTab';
import {findMatchingIndices} from '../../report/Report';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('ru');

const {RangePicker} = DatePicker;

interface MonitoringFilterProps {
    isFixedDate: boolean;
    isOnlyObj?: boolean;
    applyFilter: () => void;
    clearFilter: (isSimpleFilter: boolean) => void;
}

const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Эта нед', value: [dayjs().startOf('week'), dayjs().endOf('week')]},
    {label: 'Пред нед', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().startOf('week').add(-1, 'd')]},
    {label: 'Этот мес', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
    {label: 'Пред мес', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().startOf('month').add(-1, 'd')]},
];

const todayArray = ['/monitoring-main', '/monitoring-map', '/monitoring-select', '/monitoring-load'];
const switchButtons = ['Corp', 'Fr'];

export const MonitoringFilter = (
    {
        isFixedDate,
        applyFilter,
        isOnlyObj,
        clearFilter
    }: MonitoringFilterProps) => {
    const {pathname} = useLocation();
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state.monitor);
    const [getObjects, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});

    const [isCollapsed, setCollapse] = useState<boolean>(false);
    const [typeIndex, setTypeIndex] = useState<number[]>([]);
    const [dataFilter, setDFilter] = useState<string[]>([]);

    useLayoutEffect(() => {
        const found = todayArray.some(item => new RegExp(`^${item}`).test(pathname));

        if (found) {
            const newKeys = filter.map(key => {
                if (key.type === 'Start') return {...key, value: dayjs().format('YYYY-MM-DD')};
                if (key.type === 'Finish') return {...key, value: dayjs().format('YYYY-MM-DD')};
                return key;
            });

            dispatch(setFilter(newKeys));
        } else {

            const dateToCheck = filter.find(i => i.type === 'Finish');
            const isToday = dayjs().isSame(dayjs((dateToCheck as IMonitorFilter).value as string), 'day');
            if (isToday) {
                const newKeys = filter.map(key => {
                    if (key.type === 'Start') return {...key, value: dayjs().subtract(7, 'day').format('YYYY-MM-DD')};
                    if (key.type === 'Finish') return {...key, value: dayjs().format('YYYY-MM-DD')};
                    return key;
                });
                dispatch(setFilter(newKeys));
            }
        }

    }, []);

    useEffect(() => {
        getObjects({body: fromJsonToUrlencoded({data: userObjParams})});
        const values = filter.find(i => i.type === 'FParentCodes')?.value as string[];
        if (values) {
            const matchingIndices = findMatchingIndices(values, switchButtons);
            setTypeIndex(matchingIndices);
            setDFilter(values);
        }
    }, []);

    useEffect(() => {
        const newFilter = filter.map(item =>
            item.type === 'FParentCodes'
                ? {...item, value: dataFilter}
                : item
        );
        dispatch(setFilter(newFilter));
    }, [dataFilter]);

    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );
        setCollapse(true);

        const newKeys = filter.map(key => {
            if (key.type === 'Start') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setFilter(newKeys));
    };

    const handleChangeDate2 = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );
        const newKeys = filter.map(key => {
            if (key.type === 'Start2') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish2') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setFilter(newKeys));
    };

    const handleSetFilter = (value: string[], type: MonitoringFilterItemType) => {
        if (filter.some(key => key.type === type)) {
            const newKeys = filter.map(key => key.type === type ? {...key, value} : key);
            dispatch(setFilter(newKeys));
        } else {
            dispatch(setFilter([...filter, {type, value}]));
        }
    };

    const handleType = (index: number) => {
        const type = switchButtons[index].toLowerCase();
        let newData: string[] = [];
        if (dataFilter.includes(type)) {
            newData = dataFilter.filter(i => i !== type);
        } else {
            newData = [...dataFilter, type];
        }
        const matchingIndices = findMatchingIndices(newData, switchButtons);
        setTypeIndex(matchingIndices);
        setDFilter(newData);
    };

    const dateStart = filter.find(k => k.type === 'Start') as IFilter;
    const dateFinish = filter.find(k => k.type === 'Finish') as IFilter;
    const dateStart2 = filter.find(k => k.type === 'Start2') as IFilter;
    const dateFinish2 = filter.find(k => k.type === 'Finish2') as IFilter;
    const defaultObj = filter.filter(i => i.type === 'FObjects').flatMap(i => i.value);

    return (
        <div>
            <div className={styles.dataPickerWrapper}>
                <div className={styles.dataPickerWrapperData}>
                    <RangePicker
                        popupClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        presets={rangePresets}
                        value={[dayjs(dateStart.value as string, 'YYYY-MM-DD'), dayjs(dateFinish.value as string, 'YYYY-MM-DD')]}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        disabled={isFixedDate}
                        allowClear={false}
                    />
                </div>
                <button
                    className={styles.dataPickerWrapperButton}
                    onClick={() => setCollapse(!isCollapsed)}
                >
                    {isCollapsed ? 'Скрыть' : 'Фильтр'}
                </button>
            </div>
            <UnmountClosed isOpened={isCollapsed}>
                <div className={styles.filterWrapper}>
                    <div className={styles.filterCollapse}>
                        <div className={styles.inputWrap}>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Выбрать объект"
                                className={styles.select}
                                defaultValue={defaultObj as string[]}
                                onChange={(value) => handleSetFilter(value, 'FObjects')}
                                options={objects?.listObject ?? []}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                            />
                        </div>
                        {isOnlyObj ? null : (
                            <>
                                <div className={styles.inputWrap}>
                                    <SwitcherTab
                                        pageType={'report'}
                                        isActive={typeIndex}
                                        isMultiple={true}
                                        buttons={switchButtons.map(i => i.toUpperCase())}
                                        onClick={(index) => handleType(index)}
                                    />
                                </div>
                                {dateStart2 !== undefined ? <div className={styles.filterCollapseRow}>
                                    <p className={styles.selectDateLabel}>Интервал 2</p>
                                    <RangePicker
                                        popupClassName="custom-range-picker-dropdown"
                                        className={classNames(styles.inputRange, styles.inputRangeW70)}
                                        locale={ru_RU}
                                        format="D MMM, YYYY"
                                        presets={rangePresets}
                                        value={[dayjs(dateStart2.value as string, 'YYYY.MM.DD'), dayjs(dateFinish2.value as string, 'YYYY.MM.DD')]}
                                        onChange={(dates, dateStrings) => handleChangeDate2(dateStrings)}
                                        separator="-"
                                        allowClear={false}
                                    />
                                </div> : null}
                            </>
                        )}

                        <div className={styles.filterButtons}>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={() => {
                                    setCollapse(false);
                                    clearFilter(isFixedDate);
                                }}
                            >Очистить фильтр
                            </button>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={() => {
                                    setCollapse(false);
                                    applyFilter();
                                }}
                            >Применить фильтр
                            </button>
                        </div>
                    </div>

                </div>
            </UnmountClosed>
        </div>
    );
};
