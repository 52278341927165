import {api} from '../../../store/api';
import {ProductDetail, ProductType} from './types';

export const productApi = api.injectEndpoints({
    endpoints: build => ({
        getTableProduct: build.query<ProductType[], { params: string }>({
            query: ({params}) => ({
                url: `/goodsReport/table?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return data.table.body;
            }
        }),
        getTableProductByObjects: build.query<{ head: string[], body: ProductDetail[] }, { params: string }>({
            query: ({params}) => ({
                url: `/goodsReport/tableByObj?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return {body: data.table.body, head: data.table.head.map((i: any) => i.title)};
            }
        }),
        getTableProductByPayments: build.query<{ head: string[], body: ProductDetail[] }, { params: string }>({
            query: ({params}) => ({
                url: `/goodsReport/tableByCorr?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return {body: data.table.body, head: data.table.head.map((i: any) => i.title)};
            }
        }),
    }), overrideExisting: true
});

export const {
    useGetTableProductQuery,
    useGetTableProductByObjectsQuery,
    useGetTableProductByPaymentsQuery
} = productApi;
