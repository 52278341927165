import React, {useEffect, useState} from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {useGetCashTableDataQuery} from './store/monitoring.api';
import styles from './styles.module.scss';
import AppLoader from '../../components/appLoader';
import {SearchByProductName} from './components';
import {Body} from '../../helpers/mapCheckResponse';
import {CashDetailsParams} from './store/types';
import classNames from 'classnames';
import {Icon} from '../../assets/customSvg';

const paths = [
    'balanceSystem',
    'dishes',
    'sumWaiters',
    'checks'
];


export const MonitoringCheckTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location as Location & { state: { ID_Obj: string, index: number } };

    const [body, setBody] = useState<Body>([]);

    const {data: systemBalance, isLoading, error} = useGetCashTableDataQuery(
        {ID_Obj: state.ID_Obj, path: paths[state.index]},
    );

    useEffect(() => {
        if (systemBalance) {
            setBody(systemBalance.table.body);
        }
    }, [systemBalance]);

    useEffect(() => {
        if (error) {
            console.log('error', error);
        }
    }, [error]);

    const handleGetMore = (item: string[]) => {
        if (systemBalance?.checks) {
            const currentCheck = systemBalance.checks.find((i: any) => i.Num === item[0]);
            if (currentCheck) {
                const stateToDetails: CashDetailsParams = {
                    sid: currentCheck.sid,
                    checkNum: currentCheck.Num,
                    ID_Obj: state.ID_Obj
                };
                navigate('/monitoring-cash-details', {state: stateToDetails});
            }
        }
    };

    const rowWidth = systemBalance && systemBalance?.table.head?.length > 4 ? `${(systemBalance?.table.head?.length ?? 1) * 150}px` : 'auto';

    if (!systemBalance && error) {
        return <MainLayout>
            <Header breadCrumbs={`Мониторинг чеков`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.errorContent}>
                <p className={styles.errorContentText}>{(error as any).data?.message ?? 'Сервер касс не отвечает, попробуйте позже.'}</p>
            </div>
        </MainLayout>;
    }

    const isBalanceSystem = systemBalance && 'balanceSystem' in systemBalance;

    return (
        <MainLayout>
            <Header breadCrumbs={`Мониторинг чеков`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.cashCheckTableWrapper}>
                            {state.index === 1
                                ? <SearchByProductName data={systemBalance?.table.body ?? []} getFilteredData={setBody}/>
                                : null}
                            <div className={styles.cashCheckTable}>
                                <div
                                    className={styles.cashCheckTableRow}
                                    style={{width: rowWidth}}>
                                    {systemBalance?.table.head?.map((item, index) => (
                                        <div
                                            key={`${item}-${index}`}
                                            className={styles.cashCheckTableItem}
                                        >
                                            <span className={styles.cashCheckTableHeadText}>{item}</span>
                                        </div>
                                    ))}
                                </div>
                                {body?.map((item, index) => (
                                    <div
                                        key={index}
                                        className={styles.cashCheckTableRow}
                                        style={{width: rowWidth}}
                                        onClick={() => state.index === 3 ? handleGetMore(item) : null}
                                    >
                                        {item.map((bodyItem, bodyIndex) => {
                                            if (isBalanceSystem) {
                                                const item = systemBalance.balanceSystem.find((i: any) => i.rowName === bodyItem);
                                                return item ? (
                                                    <div key={`${bodyItem}-${bodyIndex}`}
                                                         className={styles.cashCheckTableItem}>
                                                        {Number(item.rowType) === 2 ?
                                                            (<>
                                                                <span className={styles.cashCheckTableItemType2}/>
                                                                <span
                                                                    className={classNames(styles.cashCheckTableText, styles.cashCheckTableTextNorm)}>{bodyItem}</span>
                                                            </>) :
                                                            <span
                                                                className={styles.cashCheckTableText}>{bodyItem}</span>}
                                                    </div>
                                                ) : (
                                                    <div key={`${bodyItem}-${bodyIndex}`}
                                                         className={styles.cashCheckTableItem}
                                                    >
                                                            <span
                                                                className={classNames(styles.cashCheckTableText, styles.cashCheckTableTextNorm)}>{bodyItem}</span>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div key={`${bodyItem}-${bodyIndex}`}
                                                     className={styles.cashCheckTableItem}
                                                >
                                                        <span
                                                            className={
                                                                classNames(styles.cashCheckTableText,
                                                                    styles.cashCheckTableTextNorm)
                                                            }
                                                        >{bodyItem}</span>
                                                    {state.index === 3 && bodyIndex === 0
                                                        ? <Icon color={'#2c2c2c'}
                                                                fileName={'eye'}
                                                                width={25}
                                                                height={15}/>
                                                        : null}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
            }
        </MainLayout>
    );
};
