import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {ProductDetail, ProductFilter} from './store/types';
import {
    useGetGraphProductQuery,
    useGetTableProductByObjectsQuery,
    useGetTableProductByPaymentsQuery
} from './store/product.api';
import {createParams} from './helpers';
import {useAppSelector} from '../../hooks/app.hooks';
import {ChartContainer, Container, ListS} from './styled';
import {SwitcherTab} from './components';
import {TableData} from '../monitoring/MonitoringDetails';
import {Table} from '../../components/table';
import {createLineChart} from '../../lib';
import styles from '../monitoring/styles.module.scss';

function mapDataToTableData(body: ProductDetail[]): TableData[] {
    return body.map((i: ProductDetail) => ({
        name: 'CorrName' in i ? i.CorrName as string : i.ObjName as string,
        value: i.Sum.display,
        dynamic: i.Cnt.display,
        lastPeriod: i.Sebest.display
    }));
};

export const ProductDetails = () => {
    const location = useLocation();
    const {state} = location as Location & { state: ProductFilter };
    const navigate = useNavigate();
    const {filter} = useAppSelector(state => state.product);

    const [index, setIndex] = useState<number>(0);

    const {data: tableObj} = useGetTableProductByObjectsQuery({
        params: createParams(filter, state)
    });

    const {data: tablePayments} = useGetTableProductByPaymentsQuery({
        params: createParams(filter, state)
    });

    const {data: graph} = useGetGraphProductQuery({
        params: createParams(filter, state)
    });

    useEffect(() => {
        if (graph !== undefined) {
            const root = createLineChart({datasets: graph, isMultiple: false});

            return () => {
                root.dispose();
            };
        }
    }, [graph]);

    const handleBack = () => {
        navigate(-1);
    };

    const result = mapDataToTableData(index === 0 ? tableObj?.body ?? [] : tablePayments?.body ?? []);

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={handleBack}/>
            <Container>
                <SwitcherTab index={index} setIndex={setIndex}/>
                <ListS>
                    <ChartContainer>
                        <div className={styles.chart} id={'dynamic'}/>
                    </ChartContainer>
                    <Table<TableData>
                        headLabels={index === 0 ? tableObj?.head ?? [] : tablePayments?.head ?? []}
                        body={result}/>
                </ListS>
            </Container>
        </MainLayout>
    );
};
