import React from 'react';
import {MainLayout, QuestionVariable} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {useNavigate, useParams} from 'react-router-dom';
import {ListMenu} from '../home/store/types';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {typeFlow} from '../../store/types';
import AppLoader from '../../components/appLoader';

export const Loyalty = () => {
    const navigate = useNavigate();
    const {idForm} = useParams<{ idForm: string }>();
    const {data, isFetching} = useGetAllQuestionsQuery();

    const viewButtons = data?.ListMenu.find(item => item.idForm === Number(idForm))?.children as ListMenu[] ?? [];

    const handlePress = (item: ListMenu) => {
        console.log(item.typeMob);
        if (item.typeMob === typeFlow.LoyClientInfo) {
            navigate('/loyalty-client');
        }
        if (item.typeMob === typeFlow.LoyRep1) {
            navigate('/loyalty-report');
        }
    };
    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {isFetching
                ? <AppLoader isFull={false}/>
                : <QuestionVariable renderData={viewButtons} handlePress={handlePress}/>}
        </MainLayout>
    );
};
