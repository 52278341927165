import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from './app.hooks';
import {MonitoringFilterItemReq} from '../screens/monitoring/store/types';
import {IMonitorFilter, initDateKeys, setFilter, todayDateKeys} from '../screens/monitoring/store/monitoring.slice';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {HoldingType} from '../screens/report/store/types';

interface UseMonitoringControlProps {
    onFetch: ({params}: { params: string }) => void;
}


interface MonitoringControlReturn {
    clearFilter: (isSimpleFilter: boolean) => void;
    applyFilter: () => void;
    jumpToDetails: ({key}: JumpToDetails) => void;
    filter: IMonitorFilter[];
    holding: HoldingType;
}

export type MonitoringKeys =
    'paySum' |
    'trx' |
    'at' |
    'adt' |
    'upt' |
    'uptm' |
    'paySum1' |
    'trx1' |
    'at1' |
    'adt1' |
    'upt1' |
    'uptm1' |
    'paySum2' |
    'trx2' |
    'at2' |
    'adt2' |
    'upt2' |
    'uptm2' |
    'paySum3' |
    'trx3' |
    'at3' |
    'adt3' |
    'upt3' |
    'uptm3' |
    'paySum4' |
    'trx4' |
    'at4' |
    'adt4' |
    'upt4' |
    'uptm4' |
    'inStorePaySum' |
    'inStoreTrx' |
    'inStoreAt' |
    'inStoreAdt' |
    'dominoAPPPaySum' |
    'dominoAPPTrx' |
    'dominoAPPAt' |
    'dominoAPPAdt' |
    'dominoWEBPaySum' |
    'dominoWEBTrx' |
    'dominoWEBAt' |
    'dominoWEBAdt' |
    'aggregatorsPaySum' |
    'aggregatorsTrx' |
    'aggregatorsAt' |
    'aggregatorsAdt' |
    'yandexPaySum' |
    'yandexTrx' |
    'yandexAt' |
    'yandexAdt' |
    'sbermarketPaySum' |
    'sbermarketTrx' |
    'sbermarketAt' |
    'sbermarketAdt' |
    'deliveryPaySum' |
    'deliveryTrx' |
    'deliveryAt' |
    'deliveryAdt' |
    'paySumAdmin' |
    'trxAdmin' |
    'atAdmin' |
    'adtAdmin' |
    'paySumBonus' |
    'trxBonus' |
    'atBonus' |
    'adtBonus' |
    'ObjStatusObject' |
    'ObjStatusDelivery' |
    'ObjStatusHall' |
    'ObjStatusPickup'


type JumpToDetails = {
    key: MonitoringKeys;
    necessaryPartPath: string;
    labelPage: string;
};

export type MonitoringDetailsNavState = {
    tablePath: string;
    dynamicPath: string;
    key: MonitoringKeys;
    labelPage: string;
}

export const useMonitoringControl = (
    {
        onFetch
    }: UseMonitoringControlProps
): MonitoringControlReturn => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {search} = location as Location;
    const {filter} = useAppSelector(state => state.monitor);
    const holding = search.replace('?holding=', '') as HoldingType;

    useEffect(() => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        onFetch({params: `${params}&periodType=period&holding=${holding}`});
    }, [filter]);

    const applyFilter = () => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');
        onFetch({params: `${params}&periodType=period&holding=${holding}`});
    };

    const clearFilter = (isSimpleFilter: boolean) => {
 
        if (isSimpleFilter) {
            const params = todayDateKeys.map(key => {
                if (key.type === 'FParentCodes') {
                    return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
                } else {
                    return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
                }
            }).join('&');

            onFetch({params: `${params}&periodType=period&holding=${holding}`});
        } else {
            const params = initDateKeys.map(key => {
                if (key.type === 'FObjects' || key.type === 'FParentCodes') {
                    return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
                } else {
                    return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
                }
            }).join('&');
            dispatch(setFilter(initDateKeys));
            onFetch({params: `${params}&periodType=period&holding=${holding}`});
        }
    };

    const jumpToDetails = ({key, necessaryPartPath, labelPage}: JumpToDetails) => {
        const startDate = filter.find(i => i.type === 'Start')?.value;
        const finishDate = filter.find(i => i.type === 'Finish')?.value;
        const startDate2 = filter.find(i => i.type === 'Start2')?.value;
        const finishDate2 = filter.find(i => i.type === 'Finish2')?.value;
        const FParentCodes = filter.find(i => i.type === 'FParentCodes')?.value as string[];

        const tablePath = FParentCodes.length > 0
            ? `/reports/${necessaryPartPath}ByObjects?FParentCodes=${FParentCodes}&holding=${holding}&periodType=period&Start=${startDate}&Finish=${finishDate}&Start2=${startDate2}&Finish2=${finishDate2}`
            : `/reports/${necessaryPartPath}ByObjects?holding=${holding}&periodType=period&Start=${startDate}&Finish=${finishDate}&Start2=${startDate2}&Finish2=${finishDate2}`;

        const dynamicPath = FParentCodes.length > 0
            ? `/reports/${necessaryPartPath}ByDate?FParentCodes=${FParentCodes}&holding=${holding}&periodType=period&Start=${startDate}&Finish=${finishDate}`
            : `/reports/${necessaryPartPath}ByDate?holding=${holding}&periodType=period&Start=${startDate}&Finish=${finishDate}`;

        navigate('/monitoring-details', {
            state: {
                tablePath,
                dynamicPath,
                key,
                labelPage
            } as MonitoringDetailsNavState
        });
    };

    return {
        applyFilter,
        clearFilter,
        jumpToDetails,
        filter,
        holding
    };
};
