import React from 'react';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {useLazyGetLossesQuery} from './store/monitoring.api';
import {useMonitoringControl} from '../../hooks';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MonitoringDataCard, MonitoringFilter} from './components';
import AppLoader from '../../components/appLoader';
import Footer from '../../components/footer';
import {HoldingType} from '../report/store/types';
import styles from './styles.module.scss';
import {formatNumber} from '../../helpers';

export const MonitoringLosses = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {search} = location as Location;
    const [getByCourier, {data, isLoading}] = useLazyGetLossesQuery();
    const holding = search.replace('?holding=', '') as HoldingType;

    const {applyFilter, clearFilter, jumpToDetails} = useMonitoringControl({
        onFetch: ({params}) => getByCourier({params})
    });

    const handleClick = (key: any, labelPage: string) => {
        jumpToDetails({key, necessaryPartPath: 'losses', labelPage});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Мониторинг ${holding.toUpperCase()} - Потери`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <>
                            <div className={styles.wrapper}>
                                <p className={styles.title}>
                                    Потери всего:
                                    <span
                                        className={styles.titleSum}>{formatNumber(Number(data?.totalItem.SumBuy?.display.replaceAll(' ', '')) ?? 0)}</span>
                                    <span className={styles.titlePercent}>{data?.totalItem.Percent?.sortValue}%</span>
                                </p>
                            </div>
                            <div className={styles.list}>
                                {data?.items.map(i => (
                                    <MonitoringDataCard
                                        column={2}
                                        num={Number(i.SumBuy?.display.replaceAll(' ', ''))}
                                        percent={Number(i.Percent?.display.replaceAll(' ', ''))}
                                        text={i.CorrName}
                                        handleClick={() => handleClick(`losses_${i.ID_Corr}`, 'Потери')}
                                    />
                                ))}
                            </div>
                        </>
                    )
            }
            <Footer/>
        </MainLayout>
    );
};

