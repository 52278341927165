import React, {useState} from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {CashDetailsParams} from './store/types';
import {useGetCashRegisterCheckDetailsQuery} from './store/monitoring.api';
import AppLoader from '../../components/appLoader';
import styles from './styles.module.scss';


export const MonitoringCashDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location as Location as { state: CashDetailsParams };

    const [indexTab, setIndexTab] = useState<number>(0);

    const {data, isLoading} = useGetCashRegisterCheckDetailsQuery(
        {...state}
    );

    return (
        <MainLayout>
            <Header breadCrumbs={`Мониторинг касс`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={'container'}>
                            <div className={styles.tabButtonList}>
                                {data.map((i: any, idx: number) => (
                                    <button
                                        key={idx}
                                        className={styles.tabButton}
                                        onClick={() => setIndexTab(idx)}
                                    >{i.key}</button>
                                ))}
                            </div>
                            <div className={styles.cashDetailsCard}>
                                {data[indexTab].values.length > 0
                                    ? data[indexTab].values.map((item: any, index: number) => (

                                        <div className={styles.cashDetailsCardRow}>
                                            {Object.entries(item).map(([key, value]) => (
                                                <div key={index}>
                                                    <div key={key}>
                                                        <span className={styles.cashDetailsCardName}>{key}:</span>
                                                        <span
                                                            className={styles.cashDetailsCardValue}>{String(value) || '—'}</span>
                                                    </div>
                                                </div>
                                            ))
                                            }</div>

                                    )) : (
                                        <div className={styles.cashDetailsCardRow}>
                                            <p className={styles.errorContentText}>Нет значений</p>
                                        </div>

                                    )}
                            </div>
                        </div>
                    )
            }
        </MainLayout>
    );
};
