import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import {AuthContainer} from './screens/auth';
import {PrivateRouteMain} from './routes';
import {ChecksScreen} from './screens/checks';
import {CheckTypesScreen} from './screens/checkTypes';
import {SelectLocation} from './screens/selectLocation';
import {Quiz, QuizList} from './screens/quiz';
import {SettingsScreen} from './screens/settings';
import {Camera} from './screens/camera';
import {QuicklyTaskTypes, TaskMenu} from './screens/taskTypes';
import {QuicklyTaskCreate, TaskCreate} from './screens/taskCreate';
import {TaskList} from './screens/taskList';
import {PdfView} from './screens/pdfView';
import {InfoType, InfoView} from './screens/info';
import {Home} from './screens/home';
import {Report} from './screens/report';
import {ListSurveys} from './screens/listSurveys';
import {Profile} from './screens/profile';
import {Purchase} from './screens/purchase';
import {Inventory, InventoryCheckForm, InventoryCreateForm, InventoryListPage, ScannerPage} from './screens/inventory';
import {TimeConfirmation, TimeControl, WorkShift, WorkShiftEmployee} from './screens/timeControl';
import {Courier, DeliveryMap, Vacancies, VacanciesCreate} from './screens/courier';
import {Marketing} from './screens/marketing';
import {CallCenter} from './screens/callCenter';
import {Products} from './screens/products';
import {ActionList} from './screens/actionList';
import {EmptyPage} from './screens/emptyPage';
import {FinancialCreate, FinancialList, FinancialMenu} from './screens/financial';
import {ViewReport} from './screens/report/ViewReport';
import {PhotoReport, PhotoReportCreate, PhotoReportList} from './screens/photoReport';
import {InventoryViewCard} from './screens/inventory/InventoryViewCard';
import {TestPage} from './screens/test';
import {SelectEmployee} from './screens/selectEmployee';
import {useAppSelector} from './hooks/app.hooks';
import {useChangeTheme} from './hooks';
import {PhoneCall, PhoneCallCounts} from './screens/phoneCall';
import {TableView} from './screens/tableView';
import {
    Monitoring,
    MonitoringByCourier,
    MonitoringByRange,
    MonitoringBySource,
    MonitoringByStep,
    MonitoringByStop,
    MonitoringByStopTable,
    MonitoringCashDetails,
    MonitoringCashRegister,
    MonitoringCheckTable,
    MonitoringDetails,
    MonitoringLine,
    MonitoringLoad,
    MonitoringLosses,
    MonitoringMainData,
    MonitoringMap,
    MonitoringNonPayers,
    MonitoringSelect,
} from './screens/monitoring';
import {OrderDetails} from './screens/monitoringOrder';
import {YMaps} from '@pbe/react-yandex-maps';
import {ReviewByObjects, ReviewCommon, ReviewObjList} from './screens/reviews';
import {ExternalCamera, ExternalCameraList} from './screens/externalСamera';
import {
    Loyalty,
    LoyaltyClient,
    LoyaltyClientInfo,
    LoyaltyClientReport,
    LoyaltyHistory,
    LoyaltyReport
} from './screens/loyalty';
import {ProductCards} from './screens/products/ProductCards';
import {ProductDetails} from './screens/products/ProductDetails';

// function urlBase64ToUint8Array(base64String: any) {
//     const padding = '='.repeat((4 - base64String.length % 4) % 4);
//     const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
//     const rawData = window.atob(base64);
//     return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
// }

function App() {
    const {theme} = useAppSelector(state => state.app);

    const {chaneTheme} = useChangeTheme();


    useEffect(() => {
        document.addEventListener('visibilitychange', async function () {

            if (document.visibilityState === 'visible') {
                const isFile = await sessionStorage.getItem('isFile');

                if (isFile === null) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 800);
                }
            }
        });

        const appHeight = () => {
            setTimeout(() => {
                const doc = document.documentElement;
                doc.style.setProperty('--app-height', `${window.innerHeight}px`);
            }, 100);
        };
        window.addEventListener('resize', appHeight);
        appHeight();
        chaneTheme(theme);
    }, []);


    return (
        <div className={'wrapperView'}>
            <YMaps
                query={
                    {
                        lang: 'ru_RU',
                        apikey: '0adbb008-3071-4219-9486-f3377b7a8089',
                        load: 'templateLayoutFactory,route,multiRouter.MultiRoute,control.RoutePanel'
                    }
                }>
                <Routes>
                    <Route path={'/login'} element={<AuthContainer/>}/>
                    <Route path={'/'} element={<PrivateRouteMain/>}>
                        <Route path={'/'} element={<Home/>}/>
                        <Route path={'/check/:idForm'} element={<ChecksScreen/>}/>
                        <Route path={'/check-type/:idForm'} element={<CheckTypesScreen/>}/>
                        <Route path={'/select-location/:idForm'} element={<SelectLocation/>}/>
                        <Route path={'/select-employee/:idForm'} element={<SelectEmployee/>}/>
                        <Route path={'/quiz'} element={<Quiz/>}/>
                        <Route path={'/settings'} element={<SettingsScreen/>}/>
                        <Route path={'/camera/:answerId'} element={<Camera/>}/>
                        <Route path={'/task-menu/:idForm'} element={<TaskMenu/>}/>
                        <Route path={'/task-list/:idForm'} element={<TaskList/>}/>
                        <Route path={'/task-create'} element={<TaskCreate/>}/>
                        <Route path={'/quiz-list'} element={<QuizList/>}/>
                        <Route path={'/quickly-task-menu'} element={<QuicklyTaskTypes/>}/>
                        <Route path={'/task-quickly-create/:id'} element={<QuicklyTaskCreate/>}/>
                        <Route path={'/view-pdf'} element={<PdfView/>}/>
                        <Route path={'/view-table'} element={<TableView/>}/>
                        <Route path={'/purchase/:idForm'} element={<Purchase/>}/>
                        <Route path={'/inventory/:idForm'} element={<Inventory/>}/>
                        <Route path={'/inventory-create'} element={<InventoryCreateForm/>}/>
                        <Route path={'/inventory-check'} element={<InventoryCheckForm/>}/>
                        <Route path={'/inventory-view'} element={<InventoryViewCard/>}/>
                        <Route path={'/inventory-list'} element={<InventoryListPage/>}/>
                        <Route path={'/scanner/:idForm'} element={<ScannerPage/>}/>
                        <Route path={'/time-control/:idForm'} element={<TimeControl/>}/>
                        <Route path={'/time-confirmation/:idForm'} element={<TimeConfirmation/>}/>
                        <Route path={'/time-work-shift/:idForm'} element={<WorkShift/>}/>
                        <Route path={'/time-work-shift-employee'} element={<WorkShiftEmployee/>}/>
                        <Route path={'/courier/:idForm'} element={<Courier/>}/>
                        <Route path={'/courier-delivery-map'} element={<DeliveryMap/>}/>
                        <Route path={'/vacancies'} element={<Vacancies/>}/>
                        <Route path={'/vacancies-create'} element={<VacanciesCreate/>}/>
                        <Route path={'/marketing/:idForm'} element={<Marketing/>}/>
                        <Route path={'/call-center/:idForm'} element={<CallCenter/>}/>
                        <Route path={'/products/:idForm'} element={<Products/>}/>
                        <Route path={'/product-cards/:FTypes'} element={<ProductCards/>}/>
                        <Route path={'/product-details'} element={<ProductDetails/>}/>
                        <Route path={'/info-type/:idForm'} element={<InfoType/>}/>
                        <Route path={'/info-view'} element={<InfoView/>}/>
                        <Route path={'/report'} element={<Report/>}/>
                        <Route path={'/report-view'} element={<ViewReport/>}/>
                        <Route path={'/list-surveys'} element={<ListSurveys/>}/>
                        <Route path={'/action-list'} element={<ActionList/>}/>
                        <Route path={'/profile'} element={<Profile/>}/>
                        <Route path={'/financial-menu/:idForm'} element={<FinancialMenu/>}/>
                        <Route path={'/financial-list/:idForm'} element={<FinancialList/>}/>
                        <Route path={'/financial-create'} element={<FinancialCreate/>}/>
                        <Route path={'/photo-report'} element={<PhotoReport/>}/>
                        <Route path={'/photo-report-list'} element={<PhotoReportList/>}/>
                        <Route path={'/photo-report-create'} element={<PhotoReportCreate/>}/>
                        <Route path={'/empty-page'} element={<EmptyPage/>}/>
                        <Route path={'/test-page'} element={<TestPage/>}/>
                        <Route path={'/call-count/:idForm'} element={<PhoneCallCounts/>}/>
                        <Route path={'/calls'} element={<PhoneCall/>}/>
                        <Route path={'/monitoring/:idForm'} element={<Monitoring/>}/>
                        <Route path={'/monitoring-main/:idForm'} element={<MonitoringMainData/>}/>
                        <Route path={'/monitoring-line'} element={<MonitoringLine/>}/>
                        <Route path={'/monitoring-load/:idForm'} element={<MonitoringLoad/>}/>
                        <Route path={'/monitoring-range/:idForm'} element={<MonitoringByRange/>}/>
                        <Route path={'/monitoring-source/:idForm'} element={<MonitoringBySource/>}/>
                        <Route path={'/monitoring-courier/:idForm'} element={<MonitoringByCourier/>}/>
                        <Route path={'/monitoring-step/:idForm'} element={<MonitoringByStep/>}/>
                        <Route path={'/monitoring-map/:idForm'} element={<MonitoringMap/>}/>
                        <Route path={'/monitoring-select/:idForm'} element={<MonitoringSelect/>}/>
                        <Route path={'/monitoring-stop/:idForm'} element={<MonitoringByStop/>}/>
                        <Route path={'/monitoring-stop-table/:type'} element={<MonitoringByStopTable/>}/>
                        <Route path={'/monitoring-non-payer/:idForm'} element={<MonitoringNonPayers/>}/>
                        <Route path={'/monitoring-losses/:idForm'} element={<MonitoringLosses/>}/>
                        <Route path={'/monitoring-details'} element={<MonitoringDetails/>}/>
                        <Route path={'/monitoring-cash-register'} element={<MonitoringCashRegister/>}/>
                        <Route path={'/monitoring-check-table'} element={<MonitoringCheckTable/>}/>
                        <Route path={'/monitoring-cash-details'} element={<MonitoringCashDetails/>}/>
                        <Route path={'/order-details'} element={<OrderDetails/>}/>
                        <Route path={'/review/:idForm'} element={<ReviewCommon/>}/>
                        <Route path={'/review-objects'} element={<ReviewByObjects/>}/>
                        <Route path={'/review-objects-list'} element={<ReviewObjList/>}/>
                        <Route path={'/external-camera'} element={<ExternalCamera/>}/>
                        <Route path={'/external-camera-list/:idForm'} element={<ExternalCameraList/>}/>
                        <Route path={'/loyalty/:idForm'} element={<Loyalty/>}/>
                        <Route path={'/loyalty-client'} element={<LoyaltyClient/>}/>
                        <Route path={'/loyalty-client-info'} element={<LoyaltyClientInfo/>}/>
                        <Route path={'/loyalty-history/:ClientID'} element={<LoyaltyHistory/>}/>
                        <Route path={'/loyalty-report'} element={<LoyaltyReport/>}/>
                        <Route path={'/loyalty-client-report/:FObjects'} element={<LoyaltyClientReport/>}/>
                    </Route>
                </Routes>
            </YMaps>
        </div>
    );
}

export default App;
