import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useLazyGetBySourceQuery} from './store/monitoring.api';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MonitoringFilter, SourceBlock} from './components';
import Footer from '../../components/footer';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';
import {BySource, SourceType} from './store/types';
import {MonitoringKeys} from '../../hooks/useMonitoringControl';

export const MonitoringBySource = () => {
    const navigate = useNavigate();
    const [getBySource, {data, isLoading}] = useLazyGetBySourceQuery();


    const {applyFilter, clearFilter, holding, jumpToDetails} = useMonitoringControl({
        onFetch: ({params}) => getBySource({params})
    });

    const handleClick = (key: MonitoringKeys, labelPage: string) => {
        jumpToDetails({key, necessaryPartPath: 'revenueSource', labelPage});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Монитор ${holding.toUpperCase()} - По источникам за период`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <>
                            <SourceBlock
                                data={data as BySource}
                                label={'Выручка всего:'}
                                keyName={'paySum' as keyof SourceType}
                                isSeparator={false}
                                onClick={handleClick}/>
                            <SourceBlock
                                data={data as BySource}
                                label={'Чеков всего:'}
                                keyName={'trx' as keyof SourceType}
                                onClick={handleClick}/>
                            <SourceBlock
                                data={data as BySource}
                                label={'Средний чек всего:'}
                                keyName={'at' as keyof SourceType}
                                onClick={handleClick}/>
                            <SourceBlock
                                data={data as BySource}
                                label={'Чеков/день/тчк всего:'}
                                keyName={'adt' as keyof SourceType}
                                onClick={handleClick}/>

                        </>
                    )
            }

            <Footer/>
        </MainLayout>
    );
};
