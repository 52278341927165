import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import styled from 'styled-components';
import {Filter} from './components/Filter';
import {useGetTableProductQuery} from './store/product.api';
import AppLoader from '../../components/appLoader';
import {Card} from './components';
import {ProductFilter, ProductType} from './store/types';
import {createParams} from './helpers';
import {useAppSelector} from '../../hooks/app.hooks';
import {useDebounce} from '../../hooks';

type FTypesType = '1' | '2' | '5' | '6';


export const ProductCards = () => {
    const {FTypes} = useParams<{ FTypes: FTypesType }>();
    const navigate = useNavigate();
    const {filter} = useAppSelector(state => state.product);

    const [cards, setCards] = useState<ProductType[]>([]);
    const [searchText, setSearch] = useState<string>('');
    const {debounceValue} = useDebounce({value: searchText, delay: 300});


    const {data, isFetching} = useGetTableProductQuery({
        params: createParams(filter, {FTypes})
    });

    useEffect(() => {
        if (data !== undefined) {
            setCards(data);
        }
    }, [data]);

    useEffect(() => {
        const items = debounceValue !== ''
            ? (data as ProductType[]).filter(i => i.GoodName.toLowerCase().includes(debounceValue.toLowerCase()))
            : data as ProductType[];
        setCards(items);
    }, [debounceValue]);

    const handleClickCard = (item: ProductType) => {
        navigate('/product-details', {
            state: {FGoods: String(item.ID_Good), FTypes: FTypes} as ProductFilter
        });
    };
    console.log(cards);
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <MainLayout isOverflowHidden={true}>
            <BackButton text={'Назад'} onClick={handleBack}/>
            <Container>
                <Filter value={searchText} onSearch={setSearch}/>
                {isFetching
                    ? <AppLoader isFull={false}/>
                    : <ListS>
                        {cards?.map((item: ProductType, index: number) =>
                            <Card
                                key={index}
                                item={item}
                                onClick={() => handleClickCard(item)}/>
                        )}
                    </ListS>}
            </Container>
        </MainLayout>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  height: calc(var(--app-height) - 74px);
`;

const ListS = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
