import React from 'react';
import styled from 'styled-components';
import {CustomDataPicker} from '../../../components/CustomDataPicker';
import {fParentCodesButton} from '../constants';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {setProductFilter} from '../store/product.slice';
import {SimpleInput} from '../../../fields/simpleInput';
import {SwitcherButtonS, SwitcherS} from '../styled';

interface FilterProps {
    value: string;
    onSearch: (value: string) => void;
}

export const Filter = (
    {
        value,
        onSearch
    }: FilterProps
) => {
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state.product);

    const handleChangeDate = (dateString: string[]) => {
        const newFilter = {...filter};
        newFilter.Start = dateString[0];
        newFilter.Finish = dateString[1];

        dispatch(setProductFilter(newFilter));
    };

    const handleSwitcher = (type: string) => {
        const newFilter = {...filter};
        const currentFParentCodes = newFilter.FParentCodes as string[] || [];

        newFilter.FParentCodes = currentFParentCodes.includes(type)
            ? currentFParentCodes.filter(i => i !== type)
            : [...currentFParentCodes, type];

        dispatch(setProductFilter(newFilter));
    };

    return (
        <FilterS>
            <RowS>
                <CustomDataPicker
                    height={40}
                    Start={filter.Start as string}
                    Finish={filter.Finish as string}
                    handleChangeDate={handleChangeDate}
                />
            </RowS>
            <RowS>
                <SwitcherS>
                    {fParentCodesButton.map(button =>
                        <SwitcherButtonS key={button}
                                         isActive={filter.FParentCodes?.includes(button) || false}
                                         onClick={() => handleSwitcher(button)}
                        >
                            {button}
                        </SwitcherButtonS>)}
                </SwitcherS>
            </RowS>
            <RowS>
                <SimpleInput
                    type={'text'}
                    value={value}
                    readonly={false}
                    placeholder={'Поиск по названию'}
                    size={'sm'}
                    onChange={onSearch}
                />
            </RowS>
        </FilterS>
    );
};

const FilterS = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RowS = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

