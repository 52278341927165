import React, {useEffect, useRef, useState} from 'react';
import '@tensorflow/tfjs';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import Hls from 'hls.js';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import styles from './style.module.scss';
import {Icon} from '../../assets/customSvg';

function formatPlayerTime(time: number): string {
    if (!time) {
        return '--:--';
    }

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
}

export const ExternalCamera = () => {
    const navigate = useNavigate();
    const [streamName, setStreamName] = useState('camera1');
    const [peopleCount, setPeopleCount] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(`https://api.ifbx.ru/api/v1/hls/${streamName}/index.m3u8`);
                hls.attachMedia(video);

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    video.play();
                    setIsPlaying(true);
                });

                return () => {
                    hls.destroy();
                };
            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = `http://localhost:8080/hls/${streamName}/index.m3u8`;
                video.addEventListener('loadedmetadata', () => {
                    video.play();
                    setIsPlaying(true);
                });
            }
        }
    }, [streamName]);

    useEffect(() => {
        let isDetecting = true;
        let model: cocoSsd.ObjectDetection | null = null;

        const loadModelAndStartDetection = async () => {
            if (videoRef.current && canvasRef.current) {
                try {

                    model = await cocoSsd.load();

                    const video = videoRef.current;
                    const canvas = canvasRef.current;
                    const context = canvas.getContext('2d');

                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    // Обрабатываем поток видео
                    const detectPeople = async () => {
                        if (!isDetecting) return;

                        if (video && model && context) {
                            context.clearRect(0, 0, canvas.width, canvas.height); // Очищаем canvas

                            try {
                                const predictions = await model.detect(video);
                                const people = predictions.filter((p) => p.class === 'person');
                                setPeopleCount(people.length);

                                // Рисуем рамки для каждого обнаруженного человека
                                people.forEach((person) => {
                                    const [x, y, width, height] = person.bbox;
                                    context.strokeStyle = 'red';
                                    context.lineWidth = 2;
                                    context.strokeRect(x, y, width, height);

                                    context.fillStyle = 'red';
                                    context.font = '16px Arial';
                                    context.fillText(
                                        `${person.class} (${Math.round(person.score * 100)}%)`,
                                        x,
                                        y > 10 ? y - 5 : 10
                                    );
                                });
                            } catch (err) {
                                console.error('Ошибка при анализе видео:', err);
                            }
                        }
                        if (isDetecting) {
                            requestAnimationFrame(detectPeople);
                        }
                    };

                    detectPeople();
                } catch (error) {
                    console.error('Ошибка загрузки модели или анализа видео:', error);
                }
            }
        };

        loadModelAndStartDetection();

        return () => {
            isDetecting = false;
            if (videoRef.current?.srcObject) {
                const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, []);

    const togglePlayPause = () => {
        const video = videoRef.current;
        if (video) {
            if (video.paused) {
                video.play();
                setIsPlaying(true);
            } else {
                video.pause();
                setIsPlaying(false);
            }
        }
    };


    const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const video = videoRef.current;
        if (video) {
            video.currentTime = parseFloat(e.target.value);
            setCurrentTime(video.currentTime);
        }
    };

    // Обновляем время текущего воспроизведения и длительность видео
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const updateTime = () => {
                setCurrentTime(video.currentTime);
                setDuration(video.duration);
            };

            video.addEventListener('timeupdate', updateTime);
            video.addEventListener('pause', () => setIsPlaying(false));
            video.addEventListener('play', () => setIsPlaying(true));

            return () => {
                video.removeEventListener('timeupdate', updateTime);
                video.removeEventListener('pause', () => setIsPlaying(false));
                video.removeEventListener('play', () => setIsPlaying(true));
            };
        }
    }, []);

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.video}>
                <video
                    ref={videoRef}
                    muted
                    autoPlay
                    playsInline
                    {...{'webkit-playsinline': 'true'}}
                />
                <canvas ref={canvasRef} className={styles.canvas}/>
            </div>
            <div className={'container'}>
                <p className={styles.videoText}>Количество людей: {peopleCount}</p>
                <div className={styles.controls}>
                    <button className={styles.controlsButton} onClick={togglePlayPause}>
                        {isPlaying
                            ? <Icon fileName={'pause'} width={25} height={25} color={'var(--text-color)'}/>
                            : <Icon fileName={'play'} width={25} height={25} color={'var(--text-color)'}/>
                        }
                    </button>
                    <div className={styles.controlsRoad}>
                        <span className={styles.controlsTime}>{formatPlayerTime(Math.round(currentTime))}</span>
                        <input
                            type="range"
                            min="0"
                            max={duration || 0}
                            step="0.1"
                            value={currentTime}
                            className={styles.controlsRange}
                            onChange={handleTimeChange}
                        />
                        <span className={styles.controlsTime}>{formatPlayerTime(Math.round(duration))}</span>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};
