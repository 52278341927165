import React, {useEffect} from 'react';
import {ImageLayout, MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {Filter} from './components';
import {useLazyGetVacanciesListQuery} from './store/courier.api';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {courierFilterKeys, ICourierFilter, VacancyInfo} from './store/types';
import {changeVacancy, clearCourierFilter} from './store/courier.slice';
import styles from './style.module.scss';
import {Card} from './components/Card';
import AppLoader from '../../components/appLoader';
import dayjs from 'dayjs';

const add = require('../../assets/images/add-icon.png');

export const Vacancies = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [getVacanciesList, {data, isFetching}] = useLazyGetVacanciesListQuery();

    const {filter} = useAppSelector(state => state.courier);

    useEffect(() => {
        const params: string = courierFilterKeys.map(key => `${key.type}=${key.value}`).join('&');
        getVacanciesList({params});
    }, []);

    const applyFilter = (newKeys?: ICourierFilter[]) => {
        const params: string = newKeys
            ? newKeys.map(key => `${key.type}=${key.value}`).join('&')
            : filter.map(key => `${key.type}=${key.value}`).join('&');
        getVacanciesList({params});
    };
 
    const clearFilter = () => {
        dispatch(clearCourierFilter());
        const params: string = courierFilterKeys.map(key => `${key.type}=${key.value}`).join('&');
        getVacanciesList({params});
    };

    const handleClickByCard = (item: VacancyInfo) => {

        dispatch(changeVacancy({
            Type: 'Update',
            ObjId: item.ID_Obj,
            ID: item.ID,
            ID_Staff: item.ID_Staff,
            IsActive: 1,
            Date: dayjs(item.Date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
            Cnt: item.Cnt,
            Rem: item.Rem
        }));
        setTimeout(() => {
            navigate(`/vacancies-create`);
        }, 200);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Вакансии'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={'container'}>
                <Filter clearFilter={clearFilter} applyFilter={applyFilter}/>
                <div className={styles.list}>
                    {isFetching
                        ? <AppLoader isFull={false}/>
                        : data?.VacancyList?.map((item: VacancyInfo) => (
                            <Card key={item.ID} item={item} onClick={() => handleClickByCard(item)}/>
                        ))}

                </div>
            </div>

            <button className={styles.addButton} onClick={() => navigate('/vacancies-create')}>
                <ImageLayout
                    src={add}
                    imageWidth={44}
                    imageHeight={44}
                    containerWidth={44}
                    containerHeight={44}
                />
            </button>
        </MainLayout>
    );
};

