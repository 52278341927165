import {OrderStatusesType} from '../../monitoringOrder/store/types';
import {IMonitorFilter} from './monitoring.slice';
import {TableData} from '../../../store/types';

export type MainData = {
    paySum: number;
    trx: number;
    at: number;
    adt: number;
}

export type ByRangeData = {
    paySum: 1773010.08,
    trx: 1558,
    at: 1138.0039,
    adt: 31.795917510986,
    upt: 2.3857509627728,
    uptm: 2.5353016688062,
}

export type SourceType = {
    sum: number;
    trx: number;
    at: number;
    adt: number;
}
export type BySource = {
    total: SourceType
    aggregators: SourceType
    dominoWEB: SourceType
    dominoAPP: SourceType
    inStore: SourceType
    delivery: SourceType
    sbermarket: SourceType
    yandex: SourceType
}

export type ByCourier = {
    paySum: number;
    paySum1: number;
    paySum2: number;
    paySum3: number;
    paySum4: number;
    trx: number;
    trx1: number;
    trx2: number;
    trx3: number;
    trx4: number;
    at: number;
    at1: number;
    at2: number;
    at3: number;
    at4: number;
    adt: number;
    adt1: number;
    adt2: number;
    adt3: number;
    adt4: number;
}

export type ByStage = {
    AvgTimeCooking: number;
    AvgTimeSended: number;
    AvgTimeOnShelf: number;
    ClickToEat: number;
}

export interface MonitoringStore {
    filter: IMonitorFilter[];
    pointsOrders: Point[];
    statuses: string[];
}

export type Point = {
    idObj: number;
    idOrder: string;
    status: string;
    status_en: OrderStatusesType;
    lon: number;
    lat: number;
}

export type ObjectPoint = {
    id: number;
    name: string;
    IsActive: 1,
    ParentCode: 'Corp' | 'Fr';
    ParentName: string;
    PlaceType: string;
    lon: number | null,
    lat: number | null,
    boundary: number[][],
    address: string;
    email: string;
    phone: string | null,
    ops: string;
    tu: string;
    upr: string | null,
    dateStart: string | null;
    dateFinish: string | null
}

export type MonitoringFilterItemType =
    'FObjects'
    | 'Start'
    | 'Finish'
    | 'Start2'
    | 'Finish2'
    | 'FStatuses'
    | 'FParentCodes';
export const MonitoringFilterItemReq: any = {
    FObjects: 'FObjects',
    FStatuses: 'FStatuses',
    Start: 'Start',
    Finish: 'Finish',
    Start2: 'Start2',
    Finish2: 'Finish2',
    FParentCodes: 'FParentCodes',
};

export type OrderToday = {
    name: string;
    sum?: number;
    cnt?: number;
    value?: number;
    name_en?: string;
}

export type OrderShort = {
    ID_Obj: number | null;
    ObjName: string;
    orderId: number | null;
    ClientPhone: string
    OrderNumber: number | null,
    OrderStatus: number | null,
    OrderSum: {
        display: number;
        sortValue: number;
    },
    OrderRem: string | null;
    DateCreated: {
        display: string | null;
        sortValue: boolean;
    },
    DateClosed: {
        display: string;
        sortValue: boolean;
    }
}

export type OrderTodayArr = {
    first: OrderToday[];
    second: OrderToday[];
    third: OrderToday[];
    fourth: OrderToday[];
    fifth: OrderToday[];
}

export type BasicSpotData = {
    delivery: number;
    hall: number;
    object: number;
    pickup: number;
}

export type NonPayer = {
    paySum: number;
    trx: number;
    at: number;
    adt: number;
}

export type NonPayerRes = {
    admin: NonPayer,
    bonus: NonPayer,
    status: boolean;
}


export type Losses = {
    tId: number | string;
    ID_Corr: number | null;
    CorrName: string;
    SumBuy: TableData,
    Percent: TableData
}

export type CashDetailsParams = {
    ID_Obj: string;
    sid: string;
    checkNum: string;
}
