import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useLazyGetByCourierQuery} from './store/monitoring.api';
import {useMonitoringControl} from '../../hooks';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MonitoringDataCard, MonitoringFilter} from './components';
import AppLoader from '../../components/appLoader';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {calculatePercentage} from '../../helpers/calculatePercentage';
import {formatNumber} from '../../helpers';
import {MonitoringKeys} from '../../hooks/useMonitoringControl';

export const MonitoringByCourier = () => {
    const navigate = useNavigate();
    const [getByCourier, {data, isLoading}] = useLazyGetByCourierQuery();
    const {applyFilter, clearFilter, jumpToDetails, holding} = useMonitoringControl({
        onFetch: ({params}) => getByCourier(
            {
                params,
                path: holding === 'dp' ? '/reports/revenueAnalysisCouriers.php' : '/reports/mainValueCouriers'
            }
        )
    });

    const handleClick = (key: MonitoringKeys, labelPage: string) => {
        jumpToDetails({key, necessaryPartPath: 'revenueCouriers', labelPage});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Мониторинг ${holding.toUpperCase()} - По курьерам за период`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <>
                            <div className={styles.wrapper}>
                                <p className={styles.title}>
                                    Выручка всего:
                                    <span className={styles.titleSum}>{formatNumber(data?.paySum ?? 0)}</span>
                                    <span className={styles.titlePercent}>(100%)</span>
                                </p>
                            </div>

                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.paySum1}
                                    percent={calculatePercentage(data?.paySum as number, data?.paySum1 as number)}
                                    text={'Без курьера'}
                                    handleClick={() => handleClick('paySum1', 'Выручка - Без курьера')}
                                />
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.paySum2}
                                    percent={calculatePercentage(data?.paySum as number, data?.paySum2 as number)}
                                    text={`Курьер ресторана`}
                                    handleClick={() => handleClick('paySum2', 'Выручка - Курьер ресторана')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.paySum3}
                                    percent={calculatePercentage(data?.paySum as number, data?.paySum3 as number)}
                                    text={'Курьер сервиса'}
                                    handleClick={() => handleClick('paySum3', 'Выручка - Курьер сервиса')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.paySum4}
                                    percent={calculatePercentage(data?.paySum as number, data?.paySum4 as number)}
                                    text={'Наш курьер сервис'}
                                    handleClick={() => handleClick('paySum4', 'Выручка - Наш курьер сервис')}/>
                            </div>
                            <div className={styles.listBox}>
                                <div className={styles.separator}/>
                                <p className={styles.title}>
                                    Количество чеков:
                                    <span className={styles.titleSum}>{formatNumber(data?.trx ?? 0)}</span>
                                    <span className={styles.titlePercent}>(100%)</span>
                                </p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.trx1}
                                    percent={calculatePercentage(data?.trx as number, data?.trx1 as number)}
                                    text={'Без курьера'}
                                    handleClick={() => handleClick('trx1', 'Кол/чек - Без курьера')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.trx2}
                                    percent={calculatePercentage(data?.trx as number, data?.trx2 as number)}
                                    text={'Курьер ресторана'}
                                    handleClick={() => handleClick('trx2', 'Кол/чек - Курьер ресторана')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.trx3}
                                    percent={calculatePercentage(data?.trx as number, data?.trx3 as number)}
                                    text={'Курьер сервиса'}
                                    handleClick={() => handleClick('trx3', 'Кол/чек - Курьер сервиса')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.trx4}
                                    percent={calculatePercentage(data?.trx as number, data?.trx4 as number)}
                                    text={'Наш курьер сервис'}
                                    handleClick={() => handleClick('trx4', 'Кол/чек - Наш курьер сервис')}/>
                            </div>
                            <div className={styles.listBox}>
                                <div className={styles.separator}/>
                                <p className={styles.title}>
                                    Средний чек:
                                    <span className={styles.titleSum}>{formatNumber(data?.at ?? 0)}</span>
                                    <span className={styles.titlePercent}>(100%)</span>
                                </p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.at1}
                                    percent={calculatePercentage(data?.at as number, data?.at1 as number)}
                                    text={'Без курьера'}
                                    handleClick={() => handleClick('at1', 'Средний чек - Без курьера')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.at2}
                                    percent={calculatePercentage(data?.at as number, data?.at2 as number)}
                                    text={'Курьер ресторана'}
                                    handleClick={() => handleClick('at2', 'Средний чек - Курьер ресторана')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.at3}
                                    percent={calculatePercentage(data?.at as number, data?.at3 as number)}
                                    text={'Курьер сервиса'}
                                    handleClick={() => handleClick('at2', 'Средний чек - Курьер сервиса')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.at4}
                                    percent={calculatePercentage(data?.at as number, data?.at4 as number)}
                                    text={'Наш курьер сервис'}
                                    handleClick={() => handleClick('at2', 'Средний чек - Наш курьер сервис')}/>
                            </div>
                            <div className={styles.listBox}>
                                <div className={styles.separator}/>
                                <p className={styles.title}>
                                    Среднее чеков в день на точку:
                                    <span className={styles.titleSum}>{formatNumber(data?.adt ?? 0)}</span>
                                    <span className={styles.titlePercent}>(100%)</span>
                                </p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.adt1}
                                    percent={calculatePercentage(data?.adt as number, data?.adt1 as number)}
                                    text={'Без курьера'}
                                    handleClick={() => handleClick('adt1', 'Сред/чек/день - Без курьера')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.adt2}
                                    percent={calculatePercentage(data?.adt as number, data?.adt2 as number)}
                                    text={'Курьер ресторана'}
                                    handleClick={() => handleClick('adt2', 'Сред/чек/день - Курьер ресторана')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.adt3}
                                    percent={calculatePercentage(data?.adt as number, data?.adt3 as number)}
                                    text={'Курьер сервиса'}
                                    handleClick={() => handleClick('adt3', 'Сред/чек/день - Курьер сервиса')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.adt4}
                                    percent={calculatePercentage(data?.adt as number, data?.adt4 as number)}
                                    text={'Наш курьер сервис'}
                                    handleClick={() => handleClick('adt4', 'Сред/чек/день - Наш курьер сервис')}/>
                            </div>
                        </>
                    )
            }

            <Footer/>
        </MainLayout>
    );
};

