import {FileFromBack} from '../screens/quiz/store/types';

export const enum typeFlow {
    Checks = 'Checks',
    Tasks = 'Tasks',
    Reports = 'Reports',
    RepBots = 'RepBots',
    botRepV = 'botRepV',
    Purchase = 'Purchase',
    Information = 'Information',
    Profile = 'Profile',
    Inventory = 'Inventory',
    TimeControl = 'TimeControl',
    Courier = 'Courier',
    CallCenter = 'CallCenter',
    Marketing = 'Marketing',
    Products = 'Products',
    SOS = 'SOS',
    NewClaim = 'NewClaim',
    FinApp = 'FinApp',
    invChek = 'invChek',
    invNew = 'invNew',
    invOst = 'invOst',
    FotoRep = 'FotoRep',
    FotoRepList = 'FotoRepList',
    FotoRepNew = 'FotoRepNew',
    SCTGList = 'SCTGList',
    DPTGList = 'DPTGList',
    mainValuesRevenue = 'mainValuesRevenue',
    mainValuesWriteOff = 'mainValuesWriteOff',
    mainValuesCostPrice = 'mainValuesCostPrice',
    mainValuesCostPriceProc = 'mainValuesCostPriceProc',
    Learn = 'Learn',
    LearnChecksNoLoc = 'LearnChecksNoLoc',
    taskByNum = 'taskByNum',
    ATS = 'ATS',
    CheckInFoto = 'CheckInFoto',
    CheckOutFoto = 'CheckOutFoto',
    CheckInQR = 'CheckInQR',
    CheckOutQR = 'CheckOutQR',
    RepSmena = 'RepSmena',
    Monitor = 'Monitor',
    MonitorToDay = 'MonitorToDay',
    MonitorLoad = 'MonitorLoad',
    MonitorLine = 'MonitorLine',
    MonitorPer = 'MonitorPer',
    MonitorIst = 'MonitorIst',
    MonitorKur = 'MonitorKur',
    MonitorTime = 'MonitorTime',
    MonitorMap = 'MonitorMap',
    MonitorStopOb = 'MonitorStopOb',
    RegInObj = 'RegInObj',
    CourierOrderMap = 'CourierOrderMap',
    CourierVakMap = 'CourierVakMap',
    CourierShift = 'CourierShift',
    Reviews = 'Reviews',
    MonitorSC = 'MonitorSC',
    MonitorSCToDay = 'MonitorSCToDay',
    MonitorSCPer = 'MonitorSCPer',
    MonitorSCIst = 'MonitorSCIst',
    MonitorSCDost = 'MonitorSCDost',
    MonitorSCNePlat = 'MonitorSCNePlat',
    MonitorSCPoteri = 'MonitorSCPoteri',
    MonitorSCOprKass = 'MonitorSCOprKass',
    Video = 'Video',
    Loyalty = 'Loyalty',
    LoyClientInfo = 'LoyClientInfo',
    LoyRep1 = 'LoyRep1',
}

export type Status = {
    id: number;
    name: string;
}

export type ResponseStatuses = {
    list: Status[];
    status: boolean;
}

export type Option = { label: string; value: string };

export interface DateType {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface MiddlewareUploadFileResponse extends FileFromBack {
    status?: boolean;
    message?: string;
}

export interface IPermission {
    CanAccess: 0 | 1; //просмотр
    CanOtherSee: 0 | 1; // смотреть чужие
    CanOtherComm: 0 | 1; // писать в чат
    CanOtherEdit: 0 | 1; // редактировать
    CanPay: 0 | 1; // финансовые заявки пока нет
    CanVote: 0 | 1; // оставить подпись
    VoteType: 0 | 1; // тип голосования
}

export type TableData = {
    display: string;
    sortValue: number;
}
