import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {ChatOpt} from './types';

export const createChart = (chartId: string, items: ChatOpt[], cb: (name: string, value: string) => void) => {

    const data = items.map(i => {
        return {
            name: i.name,
            value: +i.value
        };
    });

    let root = am5.Root.new(chartId);

    if (root._logo) {
        root._logo.dispose();
    }

    root.setThemes([
        am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        width: am5.percent(80),
        x: am5.percent(5),
        innerRadius: am5.percent(20),
    }));

    let series = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'name',
        alignLabels: false,
        legendLabelText: '{name}',
        legendValueText: '{value}',
    }));


    series.set('colors', am5.ColorSet.new(root, {
        colors: [
            am5.color(0xC32B46), // Красный 1
            am5.color(0xff9900), // Оранжевый 3
            am5.color(0xfffb00), // Желтый 4
            am5.color(0x0066ff), // Синий 2
            am5.color(0x249600), // Зеленый 4
        ],
    }));


    series.labels.template.setAll({
        text: '{valuePercentTotal.formatNumber(\'0.0\')}%',
        textType: 'radial',
        centerX: am5.percent(27),
        centerY: am5.percent(50),
        fontSize: '12px',
        textAlign: 'left',
    });


    // Добавление легенды
    let legend = chart.children.push(am5.Legend.new(root, {
        x: am5.percent(60),
        centerX: am5.percent(-90),
        centerY: am5.percent(50),
        y: am5.percent(35),
        layout: root.verticalLayout
    }));

    legend.itemContainers.template.events.on('click', (ev) => {
        if (ev.target.dataItem) {
            const dataItem = ev.target.dataItem as any;
            const dataContext = dataItem.dataContext.dataContext as any;

            if (dataContext) {
                const name = dataContext.name;
                const value = dataContext.value;
                cb(name, String(value));
            }
        }
    });

    legend.labels.template.setAll({
        fontSize: '16px', // Размер шрифта
        fontWeight: '500', // Жирный шрифт
        textAlign: 'left', // Выравнивание текста по левому краю
    });

    series.data.setAll(data);

    series.appear(1000, 100);

    legend.data.setAll(series.dataItems);


    return root;
};
