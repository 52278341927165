export const config: { [key: string]: { keys: string[] } } = {
    main: {
        keys: [
            'ID_Obj',
            //  "ObjFormat",
            'ParentCode',
            'ObjName',
            'time',
            'breakEven',
            'plan',
            'toDay',
            'oneWeekAgo',
            'twoWeeksAgo',
            'threeWeeksAgo',
            'oneYearAgo',
            'checksCnt',
            'avgCheck'
        ]
    },
    botReport: {
        keys: [
            'ID_Obj',
            //'ObjFormat',
            'ParentCode',
            'ObjName',
            'time',
            'breakEven',
            'plan',
            'paySum',
            'pastInterval0',
            'pastInterval1',
            'pastInterval2',
            'pastInterval3',
            'toDay',
            'oneWeekAgo',
            'twoWeeksAgo',
            'threeWeeksAgo',
            'oneYearAgo',
            'checksCnt',
            'avgCheck',
            'name',
            // 'sid'
        ]
    },
    botReportStatusToday: {
        keys: [
            'name',
            'time',
        ]
    },
    paySum: {
        keys: ['ID_Obj', 'ParentCode', 'ObjName', 'paySum', 'toDay', 'breakEven', 'plan']
    },
    trx: {
        keys: ['ID_Obj', 'ParentCode', 'ObjName', 'checksCnt']
    },
    at: {
        keys: ['ID_Obj', 'ParentCode', 'ObjName', 'avgCheck']
    },
    adt: {
        keys: []
    },
    upt: {
        keys: []
    },
    uptm: {
        keys: []
    }
};
