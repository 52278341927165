import React, {useEffect} from 'react';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {ReviewFilter, ReviewObjectLocation} from './store/types';
import {createLineChart} from '../../lib';
import {
    useLazyGetReviewByObjQuery,
    useLazyGetReviewObjGroupQuery,
    useLazyGetReviewObjTableQuery
} from './store/review.api';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {TableObjects} from './components/table';
import styles from './style.module.scss';
import {Spin} from 'antd';
import {dateKeys, setReviewsFilter} from './store/review.slice';

function getParams(filter: ReviewFilter[], state: ReviewObjectLocation): string {
    let params = filter.map(item =>
        `${item.type}=${typeof item.value === 'object'
            ? (item.value as string[]).join(',')
            : item.value}`).join('&');

    return params;
}

export const ReviewByObjects = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location: Location = useLocation();
    const {state} = location as Location & { state: ReviewObjectLocation };
    const {filter} = useAppSelector(state => state.reviews);

    const [getByObj, {isFetching: loadCommon}] = useLazyGetReviewByObjQuery();
    const [getByObjGroup, {isFetching: loadGroup}] = useLazyGetReviewObjGroupQuery();
    const [getTable, {data: table}] = useLazyGetReviewObjTableQuery();

    useEffect(() => {
        const params = getParams(filter, state);
        getByObj({params})
            .unwrap()
            .then((data) => {
                setTimeout(() => createLineChart({datasets: data, isMultiple: false}), 200);
            });
        getTable({params});
    }, []);

    const handleSelectSome = (ids: number[]) => {
        const params = getParams(filter, state);
        if (ids.length > 0) {
            const joinIds = ids.join(',');
            getByObjGroup({params: `${params}&FObjects=${joinIds}`})
                .unwrap()
                .then((data) => {
                    setTimeout(() =>
                        createLineChart({datasets: data, isMultiple: true}), 200);
                });
        } else {
            getByObj({params})
                .unwrap()
                .then((data) => {
                    setTimeout(() => createLineChart({datasets: data, isMultiple: false}), 200);
                });
        }
    };

    const handleBack = () => {
        dispatch(setReviewsFilter(dateKeys));
        navigate(-1);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Cводные итоги по объектам'}/>
            <BackButton text={'Назад'} onClick={handleBack}/>
            {
                loadCommon || loadGroup
                    ? <div className={styles.spiner}>
                        <Spin percent={'auto'} size={'small'}/>
                    </div>
                    : <>
                        <div className={styles.chart} id={'dynamic'}></div>
                    </>
            }

            <div className={styles.wrapper}>
                <TableObjects table={table} selectItems={handleSelectSome}/>
            </div>
        </MainLayout>
    );
};
