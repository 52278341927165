import dayjs from 'dayjs';

export type DeliveryOrder = {
    ObjCoord: {
        Latitude: number;
        Longitude: number;
    },
    DeliveryCoord: {
        Latitude: number;
        Longitude: number;
    },
    ID_Obj: number;
    ObjName: string;
    OrderID: string;
    DeliveryAddress: string;
    AddressRem: string | null,
    OrderStatus: string;
    OrderStatus_en: string;
    OrderRem: string;
    DateCreated: string;
    LastUpdated: string;
}

const today = dayjs();
const weekAgo = dayjs().add(7, 'day');

export type CourierFilterItemType = 'FObjects' | 'FStaffs' | 'Start' | 'Finish';

export interface ICourierFilter {
    type: CourierFilterItemType | 'index',
    value: string | string[]
};

export const courierFilterKeys: ICourierFilter[] = [
    {type: 'Start', value: today.format('YYYY-MM-DD')},
    {type: 'Finish', value: weekAgo.format('YYYY-MM-DD')},
];

export type CourierState = {
    filter: ICourierFilter[];
    vacancy: Vacancy;
}

export type Vacancy = {
    ObjId?: string;
    Type: 'Insert' | 'Copy' | 'Update' | 'Delete',
    ID?: string | undefined;
    ID_Staff: string;
    IsActive: 1 | 0,
    Date: string;
    Cnt: string;
    Rem: string;
}

export const defaultVacancy: Vacancy = {
    ObjId: undefined,
    Type: 'Insert',
    ID: undefined,
    ID_Staff: '',
    IsActive: 1,
    Date: '',
    Cnt: '',
    Rem: ''
};

export type VacancyInfo = {
    ID: number;
    Cnt: number;
    Rem: string;
    Date: string;
    DateCreated: string;
    Author: string;
    ID_Staff: number;
    StaffName: string;
    StaffType: string;
    StaffRole: string;
    StaffRem: string | null,
    ID_Obj: number;
    ObjName: string;
    ObjFormat: string;
    ObjPlaceType: string;
    ID_ObjParent: number;
    ObjParentName: string;
    DailyRate: number | null;
    HourlyRate: number | null;
    DeliveryRate: number | null;
    KilometerRate: number | null;
    WorkDayStartTime: string | null;
    WorkDayEndTime: string | null;
}
