import {api} from '../../../store/api';
import {ProductDetail, ProductGraph, ProductType} from './types';
import {DynamicsDate} from '../../../lib/types';

export const productApi = api.injectEndpoints({
    endpoints: build => ({
        getTableProduct: build.query<ProductType[], { params: string }>({
            query: ({params}) => ({
                url: `/goodsReport/table?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return data.table.body;
            }
        }),
        getTableProductByObjects: build.query<{ head: string[], body: ProductDetail[] }, { params: string }>({
            query: ({params}) => ({
                url: `/goodsReport/tableByObj?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return {body: data.table.body, head: data.table.head.map((i: any) => i.title)};
            }
        }),
        getTableProductByPayments: build.query<{ head: string[], body: ProductDetail[] }, { params: string }>({
            query: ({params}) => ({
                url: `/goodsReport/tableByCorr?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return {body: data.table.body, head: data.table.head.map((i: any) => i.title)};
            }
        }),
        getGraphProduct: build.query<DynamicsDate[], { params: string }>({
            query: ({params}) => ({
                url: `/goodsReport/graphByDate?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return data.graphData.map((i: ProductGraph) => ({
                    date: i.date,
                    timestamp: i.timestamp,
                    cnt: i.value
                }));
            }
        })
    }), overrideExisting: true
});

export const {
    useGetTableProductQuery,
    useGetTableProductByObjectsQuery,
    useGetTableProductByPaymentsQuery,
    useGetGraphProductQuery
} = productApi;
