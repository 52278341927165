import React from 'react';
import styled from 'styled-components';
import {DatePicker, TimeRangePickerProps} from 'antd';
import dayjs from 'dayjs';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';

const {RangePicker} = DatePicker;
const today = dayjs();

const presets: TimeRangePickerProps['presets'] = [
    {label: 'Эта нед', value: [dayjs().startOf('week'), dayjs().endOf('week')]},
    {label: 'Пред нед', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().startOf('week').add(-1, 'd')]},
    {label: 'Этот мес', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
    {label: 'Пред мес', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().startOf('month').add(-1, 'd')]},
    {label: 'Этот кварт', value: [dayjs().startOf('quarter'), today.endOf('quarter')]},
    {
        label: 'Пред кварт',
        value: [dayjs().subtract(1, 'quarter').startOf('quarter'), dayjs().startOf('quarter').add(-1, 'd')]
    },
];

interface CustomPickerProps {
    height: number;
    Start: string;
    Finish: string;
    handleChangeDate: (dateString: string[]) => void;
}

export const CustomDataPicker = (
    {
        height,
        Start,
        Finish,
        handleChangeDate
    }: CustomPickerProps
) => {
    return (
        <Picker
            popupClassName="custom-range-picker-dropdown"
            locale={ru_RU}
            format="D MMM, YYYY"
            presets={presets}
            height={height}
            value={[dayjs(Start, 'YYYY-MM-DD'), dayjs(Finish, 'YYYY-MM-DD')]}
            onChange={(dates, dateStrings) =>
                handleChangeDate(
                    [
                        dayjs(dateStrings[0], 'D MMM, YYYY').format('YYYY-MM-DD'),
                        dayjs(dateStrings[1], 'D MMM, YYYY').format('YYYY-MM-DD'),
                    ]
                )}
            separator="-"
            allowClear={false}
        />
    );
};

type PickerType = {
    height: number
}

const Picker = styled(RangePicker).withConfig({
    shouldForwardProp: prop => !['height'].includes(prop)
})<PickerType>`
  display: flex;
  width: 100%;
  height: ${({height}) => height}px;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  background-color: #ffffff;
  color: var(--text-color);

  & input {
    text-align: center;
  }
`;
