import React from 'react';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {ProductFilter} from './store/types';
import {useGetTableProductByObjectsQuery, useGetTableProductByPaymentsQuery} from './store/product.api';
import {createParams} from './helpers';
import {useAppSelector} from '../../hooks/app.hooks';

export const ProductDetails = () => {
    const location = useLocation();
    const {state} = location as Location & { state: ProductFilter };
    const navigate = useNavigate();
    const {filter} = useAppSelector(state => state.product);

    const {data: tableObj} = useGetTableProductByObjectsQuery({
        params: createParams(filter, state)
    });

    const {data: tablePayments} = useGetTableProductByPaymentsQuery({
        params: createParams(filter, state)
    });


    const handleBack = () => {
        navigate(-1);
    };
    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={handleBack}/>
        </MainLayout>
    );
};
