import React from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import styles from './styles.module.scss';
import classNames from 'classnames';
import {SplitStringWithLineBreak} from './components';

export const MonitoringCashRegister = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location as Location & { state: { ID_Obj: string } };

    const onClickHandler = (index: number) => {
        navigate(`/monitoring-check-table`, {state: {...state, index}});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Мониторинг касс`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.list}>
                <div className={classNames(styles.card, styles.cardSimple)} onClick={() => onClickHandler(0)}>
                    <SplitStringWithLineBreak text={'Системный, балансовый отчёт'} className={styles.cardSubtext}/>
                </div>
                <div className={classNames(styles.card, styles.cardSimple)} onClick={() => onClickHandler(1)}>
                    <SplitStringWithLineBreak text={'По блюдам'} className={styles.cardSubtext}/>
                </div>
                <div className={classNames(styles.card, styles.cardSimple)} onClick={() => onClickHandler(2)}>
                    <SplitStringWithLineBreak text={'По официантам'} className={styles.cardSubtext}/>
                </div>
                <div className={classNames(styles.card, styles.cardSimple)} onClick={() => onClickHandler(3)}>
                    <SplitStringWithLineBreak text={'Чеки'} className={styles.cardSubtext}/>
                </div>
            </div>
        </MainLayout>
    );
};
