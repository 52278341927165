import React, {useEffect, useState} from 'react';
import {Location, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {ListButtons} from '../../components/listButtons';
import {useAppSelector} from '../../hooks/app.hooks';
import {
    useCheckByCameraMutation,
    useCheckByQrMutation,
    useLazyGetCredentialQuery,
    useLazyRegistrationInNewObjQuery,
    useSaveCredentialMutation
} from './store/api.timeControl';
import {authenticateFaceId, registerFaceId} from '../../authFunc';
import {toast} from 'react-toastify';
import {Modal} from '../../components/modal';
import {TimeControlStateLocation} from './store/types';
import {ScanContainer} from './components';
import {typeFlow} from '../../store/types';

export const TimeControl = () => {
    const navigate = useNavigate();
    const location: Location = useLocation();
    const {state} = location as Location & { state: TimeControlStateLocation };
    const {idForm} = useParams<{ idForm: string }>();
    const {data} = useGetAllQuestionsQuery();
    const [getCredential] = useLazyGetCredentialQuery();
    const [saveCredential] = useSaveCredentialMutation();
    const {name} = useAppSelector(state => state.auth.user);
    const buttonVariable = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));


    const [isScan, setIsScan] = useState<boolean>(false);

    const [checkByCamera, {isLoading}] = useCheckByCameraMutation();
    const [checkByQr, {isLoading: loadQr}] = useCheckByQrMutation();
    const [newRegistration] = useLazyRegistrationInNewObjQuery();

    useEffect(() => {
        if (state && state.FObjects) {
            const isPhoto = state?.checkType === typeFlow.CheckInFoto || state?.checkType === typeFlow.CheckOutFoto;
            const isOr = state?.checkType === typeFlow.CheckInQR || state?.checkType === typeFlow.CheckOutQR;

            if (isPhoto) {
                initialCheckByCam(state?.checkType);
            }
            if (state.checkType === typeFlow.RegInObj) {
                newRegistration({ID_Obj: state.FObjects})
                    .unwrap()
                    .then(({status, message}) => {
                        if (status) {
                            toast.info('Вы успешно зарегистрированы на новом объекте');
                            setTimeout(() => {
                                navigate(`/time-control/${idForm}`, {
                                    state: {
                                        FObjects: state.FObjects,
                                        checkType: state.checkType,
                                        ObjectName: state.ObjectName
                                    }
                                });
                            }, 300);
                        } else {
                            toast.error(message);
                        }
                    });
            }
            if (isOr) {
                setIsScan(true);
            }
        }

    }, []);

    function initialCheckByCam(checkType: string) {

        checkByCamera({
            FObjects: Number(state?.FObjects),
            checkType: checkType === typeFlow.CheckInFoto ? 1 : 2
        })
            .unwrap()
            .then(({message}) => {
                toast.info(message);
                if (message) {
                    setTimeout(() => toast.error(message), 500);
                } else {
                    navigate(`/time-confirmation/${idForm}`, {state: {ObjectName: state.ObjectName}});
                }
            })
            .catch((e) => {
                toast.error(JSON.stringify(e, null, 2), {
                    autoClose: false
                });
            });
    }

    const handleClick = async (item: ListMenu) => {
        if (item.typeMob === typeFlow.CheckInFoto || item.typeMob === typeFlow.CheckOutFoto) {
            getCredential()
                .unwrap()
                .then(async ({FaceID, FingerPrint}) => {
                    if (FaceID) {
                        await authenticateFaceId({base64encodedCredentialId: FaceID});
                    } else if (FingerPrint) {
                        await authenticateFaceId({base64encodedCredentialId: FingerPrint});
                    } else {
                        const {credentialId} = await registerFaceId({name: 'Task manager', displayName: name});
                        if (credentialId) {
                            await saveCredential({
                                body: {
                                    FaceID: credentialId,
                                    FingerPrint: credentialId
                                }
                            }).unwrap().then(() => getCredential());
                        }
                    }

                }).catch(err => {
                toast.error(JSON.stringify(err, null, 2), {
                    autoClose: false
                });
            }).finally(() => {

                if (state?.FObjects) {
                    initialCheckByCam(item.typeMob);
                } else {
                    navigate(`/select-location/${idForm}?from=TimeControl`, {state: {checkType: item.typeMob}});
                }

            });
        }


        if (item.typeMob === typeFlow.CheckInQR || item.typeMob === typeFlow.CheckOutQR) {
            navigate(`/select-location/${idForm}?from=TimeControl`, {state: {checkType: item.typeMob}});
        }
        if (item.typeMob === typeFlow.RepSmena) {
            navigate(`/time-work-shift/${idForm}`);
        }
        if (item.typeMob === typeFlow.RegInObj) {
            navigate(`/select-location/${idForm}?from=TimeControl`, {state: {checkType: item.typeMob}});
        }
    };

    const handleCheckByQrCode = (value: string) => {
        setIsScan(false);
        checkByQr(
            {FObjects: Number(state.FObjects), checkType: state.checkType === 'CheckInFoto' ? 1 : 2, qrContent: value}
        ).unwrap().then(({status, message}) => {
            status ? toast.info('Отметили') : toast.error(message, {autoClose: false});
        });
    };


    return (
        <>
            <ListButtons
                title={'Учет времени'}
                isLoading={isLoading || loadQr}
                buttons={buttonVariable?.children ?? []}
                handleClick={handleClick}/>
            <Modal isOpen={isScan} full={false}>
                <ScanContainer
                    onCansel={() => setIsScan(false)}
                    onScan={handleCheckByQrCode}
                />
            </Modal>
        </>
    );
};
