import React, {ReactNode} from 'react';
import styles from './style.module.scss';
import {useLocation} from 'react-router-dom';
import ru_RU from 'antd/lib/locale/ru_RU';
import 'dayjs/locale/ru';
import {ConfigProvider} from 'antd';
import dayjs from 'dayjs';
import {useCheckSessionQuery} from '../screens/auth/store/auth.api';
import {ToastContainer} from 'react-toastify';

dayjs.locale('ru-RU');

interface MainLayoutProps {
    children: ReactNode;
    isOverflowHidden?: boolean;
}

const noPaddingPages = ['/task-create', '/view-pdf'];

export const MainLayout = ({children, isOverflowHidden}: MainLayoutProps) => {
    const {pathname} = useLocation();
    useCheckSessionQuery(undefined, {
        pollingInterval: 60000
    });
    return (
        <div
            id={'main-container'}
            className={isOverflowHidden ? styles.mainContainerOverflowHidden : styles.mainContainer}
            style={{paddingBottom: noPaddingPages.includes(pathname) ? '0px' : '88px',}}>
            <ConfigProvider locale={ru_RU}>
                {children}
            </ConfigProvider>
            <ToastContainer position={'top-center'} autoClose={3000}/>
        </div>
    );
};
