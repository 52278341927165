import React from 'react';
import {VacancyInfo} from '../store/types';
import styles from './style.module.scss';

interface CardProp {
    item: VacancyInfo;
    onClick: () => void;
}

export const Card = ({item, onClick}: CardProp) => {

    return (
        <div className={styles.card} onClick={onClick}>
            <div className={styles.cardHeader}>
                <span className={styles.cardSubtext}>Вакансия</span>
                <span className={styles.cardTitle}>{item.StaffName}</span>
            </div>
            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>На число:</span>
                <span className={styles.cardText}>{item.Date}</span>
            </div>
            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>Объект:</span>
                <span className={styles.cardText}>{item.ObjName}</span>
            </div>
            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>Тип объекта:</span>
                <span className={styles.cardText}>{item.ObjPlaceType}</span>
            </div>

            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>Вакантные места:</span>
                <span className={styles.cardText}>{item.Cnt}</span>
            </div>
            <div className={styles.cardRow}>
                <span className={styles.cardText}>Ставки</span>
            </div>
            <div className={styles.cardRow}>
                <div className={styles.cardCol2}>
                    <span className={styles.cardSubtext}>Дневная:</span>
                    <span className={styles.cardText}>{item.DailyRate ?? '--'}</span>
                </div>
                <div className={styles.cardCol2}>
                    <span className={styles.cardSubtext}>Часовая:</span>
                    <span className={styles.cardText}>{item.HourlyRate ?? '--'}</span>
                </div>
                <div className={styles.cardCol2}>
                    <span className={styles.cardSubtext}>Доставки:</span>
                    <span className={styles.cardText}>{item.DeliveryRate ?? '--'}</span>
                </div>
                <div className={styles.cardCol2}>
                    <span className={styles.cardSubtext}>За километр:</span>
                    <span className={styles.cardText}>{item.KilometerRate ?? '--'}</span>
                </div>

            </div>
            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>Примечание:</span>
                <span className={styles.cardText}>{item.Rem}</span>
            </div>

        </div>
    );
};
