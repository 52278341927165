import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useLazyOrderShortQuery} from './store/monitoring.api';
import AppLoader from '../../components/appLoader';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {useLazyGetOrderInfoQuery} from '../monitoringOrder/store/order.api';
import {ScrollUp} from '../../components/scrollUp';
import {MonitoringFilterItemReq} from './store/types';
import {setFilter, todayDateKeys} from './store/monitoring.slice';

export const MonitoringLine = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [getShorts, {data, isFetching}] = useLazyOrderShortQuery();
    const [getOrderInfo] = useLazyGetOrderInfoQuery();
    const {filter} = useAppSelector(state => state.monitor);

    useEffect(() => {
        const params = filter.map(key => `${MonitoringFilterItemReq[key.type]}=${key.value}`).join('&');
        getShorts({params});
    }, [filter]);


    const handlePressCard = (ID_Obj: number, orderId: string) => {
        getOrderInfo({ID_Obj, orderId})
            .unwrap()
            .then(() => {
                navigate('/order-details');
            });
    };

    const handleBack = () => {
        dispatch(setFilter(todayDateKeys));
        navigate(-1);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Очередь заказов'}/>
            <BackButton text={'Назад'} onClick={handleBack}/>

            <div className={styles.wrapper}>
                <p className={styles.count}>Количество: {data?.length}</p>
                {isFetching
                    ? <AppLoader isFull={false}/>
                    : (
                        <>
                            {data?.map(item => {
                                return (
                                    <div key={item.orderId} className={styles.lineCard}
                                         onClick={() => handlePressCard(item.ID_Obj as number, String(item.orderId))}>
                                        <div className={styles.lineCardHeader}>
                                            <p className={styles.lineCardText}>Заказ № {item.OrderNumber}</p>
                                            <p className={styles.lineCardText}>{item.OrderStatus} </p>
                                        </div>
                                        <div className={styles.lineCardBody}>
                                            <p className={styles.lineCardText}>Объект:
                                                <span
                                                    className={styles.lineCardSubtext}>{item.ObjName ?? '---'}</span>
                                            </p>
                                            <p className={styles.lineCardText}>Сумма:
                                                <span
                                                    className={styles.lineCardBold}>{item.OrderSum.display ?? '---'}</span>
                                            </p>
                                            <div className={styles.lineCardRow}>
                                                <p className={styles.lineCardText}>Создан:
                                                    <span
                                                        className={styles.lineCardSubtext}> {item.DateCreated.display}</span>
                                                </p>
                                                <p className={styles.lineCardText}>Закрыт:
                                                    <span
                                                        className={styles.lineCardSubtext}> {item.DateClosed.display ?? '---'}</span>
                                                </p>
                                            </div>
                                            <p className={styles.lineCardText}>Контакт клиента:
                                                <span
                                                    className={styles.lineCardSubtext}>{item.ClientPhone ?? '---'}</span>
                                            </p>
                                            <p className={styles.lineCardText}>Примечание:
                                                <span
                                                    className={styles.lineCardSubtext}>{item.OrderRem ?? '---'}</span>
                                            </p>

                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )
                }
            </div>
            <ScrollUp/>
            <Footer/>
        </MainLayout>
    );
};
