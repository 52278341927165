import React from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {useNavigate} from 'react-router-dom';
import {useMonitoringControl} from '../../hooks';
import {useLazyGetBasicStopDataQuery} from './store/monitoring.api';
import AppLoader from '../../components/appLoader';
import styles from './styles.module.scss';
import {MonitoringDataCard} from './components';
import {MonitoringKeys} from '../../hooks/useMonitoringControl';

const labels: { [key: string]: string } = {
    object: 'Постановки в стоп по объектам',
    delivery: 'Постановки в стоп по доставке',
    hall: 'Постановки в стоп по самовывозу',
    pickup: 'Постановки в стоп по залу',
};

export const MonitoringByStop = () => {
    const navigate = useNavigate();

    const [getBasicData, {data, isLoading}] = useLazyGetBasicStopDataQuery();

    const {holding, jumpToDetails} = useMonitoringControl({
        onFetch: ({params}) => getBasicData({params})
    });


    const handleClick = (type: MonitoringKeys) => {

        jumpToDetails({key: type, labelPage: labels[type as string], necessaryPartPath: 'objStatus'});
        // switch (type) {
        //     // case 'object':
        //     //     return navigate('/monitoring-stop-table?type=object');
        //     // case 'delivery':
        //     //     return navigate('/monitoring-stop-table?type=delivery');
        //     // case 'hall':
        //     //     return navigate('/monitoring-stop-table?type=hall');
        //     // default:
        //     //     return navigate('/monitoring-stop-table?type=pickup');
        //     case 'object':
        //         return navigate(`/monitoring-stop-table/object?holding=${holding}`);
        //     case 'delivery':
        //         return navigate(`/monitoring-stop-table/delivery?holding=${holding}`);
        //     case 'hall':
        //         return navigate(`/monitoring-stop-table/hall?holding=${holding}`);
        //     default:
        //         return navigate(`/monitoring-stop-table/pickup?holding=${holding}`);
        // }

    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Постановки в стоп - ${holding.toUpperCase()}`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            <MonitoringDataCard
                                column={2}
                                num={data?.object}
                                text={'Обьекты'}
                                handleClick={() => handleClick('ObjStatusObject')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.delivery}
                                text={'Доставка'}
                                handleClick={() => handleClick('ObjStatusDelivery')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.hall}
                                text={'Зал'}
                                handleClick={() => handleClick('ObjStatusHall')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.pickup}
                                text={'Самовывоз'}
                                handleClick={() => handleClick('ObjStatusPickup')}
                            />

                        </div>
                    )
            }
        </MainLayout>
    );
};

const d = {
    'ID_Obj': 0,
    'ObjName': 'Все',
    'ObjStatusObject': {
        'display': '16',
        'sortValue': 16
    },
    'ObjStatusObjectLastPeriod': {
        'display': '23',
        'sortValue': 23
    },
    'ObjStatusObjectDynamic': {
        'display': '-30.4%',
        'sortValue': -30.434782608696
    },
    'ObjStatusDelivery': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusDeliveryLastPeriod': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusDeliveryDynamic': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusPickup': {
        'display': '1',
        'sortValue': 1
    },
    'ObjStatusPickupLastPeriod': {
        'display': '1',
        'sortValue': 1
    },
    'ObjStatusPickupDynamic': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusHall': {
        'display': '4',
        'sortValue': 4
    },
    'ObjStatusHallLastPeriod': {
        'display': '4',
        'sortValue': 4
    },
    'ObjStatusHallDynamic': {
        'display': null,
        'sortValue': null
    }
};
