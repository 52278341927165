import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {extractValueFromString, getObjectByIdForm} from '../../helpers';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import styles from './style.module.scss';
import {Input} from '../../fields/input';
import {useForm} from 'react-hook-form';
import {Value} from '../../fields/input/Input';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Button} from '../../fields/button';
import {useLazyGetLocationQuery} from './store/select.location.api';
import ButtonCard from '../../components/buttonCard';
import {LocationItem} from './store/types';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {ListMenu} from '../home/store/types';
import {setCurrentLocationObj} from '../inventory/store/inventory.slice';
import {setCurrentObjId} from '../quiz/store/quiz.slice';
import {QuizLocationState} from '../quiz/Quiz';
import {themColors} from '../../hooks/useChangeTheme';

export function getFromLabel(backLabel: string): string {
    let label: string = '';
    switch (backLabel.toLowerCase()) {
        case 'sos':
            label = 'SOS';
            break;
        case 'info':
            label = 'Информация';
            break;
        case 'inventory':
            label = 'Выбор объекта';
            break;
        case 'monitorscoprkass':
            label = 'Выбор объекта';
            break;

        default:
            label = 'КРО проверки';
    }
    return label;
}

interface FormField {
    search: string;
}

const schemaForm = new yup.ObjectSchema<FormField>({
    search: yup.string().required('Это поле обязательно к заполнению'),
});

export const SelectLocation = () => {
    const {idForm} = useParams();
    const {state, search} = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id_con} = useAppSelector(state => state.auth.user);
    const {theme} = useAppSelector(state => state.app);
    const {data} = useGetAllQuestionsQuery();
    const [getLocation, {data: location, isFetching}] = useLazyGetLocationQuery();

    const parent = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(state?.idParent));

    const backLabel = extractValueFromString(search, 'from');

    const {control, clearErrors, setValue, formState: {errors}, handleSubmit} = useForm({
        resolver: yupResolver(schemaForm)
    });

    useEffect(() => {
        getLocation({id_con});
    }, [id_con]);

    const submit = handleSubmit(values => {
        getLocation({id_con, params: `Search=${values.search}`});
    });

    const handleChangeInput = (fieldName: keyof FormField, value: Value) => {
        Object.keys(errors).length > 0 && clearErrors();
        setValue(fieldName, String(value));
    };
    console.log(backLabel);
    const handleSelect = (item: LocationItem) => {
        dispatch(setCurrentObjId(item));

        if (backLabel === 'TimeControl') {
            return navigate(`/time-control/${idForm}`, {
                state: {
                    FObjects: item.id,
                    ObjectName: item.name,
                    checkType: state.checkType
                }
            });
        }

        if (backLabel === 'LearnChecksNoLoc') {
            return navigate(`/select-employee/${idForm}`, {
                state: {path: state?.path, isEmployee: true} as QuizLocationState
            });
        }
        if (backLabel === 'FotoRepList') {
            return navigate('/photo-report?from=FotoRepList');
        }
        if (backLabel === 'FotoRepNew') {
            return navigate('/photo-report?from=FotoRepNew');
        }
        if (backLabel?.toLowerCase() === 'sos') {
            return navigate('/quickly-task-menu?from=sos', {
                state: {
                    idObj: item.id,
                }
            });
        }
        if (backLabel?.toLowerCase() === 'info') {
            return navigate('/info-view', {
                state: {
                    FObjects: item.id,
                }
            });
        }
        if (backLabel?.toLowerCase() === 'inventory') {
            dispatch(setCurrentLocationObj(item));
            return navigate(`/inventory/${idForm}`);
        }
        if (backLabel === 'MonitorSCOprKass') {
            return navigate(`/monitoring-cash-register`, {
                state: {ID_Obj: item.id}
            });
        }

        navigate(`/quiz`, {
            state: {
                idObj: item.id,
                parentName: parent?.name,
                path: state?.path,
                locationName: item.name,
                isEmployee: false
            } as QuizLocationState
        });
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`${getFromLabel(backLabel ?? '')}`}/>
            <BackButton text={`Назад`} onClick={() => navigate(-1)}/>
            <div className={styles.container}>
                <h2 className={styles.title}>Поиск объекта:</h2>
                <p className={styles.text}>введите часть названия, кода или адреса,<br/>и нажмите Поиск</p>
                <div className={styles.wrapper}>
                    <div className={styles.search}>
                        <Input
                            type={'text'}
                            fieldName={'search'}
                            control={control}
                            onChange={(value) => handleChangeInput('search', value)}/>
                    </div>
                    <div className={styles.button}>
                        <Button
                            text={'Поиск'}
                            textStyleType={'regular'}
                            style={{
                                border: `1px solid ${themColors[theme].color}`,
                                borderRadius: '12px',
                                fontSize: '14px'
                            }}
                            onClick={submit}
                        />
                    </div>
                </div>
                {isFetching ? <p className={styles.loadingText}>идет запрос...</p>
                    : (
                        <div className={styles.listContainer}>
                            <h2 className={styles.title}>Выбрать объект:</h2>
                            <div className={styles.list}>
                                {location?.list.map(item => (
                                    <ButtonCard
                                        key={item.id}
                                        name={item.name}
                                        type={'iconsvg'}
                                        src={'free-icon-font-government-flag-13794316.svg'}
                                        className={styles.locationCard}
                                        iconClass={''}
                                        iconWidth={32}
                                        iconHeight={32}
                                        onClick={() => {
                                            handleSelect(item);
                                        }}/>
                                ))}
                            </div>
                        </div>
                    )
                }
                {/*{location?.list?.length ? (*/}
                {/*    <div className={styles.listContainer}>*/}
                {/*        <h2 className={styles.title}>Выбрать объект:</h2>*/}
                {/*        <div className={styles.list}>*/}
                {/*            {location?.list.map(item => (*/}
                {/*                <ButtonCard*/}
                {/*                    key={item.id}*/}
                {/*                    name={item.name}*/}
                {/*                    type={'url'}*/}
                {/*                    src={cardSource}*/}
                {/*                    className={styles.locationCard}*/}
                {/*                    iconClass={''}*/}
                {/*                    iconWidth={32}*/}
                {/*                    iconHeight={32}*/}
                {/*                    onClick={() => {*/}
                {/*                        handleSelect(item)*/}
                {/*                    }}/>*/}
                {/*            ))}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*) : null}*/}
            </div>
        </MainLayout>
    );
};
