import React from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/app.hooks';
import {useGetDocumentsTypesQuery} from './store/photoReport.api';
import styles from './style.module.scss';
import AppLoader from '../../components/appLoader';
import {extractValueFromString} from '../../helpers';
import {PhotoReportDocType} from './store/types';
import {setPhotoReportType} from './store/photoReport.slice';

export const PhotoReport = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data, isLoading} = useGetDocumentsTypesQuery();

    const backLabel = extractValueFromString(search, 'from');

    const handleSelectType = (item: PhotoReportDocType) => {
        dispatch(setPhotoReportType(item.id));
        if (backLabel === 'FotoRepList') {
            navigate(`/photo-report-list`);
        }

        if (backLabel === 'FotoRepNew') {
            navigate(`/photo-report-create?reportType=${item.name}`);
        }
    };
    console.log(data);
    return (
        <MainLayout>
            <Header breadCrumbs={backLabel === 'FotoRepList' ? 'Список фото отчетов' : 'Новый фото отчет'}/>
            <BackButton text={'Назад'} onClick={() => {
                dispatch(setPhotoReportType(null));
                navigate(-1);
            }}/>
            {isLoading
                ? <AppLoader isFull={false}/>
                : (
                    <div className={styles.container}>
                        {data?.map((button) => (
                            <div className={styles.card} key={button.id}>
                                <span className={styles.cardText}
                                      onClick={() => handleSelectType(button)}>{button.name}</span>
                            </div>
                        ))}
                    </div>
                )}

        </MainLayout>
    );
};
