import React from 'react';
import {SwitcherButtonS, SwitcherS} from '../styled';
import {tabButtons} from '../constants';

interface SwitcherTabProps {
    index: number;
    setIndex: (index: number) => void;
}

export const SwitcherTab = ({index, setIndex}: SwitcherTabProps) => {
    return (
        <SwitcherS>
            {tabButtons.map((item, idx) =>
                <SwitcherButtonS
                    key={item}
                    isActive={idx === index}
                    onClick={() => setIndex(idx)}
                >{item}</SwitcherButtonS>)}
        </SwitcherS>
    );
};
