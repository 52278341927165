import React from 'react';
import styled from 'styled-components';
import {CustomDataPicker} from '../../../components/CustomDataPicker';
import {font} from '../../../utils/styledComponentsMixins';
import {fParentCodesButton} from '../constants';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {setProductFilter} from '../store/product.slice';
import {SimpleInput} from '../../../fields/simpleInput';

interface FilterProps {
    value: string;
    onSearch: (value: string) => void;
}

export const Filter = (
    {
        value,
        onSearch
    }: FilterProps
) => {
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state.product);

    const handleChangeDate = (dateString: string[]) => {
        const newFilter = {...filter};
        newFilter.Start = dateString[0];
        newFilter.Finish = dateString[1];

        dispatch(setProductFilter(newFilter));
    };

    const handleSwitcher = (type: string) => {
        const newFilter = {...filter};
        const currentFParentCodes = newFilter.FParentCodes as string[] || [];

        newFilter.FParentCodes = currentFParentCodes.includes(type)
            ? currentFParentCodes.filter(i => i !== type)
            : [...currentFParentCodes, type];

        dispatch(setProductFilter(newFilter));
    };

    return (
        <FilterS>
            <RowS>
                <CustomDataPicker
                    height={40}
                    Start={filter.Start as string}
                    Finish={filter.Finish as string}
                    handleChangeDate={handleChangeDate}
                />
            </RowS>
            <RowS>
                <SwitcherS>
                    {fParentCodesButton.map(button =>
                        <SwitcherButtonS key={button}
                                         isActive={filter.FParentCodes?.includes(button) || false}
                                         onClick={() => handleSwitcher(button)}
                        >
                            {button}
                        </SwitcherButtonS>)}
                </SwitcherS>
            </RowS>
            <RowS>
                <SimpleInput
                    type={'text'}
                    value={value}
                    readonly={false}
                    placeholder={'Поиск по названию'}
                    size={'sm'}
                    onChange={onSearch}
                />
            </RowS>
        </FilterS>
    );
};

const FilterS = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RowS = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SwitcherS = styled.div`
  padding: 3px;
  border-radius: 12px;
  background-color: #FFFFFF;
  width: 100%;
  height: 40px;
  display: flex;
  gap: 5px;
  border: 1px solid var(--border-color);
`;

const SwitcherButtonS = styled.button.withConfig({
    shouldForwardProp: prop => !['isActive'].includes(prop)
})<{ isActive: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${({isActive}) => isActive ? 'var(--border-color)' : 'var(--disable-color)'};
  background-color: ${({isActive}) => isActive ? 'var(--border-color)' : '#ffffff'};
  ${font.text(400)};
  color: ${({isActive}) => isActive ? '#ffffff' : 'var(--text-color)'};
  font-size: 14px;
  text-transform: uppercase;
`;
