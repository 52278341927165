import React from 'react';
import styles from '../style.module.scss';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {changeSearchParams} from '../store/loyalty.slice';

function formatCreditCardNumber(cardNumber: string): string {
    const sanitized = cardNumber.replace(/\D/g, '');
    return sanitized.match(/.{1,4}/g)?.join(' ') || '';
}

function formatPhoneNumber(phoneNumber: string): string {
    const sanitized = phoneNumber.replace(/\D/g, '');
    if (sanitized.length > 0 && !sanitized.startsWith('7')) {
        return `7${sanitized.slice(0, 10)}`;
    }
    return sanitized.slice(0, 11);
}

export const CriteriaType = () => {
    const dispatch = useAppDispatch();
    const {searchParams} = useAppSelector(state => state.loyalty);

    const handleSetParams = (value: string) => dispatch(changeSearchParams({...searchParams, id: value}));

    if (searchParams.type === 'cardNumber') {
        return (
            <input
                className={styles.filterInput}
                value={searchParams.id}
                placeholder="Введите номер карты"
                maxLength={19}
                onChange={(event) => {
                    handleSetParams(formatCreditCardNumber(event.target.value));
                }}
            />
        );
    }
    if (searchParams.type === 'phone') {
        return (
            <input
                className={styles.filterInput}
                value={searchParams.id}
                placeholder="Введите номер телефона"
                maxLength={11}
                onChange={(event) => {
                    handleSetParams(formatPhoneNumber(event.target.value));
                }}
            />
        );
    }
    if (searchParams.type === 'cardBarcode') {
        return (
            <input
                className={styles.filterInput}
                value={searchParams.id}
                placeholder="Введите код"
                onChange={(event) => {
                    handleSetParams(event.target.value);
                }}
            />
        );
    }

    return (
        <input
            className={styles.filterInput}
            value={searchParams.id}
            placeholder="Введите ID клиента"
            onChange={(event) => {
                handleSetParams(event.target.value);
            }}
        />
    );
};
