import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {ReviewBody, ReviewOrs} from '../../screens/reviews/store/types';
import {TableData} from '../../screens/monitoring/MonitoringDetails';
import {Icon} from '../../assets/customSvg';

interface TableProps<T> {
    headLabels: string[];
    body: T[];
}

type Sort = {
    index: number;
    direction: 'asc' | 'desc';
}

export const Table = <T extends TableData | ReviewBody | ReviewOrs, >(
    {
        headLabels,
        body
    }: TableProps<T>
) => {
    const width = (window.innerWidth - 42);
    const firstItemWidth = (width / headLabels.length) + 60;
    const itemWidth = (width - firstItemWidth) / (headLabels.length - 1);

    const [currentSort, setCurrentSort] = useState<Sort | null>(null);
    const [sortedData, setSortData] = useState<TableData[]>(body as TableData[]);

    useEffect(() => {
        if (body.length > 0) {
            setSortData(body as TableData[]);
        }
    }, [body]);

    function sortData(data: TableData[], column: keyof TableData, order = 'asc') {
        return data.sort((a, b) => {
            const valA = parseValue(a[column]);
            const valB = parseValue(b[column]);

            if (valA === null && valB === null) return 0;
            if (valA === null) return 1;
            if (valB === null) return -1;

            if (valA < valB) return order === 'asc' ? -1 : 1;
            if (valA > valB) return order === 'asc' ? 1 : -1;

            return 0;
        });
    }

    function parseValue(value: string) {
        if (value === null || value === undefined) return null;

        // Удаляем пробелы, проценты и переводим в число, если возможно
        const parsed = parseFloat(value.replace(/[\s%]/g, ''));
        return isNaN(parsed) ? value : parsed;
    }


    const handleChangeSort = ({index, direction}: Sort) => {
        setCurrentSort({index, direction});
        if (index === 1) {
            const sortedByValueAsc = sortData(body as TableData[], 'value', direction);
            setSortData(sortedByValueAsc);
        }
        if (index === 2) {
            const sortedByValueAsc = sortData(body as TableData[], 'lastPeriod', direction);
            setSortData(sortedByValueAsc);
        }
        if (index === 3) {
            const sortedByValueAsc = sortData(body as TableData[], 'dynamic', direction);
            setSortData(sortedByValueAsc);
        }
    };

    return (
        <div className={styles.table}>
            <div className={styles.tableHead}>
                {headLabels.map((item, idx) => {
                    const isAsc = currentSort && currentSort.index === idx && currentSort.direction === 'asc';
                    const isDesc = currentSort && currentSort.index === idx && currentSort.direction === 'desc';
                    return (
                        <div
                            key={idx}
                            className={styles.tableItem}
                            style={{width: idx === 0 ? `${firstItemWidth}px` : `${itemWidth}px`}}
                            onClick={() =>
                                idx > 0
                                    ? handleChangeSort({
                                        index: idx,
                                        direction: currentSort?.direction === 'asc' ? 'desc' : 'asc'
                                    }) : null
                            }
                        >
                            <p className={styles.tableTitle}>{item}</p>
                            {idx > 0 ? <div className={styles.tableSort}>
                                <Icon width={14} height={9} color={isDesc ? '#ff5555' : '#676767'} fileName={'up-one'}/>
                                <Icon width={14} height={9} color={isAsc ? '#ff5555' : '#676767'}
                                      fileName={'down-one'}/>
                            </div> : null}
                        </div>
                    );
                })}
            </div>

            {'lastPeriod' in (body[0] ?? {}) ? (sortedData as TableData[]).map((item, index) => (
                <div key={index} className={styles.tableRow}>
                    <div className={styles.tableItem}
                         style={{width: `${firstItemWidth}px`}}>
                        <p className={styles.tableRowText}>{item.name}</p>
                    </div>
                    <div className={styles.tableItem}
                         style={{width: `${itemWidth}px`}}>
                        <p className={styles.tableRowText}>{item.value}</p>
                    </div>
                    <div className={styles.tableItem}
                         style={{width: `${itemWidth}px`}}>
                        <p className={styles.tableRowText}>{item.lastPeriod}</p>
                    </div>
                    <div className={styles.tableItem}
                         style={{width: `${itemWidth}px`}}>
                        <p className={styles.tableRowText}>{item.dynamic}</p>
                    </div>
                </div>
            )) : null}
        </div>
    );
};
