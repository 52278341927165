import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {useChangeVacancyMutation, useGetStaffListQuery} from './store/courier.api';
import styles from './style.module.scss';
import {DatePicker, DatePickerProps, Select} from 'antd';
import {Textarea} from '../../fields/textarea';
import {SimpleInput} from '../../fields/simpleInput';
import {Button} from '../../fields/button';
import dayjs from 'dayjs';
import {useGetFilterObjMutation} from '../../components/financialFilter/store/financial.filter.api';
import {fromJsonToUrlencoded} from '../../helpers';
import {changeVacancy} from './store/courier.slice';
import {toast} from 'react-toastify';
import {defaultVacancy} from './store/types';
import classNames from 'classnames';

export const VacanciesCreate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {vacancy} = useAppSelector(state => state.courier);
    const [getFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});
    const {data: positions} = useGetStaffListQuery({FObjects: vacancy.ObjId as string}, {
        skip: !vacancy.ObjId,
        refetchOnMountOrArgChange: true
    });

    const [actionVacancy] = useChangeVacancyMutation();

    useEffect(() => {
        !objects?.listObject &&
        getFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});
    }, []);

    const handleChangeField = (key: string, value: string) => {
        dispatch(changeVacancy({...vacancy, [`${key}`]: value}));
    };

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        dispatch(changeVacancy({...vacancy, Date: dateString}));
    };
    console.log(vacancy);
    const onSave = () => {
        actionVacancy({...vacancy})
            .unwrap()
            .then(({status, VacancyInfo}) => {
                if (status) {
                    toast.info(`Вакансия ${VacancyInfo.StaffName}, на объекте ${VacancyInfo.ObjName} успешно создана.`);
                } else {
                    toast.error('Что-то пошло не так. Попробуйте позже.');
                }
            })
            .catch(() => toast.error('Что-то пошло не так. Попробуйте позже.'))
            .finally(() => {
                dispatch(changeVacancy(defaultVacancy));
                setTimeout(() => navigate('/vacancies'), 2000);
            });
        // if (!vacancy.ID) {
        //     actionVacancy({
        //         Type: 'Insert',
        //         ID_Staff: vacancy.ID_Staff,
        //         IsActive: 1,
        //         Date: vacancy.Date,
        //         Cnt: vacancy.Cnt,
        //         Rem: vacancy.Rem
        //     })
        //         .unwrap()
        //         .then(({status, VacancyInfo}) => {
        //             if (status) {
        //                 toast.info(`Вакансия ${VacancyInfo.StaffName}, на объекте ${VacancyInfo.ObjName} успешно создана.`);
        //             } else {
        //                 toast.error('Что-то пошло не так. Попробуйте позже.');
        //             }
        //         })
        //         .catch(() => toast.error('Что-то пошло не так. Попробуйте позже.'))
        //         .finally(() => setTimeout(() => navigate('/vacancies'), 2000));
        // } else {
        //     actionVacancy({...vacancy})
        //         .unwrap()
        //         .then(({status, VacancyInfo}) => {
        //             if (status) {
        //                 toast.info(`Вакансия ${VacancyInfo.StaffName}, на объекте ${VacancyInfo.ObjName} успешно создана.`);
        //             } else {
        //                 toast.error('Что-то пошло не так. Попробуйте позже.');
        //             }
        //         })
        //         .catch(() => toast.error('Что-то пошло не так. Попробуйте позже.'))
        //         .finally(() => setTimeout(() => navigate('/vacancies'), 2000));
        // }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Вакансии'}/>
            <BackButton text={'Назад'} onClick={() => {
                dispatch(changeVacancy(defaultVacancy));
                navigate(-1);
            }}/>
            <div className={'container'}>
                <div className={styles.space}/>
                <div className={styles.selectWrap}>
                    <div className={styles.taskFormRowLabelBox}>
                        <label className={styles.selectLabel}>Объект</label>
                    </div>
                    <div className={styles.taskFormRowInputBox}>
                        <Select
                            className={styles.select}
                            options={objects?.listObject}
                            filterOption={(input, option) => {
                                return (option?.label.toUpperCase() ?? '').includes(input.toUpperCase());
                            }}
                            showSearch
                            value={String(vacancy?.ObjId ?? '')}
                            onChange={(value) => handleChangeField('ObjId', value)}
                        />
                    </div>
                </div>
                <div className={styles.selectWrap}>
                    <div className={styles.taskFormRowLabelBox}>
                        <label className={styles.selectLabel}>Должность</label>
                    </div>
                    <div className={styles.taskFormRowInputBox}>
                        <Select
                            className={!positions ? classNames(styles.select, styles.selectDisabled) : styles.select}
                            options={positions}
                            filterOption={(input, option) => {
                                return (option?.label.toUpperCase() ?? '').includes(input.toUpperCase());
                            }}
                            disabled={!positions}
                            showSearch
                            value={vacancy.ID_Staff}
                            onChange={(value) => {
                                handleChangeField('ID_Staff', value);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.selectWrap}>
                    <div className={styles.taskFormRowLabelBox}>
                        <label className={styles.selectLabel}>Дата</label>
                    </div>
                    <div className={styles.taskFormRowInputBox}>
                        <DatePicker
                            className={styles.select}
                            placeholder={''}
                            disabled={false}
                            value={vacancy && vacancy?.Date !== '' ? dayjs(vacancy?.Date) : undefined}
                            onChange={onChange}/>
                    </div>
                </div>
                <div className={styles.selectWrap}>
                    <SimpleInput
                        type={'number'}
                        placeholder={'Количество сотрудников'}
                        value={vacancy.Cnt}
                        size={'sm'}
                        readonly={false}
                        onChange={value => {
                            handleChangeField('Cnt', String(value));
                        }}
                    />
                </div>
                <div className={styles.selectWrap}>
                    <Textarea
                        placeholder={'Примечание'}
                        value={vacancy.Rem}
                        onBlur={() => {
                        }}
                        onChange={value => {
                            handleChangeField('Rem', String(value));
                        }}
                    />
                </div>
                <div className={styles.bottomWrapper}>
                    <Button
                        text={'Сохранить'}
                        textStyleType={'semiBold'}
                        style={{
                            fontSize: '16px',
                            height: '50px'
                        }}
                        onClick={(e) => onSave()}
                    />
                </div>
            </div>
        </MainLayout>
    );
};
