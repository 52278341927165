type Head = string[];
export type Body = string[][];

export type ResultCashCheck = {
    head: Head
    body: Body
}

const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};


export const mapCheckResponse = (data: any): ResultCashCheck => {
    const head: Head = [];
    const body: Body = [];

    if (data?.table?.head) {
        // Извлекаем заголовки из `data.table.head`
        data.table.head.forEach((column: any) => {
            const title = column.title?.replace(/&nbsp;/g, '').trim() || '';

            // Проверяем, есть ли в заголовке даты формата dd.mm - dd.mm
            const dateRangeMatch = title.match(/\d{2}\.\d{2}\s*-\s*\d{2}\.\d{2}/);
            console.log('dateRangeMatch', dateRangeMatch);
            if (dateRangeMatch) {
                head.push(dateRangeMatch[0]); // Если даты найдены, добавляем их в `head`
            } else {
                head.push(title);
            }
        });
    }

    if (data?.table?.body) {
        // Преобразуем строки таблицы в массив строк (Body)
        data.table.body.forEach((row: any) => {
            const rowData: string[] = [];
            data.table.head.forEach((column: any) => {
                const key = column.data;
                if (typeof key === 'string') {
                    // Простое поле
                    rowData.push(row[key]?.replace(/&nbsp;/g, '').trim() ?? '');
                } else if (key && key._) {
                    // Комплексное поле
                    const value = getNestedValue(row, key._);
                    rowData.push(value?.toString()?.replace(/&nbsp;/g, '').trim() ?? '');
                } else {
                    rowData.push('');
                }
            });
            body.push(rowData);
        });
    }

    return {head, body};
};
