import React from 'react';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {useLazyGetNonPayerQuery} from './store/monitoring.api';
import {useMonitoringControl} from '../../hooks';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MonitoringDataCard, MonitoringFilter} from './components';
import AppLoader from '../../components/appLoader';
import Footer from '../../components/footer';
import {HoldingType} from '../report/store/types';
import styles from './styles.module.scss';
import {MonitoringKeys} from '../../hooks/useMonitoringControl';

export const MonitoringNonPayers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {search} = location as Location;
    const [getByCourier, {data, isLoading}] = useLazyGetNonPayerQuery();
    const holding = search.replace('?holding=', '') as HoldingType;
    // const {handleClick} = useGetMonitoringTable();

    const {applyFilter, clearFilter, jumpToDetails} = useMonitoringControl({
        onFetch: ({params}) => getByCourier({params})
    });


    const handleClick = (key: MonitoringKeys, labelPage: string) => {
        jumpToDetails({key, necessaryPartPath: 'defaulters', labelPage});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Мониторинг ${holding.toUpperCase()} - Неплательщики`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <>
                            <div className={styles.wrapper}>
                                <p className={styles.title}>
                                    Администрация
                                </p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.admin.paySum}
                                    percent={undefined}
                                    text={'Выручка всего'}
                                    handleClick={() => handleClick('paySumAdmin', 'Выручка всего')}
                                />
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.admin.trx}
                                    percent={undefined}
                                    text={'Количество чеков'}
                                    handleClick={() => handleClick('trxAdmin', 'Количество чеков')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.admin.at}
                                    percent={undefined}
                                    text={'Средний чек'}
                                    handleClick={() => handleClick('atAdmin', 'Средний чек')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.admin.adt}
                                    percent={undefined}
                                    text={'Средн чеков/день/тчк'}
                                    handleClick={() => handleClick('adtAdmin', 'Средн чеков/день/тчк')}/>
                            </div>
                            <div className={styles.listBox}>
                                <div className={styles.separator}/>
                                <p className={styles.title}>
                                    Бонусы
                                </p>
                            </div>
                            <div className={styles.list}>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.bonus.paySum}
                                    percent={undefined}
                                    text={'Выручка всего'}
                                    handleClick={() => handleClick('paySumBonus', 'Выручка всего')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.bonus.trx}
                                    percent={undefined}
                                    text={'Количество чеков'}
                                    handleClick={() => handleClick('trxBonus', 'Количество чеков')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.bonus.at}
                                    percent={undefined}
                                    text={'Средний чек'}
                                    handleClick={() => handleClick('atBonus', 'Средний чек')}/>
                                <MonitoringDataCard
                                    column={2}
                                    num={data?.bonus.adt}
                                    percent={undefined}
                                    text={'Средн чеков/день/тчк'}
                                    handleClick={() => handleClick('adtBonus', 'Средн чеков/день/тчк')}/>
                            </div>
                        </>
                    )
            }
            <Footer/>
        </MainLayout>
    );
};

