import styled from 'styled-components';
import {font} from '../../utils/styledComponentsMixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  height: calc(var(--app-height) - 74px);
`;

export const ListS = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SwitcherS = styled.div`
  padding: 3px;
  border-radius: 12px;
  background-color: #FFFFFF;
  width: 100%;
  min-height: 40px;
  height: 40px;
  display: flex;
  gap: 5px;
  border: 1px solid var(--border-color);
`;

export const SwitcherButtonS = styled.button.withConfig({
    shouldForwardProp: prop => !['isActive'].includes(prop)
})<{ isActive: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${({isActive}) => isActive ? 'var(--border-color)' : 'var(--disable-color)'};
  background-color: ${({isActive}) => isActive ? 'var(--border-color)' : '#ffffff'};
  ${font.text(400)};
  color: ${({isActive}) => isActive ? '#ffffff' : 'var(--text-color)'};
  font-size: 14px;
  text-transform: uppercase;
`;

export const ChartContainer = styled.div`
  height: 250px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
`;
