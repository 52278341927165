import {createSlice} from '@reduxjs/toolkit';
import {ProductFilter, ProductState} from './types';
import dayjs from 'dayjs';

const today = dayjs().format('YYYY-MM-DD');
const oneWeekAge = dayjs().subtract(1, 'week').format('YYYY-MM-DD');

export const defaultProductFilter: ProductFilter = {
    Start: today,
    Finish: oneWeekAge,
    FParentCodes: ['corp', 'fr']
};

export const initialState: ProductState = {
    filter: defaultProductFilter
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductFilter: (state, {payload}) => {
            state.filter = payload;
        }
    }
});

export const {setProductFilter} = productSlice.actions;
export default productSlice.reducer;
