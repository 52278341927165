import React, {useEffect, useState} from 'react';
import {SimpleInput} from '../../../fields/simpleInput';
import {useDebounce} from '../../../hooks';
import {Body} from '../../../helpers/mapCheckResponse';
import styles from '../styles.module.scss';

const searchByName = (data: Body, searchTerm: string): Body => {
    return data.filter(row =>
        typeof row[1] === 'string' && row[1].toLowerCase().includes(searchTerm.toLowerCase())
    );
};

interface SearchByProductNameProps {
    data: Body;
    getFilteredData: (data: Body) => void;
}

export const SearchByProductName = ({data, getFilteredData}: SearchByProductNameProps) => {
    const [value, setValue] = useState<string>('');
    const {debounceValue} = useDebounce({value, delay: 300});

    useEffect(() => {
        const result = searchByName(data, debounceValue);
        getFilteredData(debounceValue === '' ? data : result);
    }, [debounceValue]);

    return (
        <div className={styles.cashSearch}>
            <SimpleInput
                type={'text'}
                value={value}
                placeholder={'Поиск по названию'}
                readonly={false}
                size={'sm'}
                onChange={value => setValue(value)}
            />
        </div>
    );
};
