import React from 'react';
import styles from '../styles.module.scss';
import {capitalizeFirstLetter, formatNumber} from '../../../helpers';
import {MonitoringDataCard} from './MonitoringDataCard';
import {calculatePercentage} from '../../../helpers/calculatePercentage';
import {BySource, SourceType} from '../store/types';
import {MonitoringKeys} from '../../../hooks/useMonitoringControl';
import classNames from 'classnames';

interface SourceBlockProps {
    data: BySource;
    label: string;
    isSeparator?: boolean;
    keyName: keyof SourceType;
    onClick: (key: MonitoringKeys, label: string) => void;
}

export const SourceBlock = (
    {
        data,
        label,
        isSeparator = true,
        keyName,
        onClick
    }: SourceBlockProps
) => {

    return (
        <>
            <div className={styles.listBox}>
                <div
                    className={isSeparator ? styles.separator : classNames(styles.separator, styles.separatorNotLine)}/>
                <p className={styles.title}>
                    {label}
                    <span className={styles.titleSum}>{formatNumber(data?.total[keyName] ?? 0)}</span>
                    <span className={styles.titlePercent}>(100%)</span>
                </p>
            </div>

            <div className={styles.list}>
                <MonitoringDataCard
                    column={2}
                    num={data?.dominoWEB[keyName]}
                    percent={calculatePercentage(data?.total[keyName] as number, data?.dominoWEB[keyName] as number)}
                    text={'Web'}
                    handleClick={() => onClick(`dominoWEB${capitalizeFirstLetter(keyName)}` as MonitoringKeys, `${label} - Web`)}
                />
                <MonitoringDataCard
                    column={2}
                    num={data?.dominoAPP[keyName]}
                    percent={calculatePercentage(data?.total[keyName] as number, data?.dominoAPP[keyName] as number)}
                    text={'App'}
                    handleClick={() => onClick(`dominoAPP${capitalizeFirstLetter(keyName)}` as MonitoringKeys, `${label} - App`)}/>
                <MonitoringDataCard
                    column={2}
                    num={data?.inStore[keyName]}
                    percent={calculatePercentage(data?.total[keyName] as number, data?.inStore[keyName] as number)}
                    text={'In Store'}
                    handleClick={() => onClick(`inStore${capitalizeFirstLetter(keyName)}` as MonitoringKeys, `${label} - In Store`)}/>
                <MonitoringDataCard
                    column={2}
                    num={data?.aggregators[keyName]}
                    percent={calculatePercentage(data?.total[keyName] as number, data?.aggregators[keyName] as number)}
                    text={'Агрегатор'}
                    handleClick={() => onClick(`aggregators${capitalizeFirstLetter(keyName)}` as MonitoringKeys, `${label} - Агрегатор`)}/>
            </div>
            <div className={styles.listBox}>
                <p className={styles.title}>Агрегаторы :</p>
            </div>
            <div className={styles.list}>
                <MonitoringDataCard
                    column={3}
                    num={data?.delivery[keyName]}
                    percent={calculatePercentage(data?.total[keyName] as number, data?.delivery[keyName] as number)}
                    text={'Доставка'}
                    handleClick={() => onClick(`delivery${capitalizeFirstLetter(keyName)}` as MonitoringKeys, `${label} - Доставка`)}/>
                <MonitoringDataCard
                    column={3}
                    num={data?.sbermarket[keyName]}
                    percent={calculatePercentage(data?.total[keyName] as number, data?.sbermarket[keyName] as number)}
                    text={'Сбер'}
                    handleClick={() => onClick(`sbermarket${capitalizeFirstLetter(keyName)}` as MonitoringKeys, `${label} - Сбер`)}/>
                <MonitoringDataCard
                    column={3}
                    num={data?.yandex[keyName]}
                    percent={calculatePercentage(data?.total[keyName] as number, data?.yandex[keyName] as number)}
                    text={'Яндекс'}
                    handleClick={() => onClick(`yandex${capitalizeFirstLetter(keyName)}` as MonitoringKeys, `${label} - Яндекс`)}/>
            </div>
        </>
    );
};
