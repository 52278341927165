import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.scss';
import {SwitcherTab} from '../../components/switcherTab';
import {buttonsTypes} from '../report/Report';
import {Select} from 'antd';
import {ClientSearchCriteria} from './store/types';
import {CriteriaType} from './components';
import {Button} from '../../fields/button';
import {toast} from 'react-toastify';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {changeSearchParams, defaultSearchParams} from './store/loyalty.slice';


const option: { label: string, value: ClientSearchCriteria }[] = [
    {label: 'Телефон', value: 'phone'},
    {label: 'Номер карты', value: 'cardNumber'},
    {label: 'Штрихкод карты', value: 'cardBarcode'},
    {label: 'Айди клиента', value: 'clientId'},
];

export const LoyaltyClient = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [typeIndex, setTypeIndex] = useState<number>(0);

    const {searchParams} = useAppSelector(state => state.loyalty);

    useEffect(() => {
        const index = buttonsTypes.findIndex(i => i === searchParams.holding);
        setTypeIndex(index);
    }, []);

    const handleSetParams = (type: ClientSearchCriteria) => {
        dispatch(changeSearchParams({...searchParams, type, id: ''}));
    };

    const handleChangeHolding = (index: number) => {
        setTypeIndex(index);
        dispatch(changeSearchParams({...searchParams, holding: buttonsTypes[index].toLowerCase()}));
    };

    const handleSubmit = () => {
        if (searchParams.id === '') {
            return toast.error('Заполните параметр из списка', {
                delay: 2000
            });
        }
        navigate('/loyalty-client-info');
    };

    const handleBack = () => {
        dispatch(changeSearchParams(defaultSearchParams));
        navigate(-1);
    };

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={handleBack}/>
            <div className={styles.filter}>
                <p className={styles.filterText}>Параметры поиска</p>
                <SwitcherTab
                    pageType={'report'}
                    isActive={typeIndex}
                    isMultiple={false}
                    buttons={buttonsTypes.map(i => i.toUpperCase())}
                    onClick={(index) => handleChangeHolding(index)}
                />
                <Select
                    className={styles.filterSelect}
                    placeholder={'Поиск по'}
                    options={option}
                    defaultValue={searchParams.type}
                    onChange={value => handleSetParams(value)}
                />
                <CriteriaType/>
                <div className={styles.filterButtonWrap}>
                    <Button
                        text={'Применить'}
                        textStyleType={'semiBold'}
                        style={{
                            fontSize: '16px'
                        }}
                        onClick={handleSubmit}/>
                </div>
            </div>
        </MainLayout>
    );
};
