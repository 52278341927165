import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import styles from './style.module.scss';
import {Input} from '../../fields/input';
import {TextareaControl} from '../../fields/textarea';
import {useAppSelector} from '../../hooks/app.hooks';
import {Button} from '../../fields/button';
import {mainColor} from '../../utils/constants';
import {FileFromBack} from '../quiz/store/types';
import {
    useActionsDocMutation,
    useLazyGetDocFilesQuery,
    useLazyGetPhotoReportsQuery,
    usePhotoReportFilesMutation
} from './store/photoReport.api';
import {toast} from 'react-toastify';
import {extractValueFromString, fromJsonToUrlencoded} from '../../helpers';
import AppLoader from '../../components/appLoader';
import {CustomReader} from '../../components/customReader';


interface IForm {
    Type: string;
    Name: string;
    Rem: string;
}

const formSchema = yup.object().shape({
    Type: yup.string().default('Insert'),
    Name: yup.string().required('Обязательное поле'),
    Rem: yup.string().required('Обязательное поле'),
});

export const PhotoReportCreate = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const [actionDoc] = useActionsDocMutation();
    const [uploadFiles, {isLoading: loadingFile}] = usePhotoReportFilesMutation();
    const [getFiles] = useLazyGetDocFilesQuery();
    const [getPhotoReports, {isLoading}] = useLazyGetPhotoReportsQuery();

    const {currentLocation} = useAppSelector(state => state.quiz);
    const {photoReportTypeId} = useAppSelector(state => state.photoReport);


    const [backFiles, setFiles] = useState<FileFromBack[]>([]);
    const [uploadedCount, setUploadedCount] = useState<string[]>([]);

    const [docCreateAt, setDocCreatAt] = useState<string>('');

    const {watch, setValue, control, clearErrors, handleSubmit} = useForm<IForm>({
        resolver: yupResolver(formSchema)
    });

    const id = extractValueFromString(search, 'id');
    const reportType = extractValueFromString(search, 'reportType');

    useEffect(() => {

        if (id) {
            getPhotoReports({params: `FDocs=${id}`})
                .unwrap()
                .then((data) => {
                    const doc = data[0];
                    setDocCreatAt(doc.Date.date.split(' ')[0]);

                    getFiles({ID_Tab: String(doc.ID)})
                        .unwrap()
                        .then(({filesList}) => {
                            const ids = filesList.map((i: any) => i.ID);
                            setUploadedCount(ids);
                            const files = filesList.map((file: any) => (
                                {
                                    id: file.ID,
                                    fileName: file.NameOrig,
                                    origName: file.NameOrig,
                                    url: file.url,
                                    loadStatus: 'loaded'
                                }
                            ));
                            setFiles(files);
                            setValue('Type', 'Update');
                            setValue('Name', doc.Name);
                            setValue('Rem', doc.Rem);
                        });
                });
        }
    }, []);

    const handleChangeField = (key: keyof IForm, value: string) => {
        setValue(key, value);
        clearErrors(key);
    };

    const onSubmit = async (values: IForm) => {
        const data: any = {
            ...values,
            ID_Obj: String(currentLocation?.id),
            ID_Type: photoReportTypeId
        };

        if (values.Type === 'Update') {
            data.ID = id;
        }

        const {info} = await actionDoc({
            body: fromJsonToUrlencoded({data})
        }).unwrap();

        if (info?.ID) {
            if (backFiles.length > uploadedCount.length) {
                const newFiles = backFiles.filter(f => !uploadedCount.includes(f.id));
                for await (const file of newFiles) {
                    const formData = new FormData();
                    formData.append('ID_Tab', String(info.ID));
                    formData.append('fileBase64', file.base64 as string);
                    formData.append('fileName', file.origName);

                    const {status} = await uploadFiles({formData}).unwrap();
                    if (status) {
                        setFiles(prevFiles =>
                            prevFiles.map(f => {
                                if (f.origName === file.origName) {
                                    return {...f, loadStatus: 'loaded'};
                                }
                                return f;
                            })
                        );
                    } else {
                        setFiles(prevFiles =>
                            prevFiles.map(f => {
                                if (f.origName === file.origName) {
                                    return {...f, loadStatus: 'rejected'};
                                }
                                return f;
                            })
                        );
                        toast.error(`Ошибка загрузки файла: ${file.origName}`);
                    }
                }
                toast.info('Успешно изменили');
            } else {
                toast.info('Успешно изменили');
            }
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={''}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {isLoading
                ? <AppLoader isFull={false}/>
                : (
                    <div className={styles.form}>
                        <div className={styles.formHeader}>
                            <p className={styles.formObjName}>
                                <span>Фото отчет по объекту:</span> {currentLocation?.name}
                            </p>
                            {docCreateAt !== '' ? <p className={styles.formDate}>
                                <span>создано:</span> {docCreateAt}
                            </p> : null}
                        </div>

                        <div className={styles.space}/>
                        <Input
                            type={'text'}
                            fieldName={'Name'}
                            placeholder={'Название документа'}
                            control={control}
                            disabled={false}
                            defaultValue={watch('Name')}
                            onChange={value => handleChangeField('Name', String(value))}
                        />
                        <div className={styles.space}/>
                        <TextareaControl
                            fieldName={'Rem'}
                            control={control}
                            placeholder={'Описание документа'}
                            disabled={false}
                            defaultValue={watch('Rem')}
                            onChange={value => handleChangeField('Rem', String(value))}
                        />
                        <div className={styles.space}/>
                        <CustomReader
                            viewFiles={backFiles}
                            loadingFile={loadingFile}
                            objectName={currentLocation?.name ?? ''}
                            reportType={reportType ?? ''}
                            handleRemove={file => {
                                const update = backFiles.filter(f => f.origName !== file.origName);
                                setFiles(update);
                            }}
                            handleSelect={image =>
                                setFiles([...backFiles,
                                    {
                                        origName: image.fileName,
                                        base64: image.fileBase64,
                                        fileName: image.fileName,
                                        id: '0',
                                        loadStatus: 'ready'
                                    }]
                                )
                            }/>

                        <div className={styles.bottomWrapper}>
                            <Button
                                text={'Сохранить'}
                                textStyleType={'semiBold'}
                                style={{
                                    color: mainColor,
                                    fontSize: '16px'
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit(onSubmit)();
                                }}
                            />
                        </div>
                    </div>
                )}

        </MainLayout>
    );
};
