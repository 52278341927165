import React from 'react';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.scss';

export const ExternalCameraList = () => {
    const navigate = useNavigate();

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={'container'}>
                <div className={styles.list}>
                    <div className={styles.listItem} onClick={() => navigate('/external-camera')}>
                        <span className={styles.listText}>Камера 1</span>
                    </div>
                    <div className={styles.listItem} onClick={() => navigate('/external-camera')}>
                        <span className={styles.listText}>Камера 2</span>
                    </div>
                    <div className={styles.listItem} onClick={() => navigate('/external-camera')}>
                        <span className={styles.listText}>Камера 3</span>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};
