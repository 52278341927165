import {api} from '../../../store/api';
import {
    BasicSpotData,
    ByCourier,
    ByRangeData,
    BySource,
    ByStage,
    CashDetailsParams,
    Losses,
    MainData,
    NonPayerRes,
    ObjectPoint,
    OrderShort,
    OrderToday,
    OrderTodayArr,
    Point
} from './types';
import {mapCheckResponse} from '../../../helpers';
import {ResultCashCheck} from '../../../helpers/mapCheckResponse';

const roundByValues = (byCourier: any): any => {
    const roundedCourier = {...byCourier};
    const exclude = ['upt', 'uptm'];
    Object.keys(roundedCourier).forEach((key) => {
        if (exclude.includes(key)) return;

        roundedCourier[key as keyof any] = Math.round(roundedCourier[key as keyof any]);
    });

    return roundedCourier;
};

const roundBySourceValues = (byCourier: BySource): any => {
    const roundedCourier = {...byCourier};
    Object.keys(roundedCourier).forEach((key) => {
        if (!roundedCourier[key as keyof BySource].sum) return;
        roundedCourier[key as keyof BySource].sum = Math.round(roundedCourier[key as keyof BySource].sum);
    });

    return roundedCourier;
};

const sortOrder = ['Waiting', 'WaitCooking', 'CookingStarted', 'CookingCompleted', 'OnWay', 'Delivered', 'Closed', 'Cancelled'];


const sortOrderTodayFn = (data: any): OrderTodayArr => {
    const first: OrderToday[] = [];
    const second: OrderToday[] = [];
    const third: OrderToday[] = [];
    const fourth: OrderToday[] = [];
    const fifth: OrderToday[] = [];

    const sortOrderToday = [
        ['OrdersAll', 'OrdersWithoutDelivery', 'OrdersWithDelivery', 'OrdersCancelled', 'OrdersClosed'],
        ['OrdersInWork', 'OrdersOnWay'],
        ['OrdersOverdued', 'OrdersOverduedByHour'],
        ['AvgCooking', 'AvgTimeSended', 'AvgClickToEat'],
        ['CourierInWork', 'CntCouriers', 'CntPzMakers', 'FurnacePower']
    ];

    sortOrderToday[0].forEach(key => first.push(data[key]));
    sortOrderToday[1].forEach(key => second.push(data[key]));
    sortOrderToday[2].forEach(key => third.push(data[key]));
    sortOrderToday[3].forEach(key => fourth.push(data[key]));
    sortOrderToday[4].forEach(key => fifth.push(data[key]));

    return {
        first,
        second,
        third,
        fourth,
        fifth
    };
};

function sortByCustomOrder(data: OrderToday[], sortOrder: string[]) {
    const orderMap = new Map(sortOrder.map((name, index) => [name, index])) as any;
    data.sort((a, b) => orderMap.get(a.name_en) - orderMap.get(b.name_en));
    return data;
}


export const monitoringApi = api.injectEndpoints({
    endpoints: build => ({
        //@todo этот method будем использовать в 3 кейсах (Монитор DP; SC; PIN;)
        getMainData: build.query<MainData, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValueRevenueToday.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getByRange: build.query<ByRangeData, { params: string }>({
            query: ({params}) => ({
                // url: `/reports/keyRevenueIndicators.php?${params}`,
                url: `/reports/mainValueRevenue.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getBySource: build.query<BySource, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValueRevenueBySource?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: BySource) => {
                return roundBySourceValues(data);
            }
        }),
        getByCourier: build.query<ByCourier, { path: string; params: string }>({
            query: ({params, path}) => ({
                url: `${path}?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getByStage: build.query<ByStage, { params: string }>({
            query: ({params}) => ({
                url: `/reports/avgTimeValue.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByStage) => {
                return roundByValues(data);
            }
        }),
        getObjectInMap: build.query<ObjectPoint[], { params: string }>({
            query: ({params}) => ({
                url: `/listData/listObjectsWithCoordinates.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({list}: { list: ObjectPoint[] }) => {
                return list.filter(i => i.lat !== null && i.lon !== null);
            }
        }),
        getPointAll: build.query<Point[], { params: string }>({
            query: ({params}) => ({
                url: `/orderHooks/orderMapData.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({heatmapData}) => {
                return heatmapData;
            }
        }),
        orderQueryToday: build.query<OrderTodayArr, { params: string }>({
            query: ({params}) => ({
                url: `/reports/orderQueueToday.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                // first second third fourth fifth
                return sortOrderTodayFn(data);
            }
        }),
        orderShort: build.query<OrderShort[], { params: string }>({
            query: ({params}) => ({
                url: `/orderHooks/p_OrderHook_shortTable.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {
                return data.table.body.filter((i: OrderShort) => i.ID_Obj !== null);
            }
        }),
        getOrderCount: build.query<OrderToday[], { params: string }>({
            query: ({params}) => ({
                url: `/orderHooks/p_OrderHook_resultValue.php?${params}`
            }),
            transformResponse: (data: any) => {
                const items = Object.values(data).filter(i => typeof i !== 'boolean') as OrderToday[];
                return sortByCustomOrder(items, sortOrder);
            }
        }),
        getBasicStopData: build.query<BasicSpotData, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValueObjStatus?${params}`
            })
        }),
        getBasicObjects: build.query<any, { params: string }>({
            query: ({params}) => ({
                url: `/reports/objStatusByObjects?${params}`
            }),
            keepUnusedDataFor: 0
        }),
        getNonPayer: build.query<NonPayerRes, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValueDefaulters?${params}`
            })
        }),
        getLosses: build.query<{ items: Losses[], totalItem: Losses }, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValueTableLosses?${params}`
            }),
            transformResponse: ({table}) => {
                const items = table.body.filter((i: Losses) => i.tId !== 'all');
                const totalItem = table.body.filter((i: Losses) => i.tId === 'all').pop();
                return {
                    items, totalItem
                };
            }
        }),
        getDynamic: build.query<any, { path: string }>({
            query: ({path}) => ({
                url: path,
                method: 'GET'
            })
        }),
        getTable: build.query<any, { path: string }>({
            query: ({path}) => ({
                url: path,
                method: 'GET'
            })
        }),
        getCashTableData: build.query<{ table: ResultCashCheck, [key: string]: any }, { ID_Obj: string, path: string }>({
            query: ({ID_Obj, path}) => ({
                url: `/monitorRK/${path}?ID_Obj=${ID_Obj}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {
                if ('checks' in data) {
                    return {table: mapCheckResponse(data), checks: data.checks};
                }
                if ('balanceSystem' in data) {
                    return {
                        table: mapCheckResponse(data),
                        balanceSystem: data.table.body.map((i: any) => ({
                            rowType: i.rowType,
                            rowName: i.rowName.replace(/&nbsp;/g, '').trim()
                        }))
                    };
                }
                return {table: mapCheckResponse(data)};
            }
        }),
        getCashRegisterCheckDetails: build.query<any, CashDetailsParams>({
            query: ({ID_Obj, checkNum, sid}) => ({
                url: `/monitorRK/checkDetail?ID_Obj=${ID_Obj}&sid=${sid}&checkNum=${checkNum}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {
                const tabs = [];
                const keys = Object.keys(data.checkDetail).filter(k => k !== 'status');
                for (const key of keys) {
                    if (key === 'Общая информация') continue;
                    tabs.push({
                        key,
                        values: data.checkDetail[key]
                    });
                }
                return tabs;
            }
        })
    }), overrideExisting: true
});

export const {
    useLazyGetMainDataQuery,
    useLazyGetByRangeQuery,
    useLazyGetBySourceQuery,
    useLazyGetByCourierQuery,
    useLazyGetByStageQuery,
    useLazyGetObjectInMapQuery,
    useLazyGetPointAllQuery,
    useLazyOrderQueryTodayQuery,
    useLazyOrderShortQuery,
    useLazyGetOrderCountQuery,
    useLazyGetBasicStopDataQuery,
    useLazyGetBasicObjectsQuery,
    useLazyGetNonPayerQuery,
    useLazyGetLossesQuery,
    useGetDynamicQuery,
    useGetTableQuery,
    useGetCashTableDataQuery,
    useGetCashRegisterCheckDetailsQuery
} = monitoringApi;
