import {api} from '../../../store/api';
import {GetQuestionReq} from './types';
import {mapListMenu} from '../../../entitys';
import {IOpt} from '../../selectLocation/store/types';

export const questionsApi = api.injectEndpoints({
    endpoints: build => ({
        getAllQuestions: build.query<GetQuestionReq, void>({
            query: () => ({
                url: '/siderBar/listMenu.php',
                method: 'GET',
            }),
            transformResponse: ({response, message, status}) => {
                const result = mapListMenu(response);
                return {
                    ListMenu: result,
                    message,
                    status
                };
            },
        }),
        getListPolls: build.query<{ status: boolean, listPolls: IOpt[] }, { id_con: string }>({
            query: ({id_con}) => ({
                url: '/polls/listPolls.php',
                method: 'GET',
                headers: {
                    id_con
                }
            })
        })
    })
});

export const {useGetAllQuestionsQuery, useLazyGetAllQuestionsQuery, useGetListPollsQuery} = questionsApi;
