import React, {useEffect, useState} from 'react';
import {useGetFilterObjMutation} from '../../../components/financialFilter/store/financial.filter.api';
import {fromJsonToUrlencoded} from '../../../helpers';
import styles from './style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import {rangePresets} from '../../../components/taskFilter/TaskFilter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import {DatePicker} from 'antd';
import {UnmountClosed} from 'react-collapse';
import {SelectFormRow} from '../../../components/selectFormRow';
import {CourierFilterItemType, ICourierFilter} from '../store/types';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {setCourierFilter} from '../store/courier.slice';
import {useGetStaffListFilterQuery} from '../store/courier.api';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('ru');

const {RangePicker} = DatePicker;

interface FilterProps {
    applyFilter: (newKeys?: ICourierFilter[]) => void;
    clearFilter: () => void;
}

export const Filter = ({applyFilter, clearFilter}: FilterProps) => {
    const dispatch = useAppDispatch();
    const [getFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});

    const {data: positions} = useGetStaffListFilterQuery();
    const {filter} = useAppSelector(state => state.courier);
    const [isCollapsed, setCollapse] = useState<boolean>(false);

    useEffect(() => {
        getFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});
    }, []);

    const handleSetFilter = (value: string, type: CourierFilterItemType | 'index') => {
        if (filter.some(key => key.type === type)) {
            const newKeys = filter.map(key => key.type === type ? {...key, value} : key);
            dispatch(setCourierFilter(newKeys));
        } else {
            dispatch(setCourierFilter([...filter, {type, value}]));
        }
    };

    const handleChangeDate = async (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );

        const newKeys = filter.map(key => {
            if (key.type === 'Start') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish') return {...key, value: formattedDates[1]};
            return key;
        });
        await dispatch(setCourierFilter(newKeys));
        applyFilter(newKeys);
    };

    const dateStart = filter.find(k => k.type === 'Start') as ICourierFilter;
    const dateFinish = filter.find(k => k.type === 'Finish') as ICourierFilter;
    const defaultObj = filter.find(i => i.type === 'FObjects')?.value as string ?? '';
    const defaultPosition = filter.find(i => i.type === 'FStaffs')?.value as string ?? '';

    return (
        <div>
            <div className={styles.dataPickerWrapper}>
                <div className={styles.dataPickerWrapperData}>
                    <RangePicker
                        popupClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        presets={rangePresets}
                        value={[dayjs(dateStart.value as string, 'YYYY.MM.DD'), dayjs(dateFinish.value as string, 'YYYY.MM.DD')]}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        allowClear={false}
                    />
                </div>
                <button
                    className={styles.dataPickerWrapperButton}
                    onClick={() => setCollapse(!isCollapsed)}
                >
                    {isCollapsed ? 'Скрыть' : 'Фильтр'}
                </button>
            </div>

            <UnmountClosed isOpened={isCollapsed}>
                <div className={styles.filterWrapper}>
                    <div className={styles.filterCollapse}>
                        <SelectFormRow
                            options={objects?.listObject}
                            label={'Объекты'}
                            disabled={false}
                            isTreeOptions={false}
                            defaultValue={defaultObj}
                            handleSelect={value => handleSetFilter(value, 'FObjects')}
                        />
                        <SelectFormRow
                            options={positions}
                            label={'Должности'}
                            disabled={false}
                            isTreeOptions={true}
                            defaultValue={defaultPosition}
                            handleSelect={value => handleSetFilter(value, 'FStaffs')}
                        />
                        <div className={styles.filterButtons}>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={() => {
                                    setCollapse(false);
                                    clearFilter();
                                }}
                            >Очистить фильтр
                            </button>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={() => applyFilter()}
                            >Применить фильтр
                            </button>
                        </div>
                    </div>

                </div>
            </UnmountClosed>
        </div>
    );
};
