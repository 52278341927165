import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import dayjs from 'dayjs';
import {useGetBonusClientReportQuery} from './store/loyalty.api';
import {buttonsTypes} from '../report/Report';
import {changeSearchParams, defaultSearchParams} from './store/loyalty.slice';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';
import {SwitcherTab} from '../../components/switcherTab';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import {rangePresets} from '../../components/taskFilter/TaskFilter';
import AppLoader from '../../components/appLoader';
import {ScrollUp} from '../../components/scrollUp';
import {DatePicker} from 'antd';
import {Card} from './components';
import {LoyaltyClientReportType} from './store/types';
import {useDebounce} from '../../hooks';
import {SimpleInput} from '../../fields/simpleInput';

const {RangePicker} = DatePicker;

const today = dayjs();
const monthAgo = dayjs().subtract(1, 'month');

export const LoyaltyClientReport = () => {
    const {FObjects} = useParams<{ FObjects: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [typeIndex, setTypeIndex] = useState<number>(0);
    const [dateStr, setDateStr] = useState<string[]>([dayjs(monthAgo).format('D MMM YYYY'), dayjs(today).format('D MMM YYYY')]);

    const {searchParams} = useAppSelector(state => state.loyalty);

    const {data, isFetching} = useGetBonusClientReportQuery(
        {
            params:
                `holding=${searchParams.holding}
                &FObjects=${FObjects}
                &Start=${dayjs(dateStr[0], 'D MMM YYYY').format('YYYY-MM-DD')}
                &Finish=${dayjs(dateStr[1], 'D MMM YYYY').format('YYYY-MM-DD')}`
        },
        {refetchOnMountOrArgChange: true}
    );

    const [items, setItems] = useState<LoyaltyClientReportType[]>([]);
    const [value, setValue] = useState<string>('');
    const {debounceValue} = useDebounce({value, delay: 300});
    useEffect(() => {
        const index = buttonsTypes.findIndex(i => i === searchParams.holding);
        setTypeIndex(index);
    }, []);

    useEffect(() => {
        if (data) {
            setItems(data);
        }
    }, [data]);

    useEffect(() => {
        const items = debounceValue !== ''
            ? (data as LoyaltyClientReportType[]).filter(i => i.Client.includes(debounceValue))
            : data as LoyaltyClientReportType[];
        setItems(items);
    }, [debounceValue]);

    const handleChangeHolding = (index: number) => {
        setTypeIndex(index);
        dispatch(changeSearchParams({...searchParams, holding: buttonsTypes[index].toLowerCase()}));
    };

    const handleChangeDate = (dateStrings: [string, string]) => {
        setDateStr(dateStrings);
    };

    const handleClick = (ClientID: number) => {
        dispatch(changeSearchParams({...searchParams, type: 'clientId', id: ClientID}));
        setTimeout(() => navigate('/loyalty-client-info'));
    };

    const handleBack = () => {
        dispatch(changeSearchParams(defaultSearchParams));
        navigate(-1);
    };

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={handleBack}/>
            <div className={styles.filter}>
                <p className={styles.filterText}>Параметры поиска</p>
                <SwitcherTab
                    pageType={'report'}
                    isActive={typeIndex}
                    isMultiple={false}
                    buttons={buttonsTypes.map(i => i.toUpperCase())}
                    onClick={(index) => handleChangeHolding(index)}
                />
                <div className={styles.separator}/>
                <RangePicker
                    popupClassName="custom-range-picker-dropdown"
                    className={styles.inputRange}
                    locale={ru_RU}
                    format="D MMM YYYY"
                    presets={rangePresets}
                    value={[dayjs(dateStr[0], 'D MMM YYYY'), dayjs(dateStr[1], 'D MMM YYYY')]}
                    onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                    separator="-"
                    allowClear={false}
                />
                <div className={styles.separator}/>
                <SimpleInput
                    type={'text'}
                    value={value}
                    placeholder={'Поиск по ФИО'}
                    readonly={false}
                    size={'sm'}
                    onChange={setValue}
                />
                <div className={styles.separator}/>
                <div>
                    {isFetching
                        ? <AppLoader isFull={false}/>
                        : items.map((item, index) => (
                            <div key={index} className={styles.card} onClick={() => handleClick(item.ClientID)}>
                                <div className={styles.cardHead}>
                                    <span className={styles.cardText}>{item.Client}</span>
                                </div>
                                <Card item={item}/>
                            </div>
                        ))}
                </div>
                <ScrollUp/>
            </div>
        </MainLayout>
    );
};
