import {createSlice} from '@reduxjs/toolkit';
import {courierFilterKeys, CourierState, defaultVacancy} from './types';

const initialState: CourierState = {
    filter: courierFilterKeys,
    vacancy: defaultVacancy
};

const courierSlice = createSlice({
    name: 'courier',
    initialState,
    reducers: {
        setCourierFilter: (state, {payload}) => {
            state.filter = payload;
        },
        clearCourierFilter: (state) => {
            state.filter = courierFilterKeys;
        },
        changeVacancy: (state, {payload}) => {
            state.vacancy.ObjId = payload?.ObjId ?? undefined;
            state.vacancy.Type = payload?.Type ?? 'Insert';
            state.vacancy.ID = payload?.ID ?? undefined;
            state.vacancy.ID_Staff = payload?.ID_Staff;
            state.vacancy.IsActive = payload?.IsActive ?? 1;
            state.vacancy.Date = payload?.Date;
            state.vacancy.Cnt = payload?.Cnt ?? 1;
            state.vacancy.Rem = payload?.Rem;
        }
    }
});

export const {setCourierFilter, clearCourierFilter, changeVacancy} = courierSlice.actions;
export default courierSlice.reducer;
