import React from 'react';
import {ImageLayout, MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {Location, useLocation, useNavigate, useParams} from 'react-router-dom';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {initDateKeys, setFilter, todayDateKeys} from './store/monitoring.slice';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import SimpleButton from '../../components/simpleButton';
import {typeFlow} from '../../store/types';
import {SplitStringWithLineBreak} from './components';
import {themColors} from '../../hooks/useChangeTheme';
import {Icon} from '../../assets/customSvg';
import {HoldingType} from '../report/store/types';


const todayTypesDP = ['MonitorToDay', 'MonitorMap', 'MonitorLine', 'MonitorLoad'];
const byRangeTypesDP = [
    'MonitorPer',
    'MonitorTime',
    'MonitorIst',
    'MonitorKur',];
const putStopTypesDP = ['MonitorStopOb'];

const todayTypesSC = [
    'MonitorSCToDay',
    'MonitorSCOprKass' //@todo надо сделать под это страницу
];
const byRangeTypesSC = [
    'MonitorSCPer',
    'MonitorSCIst',
    'MonitorSCDost',
    'MonitorSCNePlat',
    'MonitorSCPoteri',
    'MonitorSCNeBonus'];
const putStopTypesSC = [''];

const Buttons = ({item, handlePress}: { item: ListMenu, handlePress: (item: ListMenu) => void }) => {
    const {theme} = useAppSelector(state => state.app);
    return (
        <SimpleButton
            key={item.idForm}
            column={2}
            handler={() => handlePress(item)}
        >
            <div className={styles.listItem}>
                {
                    item.icon.type === 'iconsvg'
                        ? <Icon color={themColors[theme].color} width={34} height={34}
                                fileName={item.icon.source as string}/>
                        : (
                            <ImageLayout
                                src={item.icon.source}
                                imageWidth={32}
                                imageHeight={32}
                                containerWidth={32}
                                containerHeight={32}
                            />
                        )
                }
                <SplitStringWithLineBreak className={styles.listText} text={item.name}/>
            </div>
        </SimpleButton>
    );
};

export const Monitoring = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const location = useLocation();
    const {search} = location as Location;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data} = useGetAllQuestionsQuery();
    const viewButtons = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));
    const holding = search.replace('?holding=', '') as HoldingType;

    const firstButton = viewButtons?.children.filter(i => [...todayTypesDP, ...todayTypesSC].includes(i.typeMob));
    const secondButton = viewButtons?.children.filter(i => [...byRangeTypesDP, ...byRangeTypesSC].includes(i.typeMob));
    const putStop = viewButtons?.children.filter(i => [...putStopTypesDP, ...putStopTypesSC].includes(i.typeMob));

    const handlePress = (item: ListMenu) => {
        const isOnlyCurrentDayCasesArr = [
            typeFlow.MonitorToDay,
            typeFlow.MonitorSCToDay,
            typeFlow.MonitorLoad,
            typeFlow.MonitorMap,
            typeFlow.MonitorLine
        ];
        dispatch(setFilter(isOnlyCurrentDayCasesArr.includes(item.typeMob) ? todayDateKeys : initDateKeys));

        switch (item.typeMob) {
            case typeFlow.MonitorToDay:
                return navigate(`/monitoring-main/${item.idForm}?holding=dp`);
            case typeFlow.MonitorSCToDay:
                return navigate(`/monitoring-main/${item.idForm}?holding=sc`);

            case typeFlow.MonitorLoad:
                return navigate(`/monitoring-load/${item.idForm}?holding=dp`);

            case typeFlow.MonitorPer:
                return navigate(`/monitoring-range/${item.idForm}?holding=dp`);
            case typeFlow.MonitorSCPer:
                return navigate(`/monitoring-range/${item.idForm}?holding=sc`);

            case typeFlow.MonitorLine:
                return navigate(`/monitoring-select/${item.idForm}?holding=dp`);

            case typeFlow.MonitorIst:
                return navigate(`/monitoring-source/${item.idForm}?holding=dp`);
            case typeFlow.MonitorSCIst:
                return navigate(`/monitoring-source/${item.idForm}?holding=sc`);

            case typeFlow.MonitorKur:
                return navigate(`/monitoring-courier/${item.idForm}?holding=dp`);
            case typeFlow.MonitorSCDost:
                return navigate(`/monitoring-courier/${item.idForm}?holding=sc`);

            case typeFlow.MonitorSCNePlat:
                return navigate(`/monitoring-non-payer/${item.idForm}?holding=sc`);

            case typeFlow.MonitorSCPoteri:
                return navigate(`/monitoring-losses/${item.idForm}?holding=sc`);

            case typeFlow.MonitorSCOprKass:
                return navigate(`/select-location/${item.idForm}?from=${item.typeMob}`);

            case typeFlow.MonitorTime:
                return navigate(`/monitoring-step/${item.idForm}?holding=dp`);
            case typeFlow.MonitorMap:
                return navigate(`/monitoring-map/${item.idForm}?holding=dp`);
            case typeFlow.MonitorStopOb:
                return navigate(`/monitoring-stop/${item.idForm}?holding=dp`);
            default:
                return;
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Монитор ${holding.toUpperCase()}`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.wrapper}>
                <p className={styles.title}>Сегодня:</p>
            </div>

            <div className={styles.list}>
                {firstButton?.map(item => <Buttons key={item.idForm} item={item} handlePress={handlePress}/>)}
            </div>
            <div className={styles.listBox}>
                <div className={styles.separator}/>
                <p className={styles.title}>За период:</p>
            </div>

            <div className={styles.list}>
                {secondButton?.map(item => <Buttons key={item.idForm} item={item} handlePress={handlePress}/>)}
            </div>

            {putStop && putStop.length > 0 ?
                <>
                    <div className={styles.listBox}>
                        <div className={styles.separator}/>
                        <p className={styles.title}>Постановки в стоп:</p>
                    </div>
                    <div className={styles.list}>
                        {putStop?.map(item => <Buttons key={item.idForm} item={item} handlePress={handlePress}/>)}
                    </div>
                </>
                : null
            }
            <Footer/>
        </MainLayout>
    );
};
