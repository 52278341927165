import React from 'react';
import styled from 'styled-components';
import {ProductType} from '../store/types';
import {font} from '../../../utils/styledComponentsMixins';
import {iconRuble} from '../../../utils/constants';

interface CardProps {
    item: ProductType;
    onClick: () => void;
}

export const Card = ({item, onClick}: CardProps) => {
    return (
        <CardS onClick={onClick}>
            <CardHeadS>
                <TextSM>{item.ClassName}</TextSM>
            </CardHeadS>
            <CardBodyS>
                <HeadS>{item.GoodName}</HeadS>
                <RowS>
                    <TextSM>Сумма</TextSM>
                    <Text>{item.Sum.display} {iconRuble}</Text>
                </RowS>
                <RowS>
                    <TextSM>Количество продаж</TextSM>
                    <Text>{item.Cnt.display}</Text>
                </RowS>
                <RowS>
                    <TextSM>Себестоимость</TextSM>
                    <Text>{item.Sebest.display} {iconRuble}</Text>
                </RowS>

            </CardBodyS>
        </CardS>
    );
};

const CardS = styled.div`
  border-radius: 12px;
  border: 1px solid var(--border-color);
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 10px;
`;

const CardHeadS = styled.div`
  padding: 5px 10px;
  background-color: #eeeeee;
`;

const CardBodyS = styled.div`
  padding: 5px 10px;
`;

const RowS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
`;

const TextSM = styled.span`
  ${font.text(300)};
  color: var(--text-color);
  font-size: 12px;
`;

const Text = styled.span`
  ${font.text(400)};
  color: var(--text-color);
  font-size: 14px;
`;

const HeadS = styled.span`
  ${font.text(400)};
  color: var(--text-color);
  font-size: 16px;
`;
