import {api} from '../../../store/api';
import {DeliveryOrder, Vacancy, VacancyInfo} from './types';
import {Option} from '../../../store/types';

const courierApi = api.injectEndpoints({
    endpoints: build => ({
        changeVacancy: build.mutation<{ status: boolean, VacancyInfo: VacancyInfo }, Vacancy>({
            query: (data) => ({
                url: '/vacancies/handleVacancy',
                method: 'POST',
                body: data
            })
        }),
        getTodayOrders: build.query<DeliveryOrder[], { params: string }>({
            query: ({params}) => ({
                url: `/workShift/ordersMap?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({listOrders}) => {
                return listOrders;
            }
        }),
        getStaffList: build.query<Option[], { FObjects: string }>({
            query: ({FObjects}) => ({
                url: `/vacancies/staffList?FObjects=${FObjects}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({staffList}: { staffList: { StaffName: string, ID_Staff: string }[] }) => {
                return staffList.map(item => ({label: item.StaffName, value: item.ID_Staff}));
            }
        }),
        getStaffListFilter: build.query<Option[], void>({
            query: () => ({
                url: '/vacancies/staffListFilter',
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({staffList}: { staffList: { StaffName: string, FStaffs: string }[] }) => {
                return staffList.map(item => ({label: item.StaffName, value: item.FStaffs}));
            }
        }),
        getVacanciesList: build.query<any, { params: string }>({
            query: ({params}) => ({
                url: `/vacancies/VacancyList?${params}`,
                method: 'GET'
            })
        }),

    }), overrideExisting: true
});

export const {
    useLazyGetTodayOrdersQuery,
    useGetStaffListFilterQuery,
    useGetStaffListQuery,
    useLazyGetVacanciesListQuery,
    useChangeVacancyMutation
} = courierApi;
