import React from 'react';
import {useNavigate} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {useGetClientInfoQuery} from './store/loyalty.api';
import AppLoader from '../../components/appLoader';
import styles from './style.module.scss';
import {iconRuble} from '../../utils/constants';
import classNames from 'classnames';
import {useAppSelector} from '../../hooks/app.hooks';
import {Button} from '../../fields/button';

export const LoyaltyClientInfo = () => {
    const navigate = useNavigate();

    const {searchParams} = useAppSelector(state => state.loyalty);

    const {data, isFetching} = useGetClientInfoQuery({...searchParams}, {skip: searchParams.id === ''});

    const handleBack = () => {
        navigate(-1);
    };

    const handleGetHistory = (ClientID: string) => {
        navigate(`/loyalty-history/${ClientID}`);
    };

    if (isFetching) {
        return (
            <MainLayout>
                <BackButton text={'Назад'} onClick={handleBack}/>
                <AppLoader isFull={false}/>
            </MainLayout>
        );
    }

    if (!data?.status) {
        return (
            <MainLayout>
                <BackButton text={'Назад'} onClick={handleBack}/>
                <div className={styles.wrapper}>
                    <div className={styles.client}>
                        <div className={classNames(styles.clientRow, styles.clientRowFirst)}>
                            <span className={styles.clientText}>{data?.error?.message}</span>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={handleBack}/>
            <div className={styles.wrapper}>
                <div className={styles.client}>
                    <div className={classNames(styles.clientRow, styles.clientRowFirst)}>
                        <span className={styles.clientText}>{data?.clientInfo.templateName}</span>
                    </div>
                    <div className={classNames(styles.clientRow, styles.clientRowFirst)}>
                        <p className={styles.clientName}>{data?.clientInfo.lastName} {data?.clientInfo.firstName}</p>
                        <span
                            className={styles.clientText}>Бонусы: {data?.clientInfo.bonusBalance} {iconRuble}</span>
                    </div>
                    <div className={styles.clientBody}>
                        <div className={styles.clientRow}>
                            <span className={styles.clientSubtext}>Максимальный процент списания бонуса:</span>
                            <span
                                className={styles.clientText}>{data?.clientInfo.maxPercentBonusWriteOff}%</span>
                        </div>
                        <div className={styles.clientRow}>
                            <span className={styles.clientSubtext}>Телефон:</span>
                            <span className={styles.clientText}>{data?.clientInfo.phone}</span>
                        </div>
                        <div className={styles.clientRow}>
                            <span className={styles.clientSubtext}>Email:</span>
                            <span className={styles.clientText}>{data?.clientInfo.email}</span>
                        </div>
                        <div className={styles.clientRow}>
                            <span className={styles.clientSubtext}>Процент скидки:</span>
                            <span
                                className={styles.clientText}>{data?.clientInfo.discountPercent}%</span>
                        </div>
                        <div className={styles.clientRow}>
                            <span className={styles.clientSubtext}>Общая сумма скидок:</span>
                            <span
                                className={styles.clientText}>{data?.clientInfo.sumAllDiscount} {iconRuble}</span>
                        </div>
                        <div className={styles.clientRow}>
                            <span className={styles.clientSubtext}>Депозит:</span>
                            <span
                                className={styles.clientText}>{data?.clientInfo.depositBalance} {iconRuble}</span>
                        </div>
                        <div className={styles.clientRow}>
                            <span className={styles.clientSubtext}>Общая сумма скидок:</span>
                            <span
                                className={styles.clientText}>{data?.clientInfo.sumAllDiscount} {iconRuble}</span>
                        </div>

                    </div>
                </div>
                <div className={styles.buttonMore}>
                    <Button
                        text={'История'}
                        textStyleType={'semiBold'}
                        style={{
                            fontSize: '16px',
                        }}
                        onClick={() => handleGetHistory(data?.clientInfo.clientId)}
                    />
                </div>
            </div>
        </MainLayout>
    );
};
