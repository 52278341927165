import {api} from '../../../store/api';
import {CONTENT_TYPE_URLENCODED} from '../../../utils/constants';
import {LocationItem} from './types';

export const selectLocationApi = api.injectEndpoints({
    endpoints: build => ({
        getLocation: build.query<{ status: boolean, list: LocationItem[] }, { params?: string, id_con: string }>({
            query: ({params, id_con}) => ({
                url: params ? `/object/list.php?${params}` : '/object/list.php',
                method: 'GET',
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                    id_con
                }
            }),
            transformResponse: (data: { status: boolean, list: { [key: string]: LocationItem } }) => {
                const list = Object.values(data.list);
                return {
                    status: data.status,
                    list
                };
            }
        }),
    }), overrideExisting: true
});

export const {useLazyGetLocationQuery} = selectLocationApi;
