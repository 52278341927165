import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './style.module.scss';
import {SwitcherTab} from '../../components/switcherTab';
import Footer from '../../components/footer';
import {MonthTab, TodayTab, WeekTab, YesTodayTab} from './tabs';
import {Button} from '../../fields/button';
import {Filter} from './components';
import dayjs from 'dayjs';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {
    clearReportFilter,
    setChangeReportIndex,
    setDateFilter,
    setReportFilter,
    setSelectObj
} from './store/report.slice';
import {useRequest} from './hook';
import {extractValueFromString, fromJsonToUrlencoded} from '../../helpers';
import {typeFlow} from '../../store/types';
import {useGetFilterObjMutation} from '../../components/financialFilter/store/financial.filter.api';

const buttons: string[] = ['Сегодня', 'Вчера', 'Нед', 'Мес'];

export const buttonsTypes: string[] = ['pin', 'sc', 'dp'];

export const findMatchingIndices = (arr1: string[], arr2: string[]): number[] => {
    const matchingIndices: number[] = [];

    arr1.forEach((element, index) => {
        const foundIndex = arr2.findIndex(el => el.toLowerCase() === element.toLowerCase());

        if (foundIndex !== -1) {
            matchingIndices.push(foundIndex);
        }
    });

    return matchingIndices;
};

const getPeriod = (index: number): string => {
    const today = dayjs();
    if (index === 1) {
        const yesterday = dayjs().subtract(1, 'day');
        return `${yesterday.format('D MMM YYYY')}`;
    }
    if (index === 2) {
        const weekAgo = dayjs().subtract(7, 'day');
        return `${weekAgo.format('D MMM YYYY')} - ${today.format('D MMM YYYY')}`;
    }
    if (index === 3) {
        const monthAgo = dayjs().subtract(1, 'month');
        return `${monthAgo.format('D MMM YYYY')} - ${today.format('D MMM YYYY')}`;
    }

    return `${today.format('D MMM YYYY')}`;
};

export const createParams = (periodType: string, filterHolding: string[], date: string[], FObjects: string[]) => {
    let params = `periodType=${periodType}`;
    if (filterHolding?.length > 0) {
        const holding = filterHolding.join(',');
        params = `${params}&holding=${holding}`;
    }
    if (date?.length > 0) {
        const startDate = date[0];
        const finsishDate = date[1];
        params = `${params}&finsishDate=${finsishDate}&startDate=${startDate}`;
    }

    if (FObjects?.length > 0) {
        const data = FObjects.join(',');
        params = `${params}&FObjects=${data}`;
    }

    return params;
};

const reportName: { [key: string]: string } = {
    [typeFlow.mainValuesRevenue]: 'выручка',
    [typeFlow.mainValuesWriteOff]: 'списания',
    [typeFlow.mainValuesCostPrice]: 'себестоимость',
};

export const dateRange = [
    [dayjs().startOf('day').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
    [dayjs().add(-1, 'day').startOf('day').format('YYYY-MM-DD'), dayjs().add(-1, 'day').endOf('day').format('YYYY-MM-DD')],
    [dayjs().add(-7, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    [dayjs().add(-1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
];

const getDescription = (reportSwitchIndex: number) => {

    switch (reportSwitchIndex) {
        case 0:
            return (
                <div className={styles.description}>
                    <p className={styles.descriptionText}>Показатели за сегодня</p>
                    <p className={styles.descriptionText}>Изменение в % ко вчерашнему дню</p>
                    <p className={styles.descriptionText}>Гистограмма динамики за предыдущие 7 дней</p>
                </div>
            );
        case 1:
            return (
                <div className={styles.description}>
                    <p className={styles.descriptionText}>Показатели за вчера</p>
                    <p className={styles.descriptionText}>Изменение в % к позавчерашнему дню</p>
                    <p className={styles.descriptionText}>Гистограмма динамики за предыдущие 7 дней</p>
                </div>
            );
        case 2:
            return (
                <div className={styles.description}>
                    <p className={styles.descriptionText}>Показатели за неделю</p>
                    <p className={styles.descriptionText}>Изменение в % к предыдущей неделе</p>
                    <p className={styles.descriptionText}>Гистограмма динамики за предыдущие 7 недель</p>
                </div>
            );
        default:
            return (
                <div className={styles.description}>
                    <p className={styles.descriptionText}>Показатели за месяц</p>
                    <p className={styles.descriptionText}>Изменение в % ко предыдущему месяцу</p>
                    <p className={styles.descriptionText}>Гистограмма динамики за предыдущие 7 месяцев</p>
                </div>
            );
    }
};

export const Report = () => {
    const {search} = useLocation();
    const retortType = extractValueFromString(search, 'typeMob');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isOpen, setOpen] = useState<boolean>(false);
    const [storeIndex, setStoreIndex] = useState<number[]>([]);
    const [typeIndex, setTypeIndex] = useState<number[]>([]);

    const {reportSwitchIndex, date, FObjects} = useAppSelector(state => state.report);

    const [dateStrings, setDateStr] = useState<string[]>(date);

    const [dataFilter, setDFilter] = useState<string[]>([]);

    const [getFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});
    useEffect(() => {
        getFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});
    }, []);
    const {
        revenueToday,
        // writeOffToday,
        revenueYesToday,
        writeOffYesToday,
        revenueWeek,
        writeOffWeek,
        revenueMonth,
        writeOffMonth,
        // costPriceToday,
        costPriceYesToday,
        costPriceWeek,
        costPriceMonth,
        loaderMainTodayR,
        // loaderMainTodayW,
        loaderMainYesTodayR,
        loaderMainYesTodayW,
        loaderMainWeekR,
        loaderMainWeekW,
        loaderMonthTodayR,
        loaderMonthTodayW,
        // loaderOtherTodayC,
        loaderOtherYesTodayC,
        loaderOtherWeekC,
        loaderOtherMonthC
    } = useRequest();


    useEffect(() => {
        const matchingIndices = findMatchingIndices(dataFilter, buttonsTypes);
        setTypeIndex(matchingIndices);

    }, [isOpen, dataFilter]);

    const skipCase = [typeFlow.mainValuesWriteOff, typeFlow.mainValuesCostPrice];


    const loaderMain =
        retortType === typeFlow.mainValuesRevenue
            ?
            loaderMainTodayR &&
            loaderMainYesTodayR &&
            loaderMainWeekR &&
            loaderMonthTodayR
            :
            loaderMainYesTodayW &&
            loaderMainWeekW &&
            loaderMonthTodayW;


    const loaderOther = loaderOtherYesTodayC &&
        loaderOtherWeekC &&
        loaderOtherMonthC;

    const tabs = () => {
        switch (reportSwitchIndex) {
            case 1:
                return (
                    <YesTodayTab
                        loaderMain={loaderMain}
                        loaderOther={loaderOther}
                        revenue={revenueYesToday}
                        writeOff={writeOffYesToday}
                        costPrice={costPriceYesToday}
                    />);
            case 2:
                return (
                    <WeekTab
                        loaderMain={loaderMain}
                        loaderOther={loaderOther}
                        revenue={revenueWeek}
                        writeOff={writeOffWeek}
                        costPrice={costPriceWeek}
                    />
                );
            case 3:
                return (
                    <MonthTab
                        loaderMain={loaderMain}
                        loaderOther={loaderOther}
                        revenue={revenueMonth}
                        writeOff={writeOffMonth}
                        costPrice={costPriceMonth}
                    />
                );
            default:
                return (
                    <TodayTab
                        loaderMain={loaderMain}
                        loaderOther={loaderOther}
                        revenue={revenueToday}
                        writeOff={null}
                        costPrice={null}
                    />
                );
        }
    };


    const handleSwitchStore = (index: number, type: 'store' | 'type') => {
        if (type === 'store') {
            if (storeIndex.includes(index)) {
                setStoreIndex(prevState => prevState.filter(i => i !== index));
            } else {
                setStoreIndex([...storeIndex, index]);
            }
        } else {
            const type = buttonsTypes[index].toLowerCase();
            let newData = [];
            if (dataFilter.includes(type)) {
                newData = dataFilter.filter(i => i !== type);
            } else {
                newData = [...dataFilter, type];
            }
            setDFilter(newData);
        }
    };


    const handleChangeDate = (dateStrings: [string, string]) => {
        if (dateStrings.some(i => i === '')) {
            setDateStr([]);
            dispatch(setDateFilter([]));
        } else {
            setDateStr(dateStrings);
        }
    };

    const applyFilter = () => {
        setOpen(false);
        if (dateStrings.length > 0) {
            dispatch(setDateFilter(dateStrings));
        } else {
            dispatch(setDateFilter(dateRange[reportSwitchIndex]));
        }
        dispatch(setReportFilter(dataFilter));
    };

    const clearFilter = () => {
        setOpen(false);
        setDateStr([]);
        setDFilter([]);
        dispatch(clearReportFilter());
        dispatch(setDateFilter([]));
        dispatch(setSelectObj([]));
    };

    const switchPeriod = (index: number) => {
        dispatch(setChangeReportIndex(index));
    };

    const goBackToHome = () => {
        navigate(-1);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Отчеты ${reportName[retortType ?? typeFlow.mainValuesRevenue]}`}/>
            <BackButton text={'Назад в главное меню'} onClick={goBackToHome}/>

            <div className={'container'}>
                <div className={styles.switcher}>
                    <div className={styles.switcherButtons}>
                        {dateStrings.length > 0 ?
                            <span className={styles.switcherTooltip}>выбрана дата в фильтре</span> : null}
                        <SwitcherTab
                            pageType={'report'}
                            skip={undefined}
                            simpleSkipArrayIndex={skipCase.includes(retortType as typeFlow) ? [0] : undefined}
                            isActive={reportSwitchIndex}
                            buttons={buttons}
                            isDisabled={dateStrings.length > 0}
                            onClick={switchPeriod}/>
                    </div>
                    <div className={styles.switcherFilter}>
                        <Button
                            text={'Фильтр'}
                            textStyleType={'regular'}
                            style={{
                                height: '41px',
                                // border: `1px solid ${mainColor}`,
                                borderRadius: '12px'
                            }}
                            onClick={() => setOpen(!isOpen)}
                        />
                    </div>
                </div>
                <Filter
                    isCollapsed={isOpen}
                    buttonsTypes={buttonsTypes}
                    typeIndex={typeIndex}
                    defaultDate={dateStrings}
                    objects={objects?.listObject ?? []}
                    defaultObj={FObjects}
                    applyFilter={applyFilter}
                    clearFilter={clearFilter}
                    handleChangeObj={(value) => dispatch(setSelectObj(value))}
                    handleChangeDate={handleChangeDate}
                    handleSwitchStore={handleSwitchStore}
                />
                <div className={styles.period}>
                    <span className={styles.periodText}>{getPeriod(reportSwitchIndex)}</span>
                </div>
                <div className={styles.tabs}>
                    {tabs()}
                    {getDescription(reportSwitchIndex)}
                </div>

            </div>
            <Footer/>
        </MainLayout>
    );
};
