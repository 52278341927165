import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {ListButtons} from '../../components/listButtons';

export const Products = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    const {data, isLoading} = useGetAllQuestionsQuery();

    const buttonVariable = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const handleClick = (item: ListMenu) => {
        navigate(`/product-cards/${item.typeMob}`);
    };

    // @todo товары
    return (
        <ListButtons
            title={'Учет времени'}
            isLoading={isLoading}
            buttons={buttonVariable?.children ?? []}
            handleClick={handleClick}/>
    );
};
