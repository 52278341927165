export type FilterItemType =
    'ID_Obj'
    | 'ID_Sect'
    | 'ID_Solver'
    | 'ID_Author'
    | 'Search'
    | 'Start'
    | 'Finish'
    | 'Start2'
    | 'Finish2'
    | 'FParentCodes'

export const FilterItemReq: any = {
    ID_Obj: 'FObjects',
    ID_Sect: 'FSects',
    ID_Solver: 'FSolvers',
    ID_Author: 'FUsers',
    Start: 'Start',
    Finish: 'Finish',
    Start2: 'Start2',
    Finish2: 'Finish2',
    Search: 'Search'
};

export interface IFilter {
    type: FilterItemType | 'index',
    value: string | string[]
};

export interface FilterSlice {
    keys: IFilter[];
    activeIndicator: boolean;
};

export type ListIdName = { id: number, name: string };

export interface TreeNode {
    title: string;
    value: string;
    key: string;
    children?: TreeNode[];
}
