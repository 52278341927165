import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useLazyGetByRangeQuery} from './store/monitoring.api';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {MonitoringDataCard, MonitoringFilter} from './components';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';
import {MonitoringKeys} from '../../hooks/useMonitoringControl';

export const MonitoringByRange = () => {
    const navigate = useNavigate();

    const [getByRange, {data, isFetching}] = useLazyGetByRangeQuery();

    const {applyFilter, clearFilter, jumpToDetails, holding} = useMonitoringControl({
        onFetch: ({params}) => getByRange({params})
    });

    const handleClick = (key: MonitoringKeys, labelPage: string) => {
        jumpToDetails({key, necessaryPartPath: 'revenue', labelPage});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Монитор ${holding.toUpperCase()} - Выручка за период`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isFetching
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            <MonitoringDataCard
                                column={2}
                                num={data?.paySum}
                                text={'Выручка'}
                                handleClick={() => handleClick('paySum', 'Выручка')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.trx}
                                text={'Kол-во чеков (TRX)'}
                                handleClick={() => handleClick('trx', 'Kол-во чеков (TRX)')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.at}
                                text={'Средний чек(AT)'}
                                handleClick={() => handleClick('at', 'Средний чек(AT)')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.adt}
                                text={'Чеков/день/тчк (ADT)'}
                                handleClick={() => handleClick('adt', 'Чеков/день/тчк (ADT)')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.upt}
                                text={'Товары/чек (UPT)'} // Количество товаров в чеке
                                handleClick={() => handleClick('upt', 'Товары/чек (UPT)')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.uptm}
                                text={'Тов+модиф/чек (UPTM)'} // Количество товаров с добавками (модификаторами например лимон в чай) в чеке
                                handleClick={() => handleClick('uptm', 'Тов+модиф/чек (UPTM)')}
                            />
                        </div>
                    )
            }

            <Footer/>
        </MainLayout>
    );
};
