import React, {useEffect, useRef, useState} from 'react';
import styles from '../monitoring/styles.module.scss';
import {SwitchStatus} from '../monitoring/components';
import classNames from 'classnames';
import {ArrowLeft, HomeIcon, NavigationIcon, ZoomIn, ZoomOut} from '../../assets/svg';
import {themColors} from '../../hooks/useChangeTheme';
import {Map} from '@pbe/react-yandex-maps';
import {useNavigate} from 'react-router-dom';
import {useLazyGetTodayOrdersQuery} from './store/courier.api';
import {useAppSelector} from '../../hooks/app.hooks';
import {MonitoringFilterItemReq} from '../monitoring/store/types';
import {DeliveryOrder} from './store/types';

export const DeliveryMap = () => {
    const mapRef = useRef<any>(null);
    const navigate = useNavigate();

    const {theme} = useAppSelector(state => state.app);
    const {filter, statuses} = useAppSelector(state => state.monitor);

    const [zoom, setZoom] = useState(10);
    const [ymaps, setYmaps] = useState<any>();

    const [getPoints, {data, isFetching}] = useLazyGetTodayOrdersQuery();

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }
    }, [zoom]);

    useEffect(() => {
        const params = filter.map(key => {
            if (key.type === 'FObjects') {
                return `${MonitoringFilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
            } else {
                return `${MonitoringFilterItemReq[key.type]}=${key.value}`;
            }
        }).join('&');

        getPoints({params});
    }, [filter]);

    useEffect(() => {
        if (ymaps && mapRef.current && data) {

            for (const point of data as DeliveryOrder[]) {
                const address = [point.DeliveryCoord.Latitude, point.DeliveryCoord.Longitude];
                const object = [point.ObjCoord.Latitude, point.ObjCoord.Longitude];

                const carRoute = new ymaps.multiRouter.MultiRoute({
                        referencePoints: [
                            object, // Первая точка
                            address // Вторая точка
                        ],
                        params: {
                            // Параметры для первого маршрута
                            routingMode: 'auto', // Для автомобиля
                            avoid: [],
                            avoidTrafficJams: true,
                            results: 1
                        }
                    }
                );


                mapRef.current.geoObjects.add(carRoute);

                // Создаем мультимаршрут для велосипеда
                const bikeRoute = new ymaps.multiRouter.MultiRoute({
                        referencePoints: [
                            object, // Первая точка
                            address // Вторая точка
                        ],
                        params: {
                            routingMode: 'bicycle', // Для велосипеда
                        }
                    }
                );
                mapRef.current.geoObjects.add(bikeRoute);

                // Пример для пешеходного маршрута
                const pedestrianRoute = new ymaps.multiRouter.MultiRoute({
                        referencePoints: [
                            object, // Первая точка
                            address // Вторая точка
                        ],
                        params: {
                            routingMode: 'pedestrian', // Для пешеходов
                        }
                    }
                );

                // Добавляем пешеходный маршрут на карту
                mapRef.current.geoObjects.add(pedestrianRoute);

                // // Обработчик события для мультимаршрута
                // carRoute.model.events.add('requestsuccess', () => {
                //     const activeRoute = carRoute.getActiveRoute();
                //     if (activeRoute) {
                //         const duration = activeRoute.properties.get('duration').text;
                //         setCarTime(duration);
                //     }
                // });
                //
                // // Обработчик события для велосипедного маршрута
                // bikeRoute.model.events.add('requestsuccess', () => {
                //     const activeRoute = bikeRoute.getActiveRoute();
                //     if (activeRoute) {
                //         const duration = activeRoute.properties.get('duration').text; // Время в пути на велосипеде
                //
                //         setBikeTime(duration);
                //     }
                // });
                //
                // // Обработчик события для пешеходного маршрута
                // pedestrianRoute.model.events.add('requestsuccess', () => {
                //     const activeRoute = pedestrianRoute.getActiveRoute();
                //     if (activeRoute) {
                //         const duration = activeRoute.properties.get('duration').text;
                //
                //         setPedestrianTime(duration);
                //     }
                // });
            }
        }
    }, [ymaps, data]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    mapRef.current.setCenter([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user\'s location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    return (
        <div className={styles.map}>

            <SwitchStatus isSkip={isFetching} position={'fixed'}/>
            <button className={classNames(styles.button, styles.zoomIn)} onClick={handleZoomIn}>
                <ZoomIn color={themColors[theme].color}/>
            </button>
            <button className={classNames(styles.button, styles.zoomOut)} onClick={handleZoomOut}>
                <ZoomOut color={themColors[theme].color}/>
            </button>
            <button className={classNames(styles.button, styles.home)} onClick={() => navigate('/')}>
                <HomeIcon color={themColors[theme].color}/>
                <span className={styles.buttonText}>На главную</span>
            </button>
            <button className={classNames(styles.button, styles.back)} onClick={() => navigate(-1)}>
                <ArrowLeft color={themColors[theme]?.color} width={24} height={24}/>
                <span className={styles.buttonText}>Назад</span>
            </button>
            <button className={classNames(styles.button, styles.navigate)} onClick={getUserLocation}>
                <NavigationIcon color={themColors[theme].color}/>
            </button>
            {
                isFetching
                    ? (
                        <div className={styles.loader}>
                            <p className={styles.loaderText}>идет загрузка...</p>
                        </div>
                    )
                    : (
                        <Map
                            defaultState={{zoom: 10, center: [55.755864, 37.617698]}}
                            width={window.innerWidth}
                            height={window.innerHeight}
                            instanceRef={mapRef}
                            onLoad={ymaps => {
                                setYmaps(ymaps);
                            }}
                        />
                    )
            }
        </div>
    );
};
