import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {useGetClientHistoryQuery} from './store/loyalty.api';
import {DatePicker} from 'antd';
import styles from './style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import {rangePresets} from '../../components/taskFilter/TaskFilter';
import {useAppSelector} from '../../hooks/app.hooks';
import AppLoader from '../../components/appLoader';
import {HistoryCollapse} from './components';

const {RangePicker} = DatePicker;

const today = dayjs();
const monthAgo = dayjs().subtract(1, 'month');

export const LoyaltyHistory = () => {
    const {ClientID} = useParams<{ ClientID: string }>();
    const navigate = useNavigate();
    const {searchParams} = useAppSelector(state => state.loyalty);
    const [dateStr, setDateStr] = useState<string[]>([dayjs(monthAgo).format('D MMM YYYY'), dayjs(today).format('D MMM YYYY')]);
    const {data, isFetching} = useGetClientHistoryQuery(
        {
            params:
                `holding=${searchParams.holding}&ClientID=${ClientID}
                &Start=${dayjs(dateStr[0], 'D MMM YYYY').format('YYYY-MM-DD')}
                &Finish=${dayjs(dateStr[1], 'D MMM YYYY').format('YYYY-MM-DD')}`
        },
        {refetchOnMountOrArgChange: true}
    );

    const handleChangeDate = (dateStrings: [string, string]) => {
        setDateStr(dateStrings);
    };

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.history}>
                <RangePicker
                    popupClassName="custom-range-picker-dropdown"
                    className={styles.inputRange}
                    locale={ru_RU}
                    format="D MMM YYYY"
                    presets={rangePresets}
                    value={[dayjs(dateStr[0], 'D MMM YYYY'), dayjs(dateStr[1], 'D MMM YYYY')]}
                    onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                    separator="-"
                    allowClear={false}
                />
            </div>
            {isFetching
                ? <AppLoader isFull={false}/>
                : <div className={styles.historyList}>
                    {data?.orders.map((item, idx) => <HistoryCollapse key={idx} item={item}/>)}
                </div>}
        </MainLayout>
    );
};
