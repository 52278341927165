import {api} from '../../../store/api';
import {ClientInfoReq, ClientInfoRes, LoyaltyClientReportType, LoyaltyHistory, LoyaltyReportType} from './types';

const loyaltyApi = api.injectEndpoints({
    endpoints: build => ({
        getClientInfo: build.query<ClientInfoRes, ClientInfoReq>({
            query: ({holding, type, id}) => ({
                url: `/loyalty/clientInfo?holding=${holding}&type=${type}&id=${id}`,
                method: 'GET'
            })
        }),
        getClientHistory: build.query<{ orders: LoyaltyHistory[] }, { params: string }>({
            query: ({params}) => ({
                url: `/loyalty/getOrders?${params}`,
                method: 'GET'
            })
        }),
        getBonusReport: build.query<LoyaltyReportType[], { params: string }>({
            query: ({params}) => ({
                url: `/loyalty/bonusReport?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return data.res;
            }
        }),
        getBonusClientReport: build.query<LoyaltyClientReportType[], { params: string }>({
            query: ({params}) => ({
                url: `/loyalty/bonusReportByClients?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: any) => {
                return data.res;
            }
        }),
    }), overrideExisting: true
});

export const {
    useGetClientInfoQuery,
    useGetClientHistoryQuery,
    useGetBonusReportQuery,
    useGetBonusClientReportQuery
} = loyaltyApi;
