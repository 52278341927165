import {createSlice} from '@reduxjs/toolkit';
import {ClientInfoReq, LoyaltyState} from './types';

export const defaultSearchParams: ClientInfoReq = {
    holding: 'pin',
    type: 'phone',
    id: ''
};

const initialState: LoyaltyState = {
    searchParams: defaultSearchParams
};

const loyaltySlice = createSlice({
    name: 'loyalty',
    initialState,
    reducers: {
        changeSearchParams: (state, {payload}) => {
            state.searchParams = {...state.searchParams, ...payload};
        }
    }
});

export const {changeSearchParams} = loyaltySlice.actions;
export default loyaltySlice.reducer;
