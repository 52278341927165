import React, {useEffect} from 'react';
import {useGetWorkShiftQuery} from './store/api.timeControl';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import styles from './style.module.scss';
import AppLoader from '../../components/appLoader';
import Footer from '../../components/footer';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import {DatePicker} from 'antd';
import {Icon} from '../../assets/customSvg';
import {parseQueryString} from '../taskList/helpers';

const {RangePicker} = DatePicker;

const tableHead = ['имя', 'пришёл', 'ушёл', ''];

export const WorkShift = () => {
    const navigate = useNavigate();
    const {idForm} = useParams<{ idForm: string }>();
    let [searchParams, setSearchParams] = useSearchParams();

    const {data, isLoading} = useGetWorkShiftQuery();

    useEffect(() => {
        const {currentIndex} = parseQueryString(searchParams);

        if (currentIndex > 0) {
            const element = document.getElementById(`employee-${currentIndex}`);
            if (element) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                setSearchParams(new URLSearchParams());
            }
        }
    }, []);

    const handleSetIndex = (value: string, type: 'index') => {
        const updatedParams = new URLSearchParams(searchParams);
        if (updatedParams.has(type)) {
            updatedParams.delete(type);
        }
        updatedParams.append(type, value);
        setSearchParams(updatedParams);
    };

    const handleJumpToEmployee = (index: number) => {

        handleSetIndex(String(index), 'index');
        navigate(`/time-work-shift-employee`);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'На смене'}/>
            <BackButton text={'Назад'} onClick={() => navigate(`/courier/${idForm}`)}/>
            <div className={styles.wrapper}>
                <div>
                    <RangePicker
                        popupClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        value={[dayjs(), dayjs()]}
                        onChange={(dates, dateStrings) => {
                        }}
                        separator="-"
                        disabled={true}
                        allowClear={false}
                    />
                </div>
                {isLoading ? <AppLoader isFull={false}/> :
                    data?.map((object, index) => {
                        return (
                            <div key={object.ID_Obj} className={styles.object}>
                                <p className={styles.objectName}>
                                    {object.ObjName}
                                </p>
                                <div className={styles.objectTableWrap}>
                                    <div className={styles.table}>
                                        <div className={styles.tableHeader}>
                                            {tableHead.map((item, index) =>
                                                <div key={index} className={styles.tableHead}>
                                                    <span className={styles.tableHeadText}>{item}</span>
                                                </div>)}
                                        </div>

                                        {object.employees.map((emp, idx) => {
                                            return (
                                                <div key={idx} id={`employee-${index}`} className={styles.tableRow}>
                                                    <div className={styles.tableRowItem}>
                                                        <span className={styles.tableRowName}>{emp.KontraName}</span>
                                                        <span className={styles.tableRowText}>{emp.StaffName}</span>
                                                    </div>
                                                    <div className={styles.tableRowItem}>
                                                        <span className={styles.tableRowText}>{emp.in}</span>
                                                    </div>
                                                    <div className={styles.tableRowItem}>
                                                        <span className={styles.tableRowText}>{emp.out}</span>
                                                    </div>
                                                    <div className={styles.tableRowItem}
                                                         onClick={() => handleJumpToEmployee(index)}>

                                                        <Icon width={20} height={20} color={'#000000'}
                                                              fileName={'search'}/>
                                                    </div>

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <Footer/>
        </MainLayout>
    );
};
