import React, {useEffect, useState} from 'react';
import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import {useAppSelector} from '../hooks/app.hooks';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from '../ErrorBoundary';
import {useSubscribeMutation} from '../store/api';
import {toast} from 'react-toastify';

function urlBase64ToUint8Array(base64String: any) {

    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData: any = window.atob(base64);
    return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
}

export const PrivateRouteMain = () => {
    const navigate = useNavigate();
    const {isAuth, user} = useAppSelector(state => state.auth);
    const [subscribe] = useSubscribeMutation();
    const [isPermissionNotification, setIsPermissionNotification] = useState<boolean>(false);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'NOTIFICATION_CLICK') {
                    const {data} = event.data;
                    if (data.type.toLowerCase() === 'task') {
                        navigate(`/task-create?ID_App=${data.params.ID}`);
                    }
                    if (data.type.toLowerCase() === 'fotorepvitr') {
                        navigate(`/photo-report-create?id=${data.params.ID}`);
                    }
                }
            });
        }
        if ('Notification' in window) {
            const timerId = setTimeout(() => {
                const permission = Notification.permission;

                if (permission === 'granted') {
                    createSubscribe();
                } else {
                    setIsPermissionNotification(true);
                }
            }, 3000);

            return () => {
                clearTimeout(timerId);
            };
        }

    }, []);

    const checkAndSubscribe = async () => {
        if ('Notification' in window) {
            const permission = await Notification.requestPermission();

            if (permission === 'granted') {
                if (!process.env.REACT_APP_PUSH_PUBLIC_KEY) return;
                createSubscribe();
            } else {
                setIsPermissionNotification(true);
            }
        }
    };

    const createSubscribe = async () => {
        const registration = await navigator.serviceWorker.ready;
        const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY);
        const subscription = await registration.pushManager.getSubscription();

        if (!subscription) {
            try {
                const newSubscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidKey,
                });
                await subscribe({newSubscription, id_con: user.notificationToken as string});
                setIsPermissionNotification(false);
            } catch (error) {
                toast.error('Ошибка при подписке на уведомления');
                setIsPermissionNotification(true);
            }
        }
    };

    return isAuth && user.notificationToken
        ? (
            <ErrorBoundary>
                <Outlet/>
                {/*<Drawer*/}
                {/*    title="Подключить уведомления"*/}
                {/*    placement={'bottom'}*/}
                {/*    closable={false}*/}
                {/*    height={300}*/}
                {/*    onClose={() => {*/}
                {/*        setIsPermissionNotification(false);*/}
                {/*    }}*/}
                {/*    open={isPermissionNotification}*/}
                {/*    key={'bottom'}*/}
                {/*>*/}
                {/*    <div className={styles.dialog}>*/}
                {/*        <p className={styles.dialogText}>*/}
                {/*            Для корректной работы приложения необходимо разрешить уведомления.*/}
                {/*        </p>*/}

                {/*        <button className={styles.dialogButton} type={'button'} onClick={checkAndSubscribe}>Подключить*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</Drawer>*/}
            </ErrorBoundary>
        ) : <Navigate to={'/login'} replace={true}/>;
};
