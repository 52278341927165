import React, {useState} from 'react';
import styles from './style.module.scss';
import {Spin} from 'antd';
import {CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {ImageLayout} from '../../layouts';
import dayjs from 'dayjs';
import {FileFromBack} from '../../screens/quiz/store/types';
import classNames from 'classnames';
import {useLazyGetBlobFileQuery} from '../../screens/photoReport/store/photoReport.api';

const plusIcon = require('../../assets/images/plus.png');
const photoIcon = require('../../assets/images/photo.png');
type Image = { fileBase64: string, fileName: string }

interface CustomReaderProps {
    loadingFile: boolean;
    handleSelect: (image: Image) => void;
    viewFiles: FileFromBack[];
    objectName: string;
    reportType: string;
    handleRemove: (file: FileFromBack) => void;
}

const compressBase64 = async (base64: string, maxSizeMB: number): Promise<string> => {
    const maxSizeBytes = maxSizeMB * 1024 * 1024; // Переводим мегабайты в байты

    // Создаем изображение из base64
    const img = new Image();
    img.src = base64;

    return new Promise((resolve) => {
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            // Уменьшаем разрешение изображения поэтапно
            while (true) {
                // Устанавливаем размеры canvas
                canvas.width = width;
                canvas.height = height;

                ctx?.drawImage(img, 0, 0, width, height);

                // Пробуем сохранить как JPEG с определённым качеством
                let quality = 0.9;
                let compressedBase64 = canvas.toDataURL('image/jpeg', quality);

                while (compressedBase64.length > maxSizeBytes && quality > 0.1) {
                    quality -= 0.1; // Снижаем качество
                    compressedBase64 = canvas.toDataURL('image/jpeg', quality);
                }

                if (compressedBase64.length <= maxSizeBytes) {
                    resolve(compressedBase64);
                    return;
                }

                // Если не получилось, уменьшаем разрешение и пробуем снова
                width = Math.floor(width * 0.8); // Уменьшаем ширину на 20%
                height = Math.floor(height * 0.8); // Уменьшаем высоту на 20%
            }
        };

        img.onerror = () => {
            throw new Error('Ошибка при загрузке изображения');
        };
    });
};


export const CustomReader = ({
                                 handleSelect,
                                 viewFiles,
                                 objectName,
                                 reportType,
                                 loadingFile,
                                 handleRemove
                             }: CustomReaderProps) => {
    const [url, setUrl] = useState<string>('');
    const [showFile, setShowFile] = useState<boolean>(false);
    const [getBlob] = useLazyGetBlobFileQuery();

    const timestamp = dayjs().unix();

    const applyWatermark = (
        imageSrc: string,
        objectName: string,
        date: string
    ): Promise<string> => {
        return new Promise((resolve) => {

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;

                // Нарисовать изображение на canvas
                ctx?.drawImage(img, 0, 0);

                if (ctx) {
                    // Настроить стиль текста
                    const fontSize = Math.round(img.width * 0.025); // Размер шрифта относительно ширины изображения
                    const lineHeight = fontSize * 1.5; // Высота между строками
                    const padding = 10; // Отступ внутри рамки

                    // Установить текстовый стиль
                    ctx.font = `${fontSize}px Arial`;
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'middle';

                    // Список строк для водяного знака
                    const watermarkLines = [
                        `Объект: ${objectName}`,
                        `Дата: ${date}`,
                        `Тип отчета: ${reportType}`
                    ];

                    // Рассчитываем размеры рамки
                    const maxTextWidth = Math.max(
                        ...watermarkLines.map(line => ctx.measureText(line).width)
                    ); // Ширина текста (самой длинной строки)
                    const boxWidth = maxTextWidth + padding * 2; // Добавляем отступы
                    const boxHeight = lineHeight * watermarkLines.length + padding * 2;

                    // Координаты для рамки (правый нижний угол)
                    const x = canvas.width - boxWidth - 20; // 20px отступ от края изображения
                    const y = canvas.height - boxHeight - 20; // 20px отступ от нижнего края изображения

                    // Рисуем белую рамку с прозрачностью 25%
                    ctx.fillStyle = 'rgba(255,255,255,0.48)';
                    ctx.fillRect(x, y, boxWidth, boxHeight);

                    // Рисуем текст поверх рамки
                    ctx.fillStyle = 'black';
                    watermarkLines.forEach((line, index) => {
                        const textX = x + padding;
                        const textY = y + padding + index * lineHeight + lineHeight / 2;
                        ctx.fillText(line, textX, textY);
                    });
                }

                // Получить обработанное изображение в base64
                const watermarkedImage = canvas.toDataURL('image/jpeg');
                resolve(watermarkedImage);
            };

            img.src = imageSrc;
        });
    };


    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const originalImageBase64 = reader.result as string;

                // Пример данных для водяного знака
                const currentDate = dayjs().format('DD MMM YYYY; HH:mm:ss');

                // Применяем водяной знак
                const watermarkedImageBase64 = await applyWatermark(originalImageBase64, objectName, currentDate);
                const compressedBase64 = await compressBase64(watermarkedImageBase64, 4);

                // Сохраняем изображение
                handleSelect({
                    fileBase64: compressedBase64,
                    fileName: String(timestamp)
                });
            };
            reader.readAsDataURL(file);
        }
        event.target.value = null;
    };

    const handleGetBlobFile = ({path, isBase}: { path: string, isBase: boolean }) => {
        if (isBase) {
            setShowFile(true);
            setUrl(path);
        } else {
            getBlob({path})
                .unwrap()
                .then((data) => {
                    setUrl(data);
                    setShowFile(true);
                });
        }
    };

    return (
        <div className={styles.flex}>

            <div className={styles.wrapReaderButton}>
                <label htmlFor={'reader'} className={styles.fileReaderButton} onClick={async (e) => {
                    await sessionStorage.setItem('isFile', 'OK');
                }}>
                    {loadingFile ?
                        <Spin indicator={<LoadingOutlined style={{fontSize: 14}} spin/>}/>
                        : <ImageLayout
                            src={plusIcon}
                            imageWidth={24}
                            imageHeight={24}
                            containerWidth={24}
                            containerHeight={24}/>}
                </label>
                <span className={styles.minText}>Доб. файл</span>
                <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    id={'reader'}
                    style={{display: 'none'}}
                    onChange={handleCapture}
                />
            </div>
            {viewFiles.length > 0
                ? <div className={styles.list}>
                    {viewFiles.map((file, index) => (
                        <div key={index} className={styles.wrapReaderButton} onClick={() => {
                            handleGetBlobFile({
                                path: (file?.url ?? file.base64) as string,
                                isBase: file?.url === undefined
                            });
                        }}>
                            <span key={`${index}-${file}`} className={
                                file.loadStatus === 'ready'
                                    ? styles.fileReaderButton
                                    : file.loadStatus === 'loaded'
                                        ? classNames(styles.fileReaderButton, styles.fileReaderButtonLoad)
                                        : classNames(styles.fileReaderButton, styles.fileReaderButtonReject)
                            }>
                                {file.loadStatus === 'ready'
                                    ? (<span className={styles.fileReaderButtonClose}
                                             onClick={(event) => {
                                                 event.stopPropagation();
                                                 handleRemove(file);
                                             }}>
                                            <CloseCircleOutlined style={{color: 'red'}}/>
                                        </span>)
                                    : null}
                                <ImageLayout
                                    src={photoIcon}
                                    imageWidth={36}
                                    imageHeight={36}
                                    containerWidth={36}
                                    containerHeight={36}/>
                            </span>
                            <span className={styles.minText}>{file.origName}</span>
                        </div>
                    ))}
                </div>
                : null}
            {showFile ?
                <div className={styles.modalFile}>
                    <span
                        className={styles.close}
                        onClick={() => {
                            setUrl('');
                            setShowFile(false);
                        }}
                    />
                    <div className={styles.modalFileContent}>
                        <img className={styles.showImage} src={url} alt={'attachment'}/>
                    </div>

                </div> : null}
        </div>
    );
};
