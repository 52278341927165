export function formatNumberAndPostfix(sum: number): string {
    if (!sum) return '0';
    const num = Number(sum.toFixed(0));

    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + ' млрд';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' млн';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + ' тыс';
    }

    return num.toFixed(1).replace(/\.0$/, '');
}
