import React from 'react';
import styles from '../style.module.scss';
import {useCollapse} from 'react-collapsed';
import {LoyaltyHistory} from '../store/types';
import {Icon} from '../../../assets/customSvg';
import {iconRuble} from '../../../utils/constants';
import classNames from 'classnames';

type HistoryCollapseProps = {
    item: LoyaltyHistory
}

export const HistoryCollapse = ({item}: HistoryCollapseProps) => {
    const {isExpanded, getToggleProps, getCollapseProps} = useCollapse();
    return (
        <div className={styles.historyCollapse}>
            <div className={styles.historyCollapseToggle} {...getToggleProps()}>
                <div className={classNames(styles.historyCollapseRow, styles.historyCollapseRowFirst)}>
                    <span className={styles.historyCollapseToggleText}>{item.organizationName}</span>
                    {item.cart.length > 0 ?
                        <Icon color={'#777777'} fileName={isExpanded ? 'up' : 'down'} width={10} height={8}/> : null}
                </div>
                <div className={
                    isExpanded
                        ? classNames(styles.historyCollapseToggleBody, styles.historyCollapseToggleBodyIsExpanded)
                        : styles.historyCollapseToggleBody
                }>
                    <div className={styles.historyCollapseRow}>
                        <span className={styles.historyCollapseSubtext}>Сумма</span>
                        <span className={styles.historyCollapseText}>{item.sum} {iconRuble}</span>
                    </div>
                    <div className={styles.historyCollapseRow}>
                        <span className={styles.historyCollapseSubtext}>Сумма скидок</span>
                        <span className={styles.historyCollapseText}>{item.sumDiscount} {iconRuble}</span>
                    </div>
                    <div className={styles.historyCollapseRow}>
                        <span className={styles.historyCollapseSubtext}>Добавлено бонусов</span>
                        <span className={styles.historyCollapseText}>{item.bonusAdd} {iconRuble}</span>
                    </div>
                    <div className={styles.historyCollapseRow}>
                        <span className={styles.historyCollapseSubtext}>Списано бонусов</span>
                        <span className={styles.historyCollapseText}>{item.bonusWriteOff} {iconRuble}</span>
                    </div>
                    {item.Date !== null ? <div className={styles.historyCollapseRow}>
                        <span className={styles.historyCollapseSubtext}>Дата</span>
                        <span className={styles.historyCollapseText}>{item.Date} {item.Time}</span>
                    </div> : null}

                </div>
            </div>
            {item.cart.length > 0 ?
                <div className={styles.historyCollapseBody} {...getCollapseProps()}>
                    {item.cart.map(cartItem => (
                        <div key={cartItem.nid} className={styles.historyCollapseBodyItem}>
                            <div className={styles.historyCollapseRow}>
                                <span className={styles.historyCollapseSubtext}>Тип продукта</span>
                                <span className={styles.historyCollapseText}>{cartItem.groupName}</span>
                            </div>
                            <div className={styles.historyCollapseRow}>
                                <span className={styles.historyCollapseSubtext}>Наименование</span>
                                <span className={styles.historyCollapseText}>{cartItem.name}</span>
                            </div>
                            <div className={styles.historyCollapseRow}>
                                <span className={styles.historyCollapseSubtext}>Стоимость</span>
                                <span className={styles.historyCollapseText}>{cartItem.price} {iconRuble}</span>
                            </div>
                            <div className={styles.historyCollapseRow}>
                                <span className={styles.historyCollapseSubtext}>Скидка</span>
                                <span className={styles.historyCollapseText}>{cartItem.priceWithDiscount}</span>
                            </div>
                            <div className={styles.historyCollapseRow}>
                                <span className={styles.historyCollapseSubtext}>Количество</span>
                                <span className={styles.historyCollapseText}>{cartItem.amount}</span>
                            </div>
                        </div>
                    ))}
                </div>
                : null}
        </div>
    );
};
