import {ProductFilter} from '../store/types';

export const createParams = (filter: ProductFilter, ...args: any): string => {
    const filterParams = Object.entries(filter)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return `${key}=${value.join(',')}`;
            }
            return `${key}=${value}`;
        });

    const argsParams = args
        .flatMap((arg: any) =>
            Object.entries(arg).map(([key, value]) => `${key}=${value}`)
        );

    return [...filterParams, ...argsParams].join('&');
};
