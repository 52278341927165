import React from 'react';
import styles from '../style.module.scss';
import {addSpaceNumberString} from '../../../helpers';
import {iconRuble} from '../../../utils/constants';
import {LoyaltyClientReportType, LoyaltyReportType} from '../store/types';

export const Card = ({item}: { item: LoyaltyReportType | LoyaltyClientReportType }) => {
    return (
        <div className={styles.cardBody}>
            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>Начислено бонусов</span>
                <span
                    className={styles.cardText}>{addSpaceNumberString(item.BonusP)} {iconRuble}</span>
            </div>
            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>Списано бонусов</span>
                <span
                    className={styles.cardText}>{addSpaceNumberString(item.BonusM)} {iconRuble}</span>
            </div>
            <div className={styles.cardRow}>
                <span className={styles.cardSubtext}>Результат</span>
                <span
                    className={styles.cardText}>{addSpaceNumberString(item.Result)} {iconRuble}</span>
            </div>
        </div>
    );
};
