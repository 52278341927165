import React, {useEffect, useState} from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {useNavigate, useParams} from 'react-router-dom';
import {useMonitoringControl} from '../../hooks';
import {useLazyGetBasicObjectsQuery} from './store/monitoring.api';
import AppLoader from '../../components/appLoader';
import styles from './styles.module.scss';
import {MonitoringFilter} from './components';

export const MonitoringByStopTable = () => {
    const navigate = useNavigate();
    const {type} = useParams<{ type: string }>();
    const [getBasicObject, {data, isLoading}] = useLazyGetBasicObjectsQuery();
    const [title, setTitle] = useState<string>('');
    const [cellNames, setCellNames] = useState<string[]>([]);
    const [table, setTable] = useState<any[]>([]);

    const {applyFilter, clearFilter} = useMonitoringControl({
        onFetch: ({params}) => getBasicObject({params})
    });

    useEffect(() => {
        const names = data?.tableHeadObjStatusObject?.map((i: any) => i.title) ?? [];
        setCellNames(names);
        if (type === 'object') {
            const pushData: any = [];
            setTitle('Постановки в стоп по объектам');
            data?.tableBody?.forEach((item: any) => {
                console.log(item);
                const obj = {
                    ObjName: item.ObjName,
                    type: item.CountN1.display,
                    dynamic: item.HoursN1.display,
                    lastPeriod: item.Status1.display,
                };
                pushData.push(obj);
            });

            setTable(pushData);
            return;
        }
        if (type === 'delivery') {
            const pushData: any = [];
            setTitle('Постановки в стоп по доставке');
            data?.tableBody?.forEach((item: any) => {
                const obj = {
                    ObjName: item.ObjName,
                    type: item.CountN2.display,
                    dynamic: item.HoursN2.display,
                    lastPeriod: item.Status2.display,
                };
                pushData.push(obj);
            });
            setTable(pushData);
            return;
        }
        if (type === 'pickup') {
            const pushData: any = [];
            setTitle('Постановки в стоп по самовывозу');
            data?.tableBody?.forEach((item: any) => {
                const obj = {
                    ObjName: item.ObjName,
                    type: item.CountN3.display,
                    dynamic: item.HoursN3.display,
                    lastPeriod: item.Status3.display,
                };

                pushData.push(obj);
            });
            setTable(pushData);
            return;
        }
        if (type === 'hall') {
            const pushData: any = [];
            setTitle('Постановки в стоп по залу');
            data?.tableBody?.forEach((item: any) => {
                const obj = {
                    ObjName: item.ObjName,
                    type: item.CountN4.display,
                    dynamic: item.HoursN4.display,
                    lastPeriod: item.Status4.display,
                };
                pushData.push(obj);
            });
            setTable(pushData);
            return;
        }


    }, [data]);

    return (
        <MainLayout>
            <Header breadCrumbs={title}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            <div className={'container'}>
                {
                    isLoading
                        ? <AppLoader isFull={false}/>
                        : (
                            <div className={styles.table}>
                                <div className={styles.tableHeads}>
                                    {cellNames.map((i: string, idx) => (
                                        <div key={idx} className={styles.tableCell}>
                                            <p className={styles.tableHead}>{i}</p>
                                        </div>
                                    ))}
                                </div>

                                {table?.map((item, idx) => (
                                    <div className={styles.tableRows}>
                                        <div key={idx} className={styles.tableCell}>
                                            <p className={styles.tableText}>{item.ObjName}</p>
                                        </div>
                                        <div key={idx} className={styles.tableCell}>
                                            <p className={styles.tableText}>{item.type}</p>
                                        </div>
                                        <div key={idx} className={styles.tableCell}>
                                            <p className={styles.tableText}>{item.dynamic}</p>
                                        </div>
                                        <div key={idx} className={styles.tableCell}>
                                            <p className={styles.tableText}>{item.lastPeriod === 1 ?
                                                <span className={styles.tableCellCross}/> : ''}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                }
            </div>

        </MainLayout>
    );
};
