import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {ListButtons} from '../../components/listButtons';
import {typeFlow} from '../../store/types';

export const Courier = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    const {data} = useGetAllQuestionsQuery();
    const buttonVariable = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const handleClick = (item: ListMenu) => {
        if (item.typeMob === typeFlow.CourierShift) {
            return navigate(`/time-work-shift/${idForm}`);
        }
        if (item.typeMob === typeFlow.CourierOrderMap) {
            return navigate('/courier-delivery-map');
        }
        if (item.typeMob === typeFlow.CourierVakMap) {
            return navigate('/courier-delivery-map');
        }

        return navigate('/vacancies');
    };

    return <ListButtons
        title={'Курьеры'}
        buttons={buttonVariable?.children ?? []}
        handleClick={handleClick}/>;
};
